
const { API_ROOT_URL } = require("../../../configs/activebuildconfig.json")


/**
 * CONTROLLER - user
 * FUNCTION - joinMeeting
 * VERIFY - any user
 * @param {*} meetingId The id of the meeting being joined
 * @returns Nothing
 */

export const joinMeeting = async (meetingId) => {
    const response = await fetch(`${API_ROOT_URL}/user/join_meeting`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-type": "application/json",
        },
        body: JSON.stringify({
            meetingId
        })
    })
    const result = await response.json();
    return result;
}