import React, { useState, useEffect, useContext } from "react"
import { ContextProvider } from '../../context';
import confirmMeeting from "../../utils/ConfirmMeeting";
import { useLocation } from "react-router-dom"
import "./index.css"
import {
    faChevronRight, faChevronLeft, faCircleLeft, faCheckCircle, faExclamationCircle, faCircleMinus
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getEST } from "../../API/api";
import LoadingSection from "../../utils/LoadingSection";

const Calendar = ({ calendarData, mentorEmail, setIsReviewRequestShowing, setReviewRequestData }) => {

    const { user } = useContext(ContextProvider)

    const location = useLocation()

    const mentorId = location.pathname.split("/")[2]

    const [currentMonthIndex, setCurrentMonthIndex] = useState(0);

    const [currentMonth, setCurrentMonth] = useState(calendarData[currentMonthIndex])

    const [selectedDay, setSelectedDay] = useState(-1);

    const [isConfirmMeetingClicked, setIsConfirmMeetingClicked] = useState(false)

    const [date, setDate] = useState(false)

    const [meetingsAdded, setMeetingsAdded] = useState(0)

    const [proposedMeetings, setProposedMeetings] = useState([])

    const [isAddMeetingClicked, setIsAddMeetingClicked] = useState(false)

    useEffect(() => {
        handleGetEstTime()
    }, [])

    const handleGetEstTime = async () => {
        const result = await getEST()
        if (result.success) {
            setDate(result.data)
        }
    }

    const areAnyTimesShown = (times) => {
        let atLeastOneTimeShown = false
        Object.entries(times).forEach(([, time]) => {
            const SIX_HOURS = 6 * 60 * 60 * 1000
            if (new Date(time.dateTime) - new Date(date.datetime) > SIX_HOURS && !time.isBooked) {
                atLeastOneTimeShown = true
            }
        })
        return atLeastOneTimeShown
    }

    const formatSelectedDay = (utcDate) => {

        // Get the date using UTC from database
        const date = new Date(utcDate._seconds * 1000)

        const options = { weekday: 'long', month: 'long', day: 'numeric' };

        let formattedDate = date.toLocaleDateString("en-US", options)

        const number = formattedDate.split(" ")[2]

        if (number > 3 && number < 21) {
            formattedDate += "th";
            return formattedDate;
        }

        switch (number % 10) {
            case 1: formattedDate += "st";
                break;
            case 2: formattedDate += "nd";
                break;
            case 3: formattedDate += "rd";
                break;
            default: formattedDate += "th";
        }

        return formattedDate;
    }

    const isDayAlreadyAdded = (index) => {
        let isDayAlreadyAdded = false
        Object.entries(currentMonth.days[index].times).forEach(([, time]) => {
            if (time?.isAdded) {
                isDayAlreadyAdded = true
            }
        })
        return isDayAlreadyAdded
    }

    const getClassForDay = (index) => {
        // If the already added the day as one of the potential days, don't let them add another
        // from that day
        if (isDayAlreadyAdded(index)) {
            return "day added"
        }

        if (proposedMeetings.length === 3) {
            return "day"
        }

        // If the day is in the past
        if (currentMonthIndex === 0 && index + 1 < new Date(date.datetime).getDate()) {
            return "day"
        }
        if (currentMonth.days[index].isAvailable && index === selectedDay && areAnyTimesShown(currentMonth.days[index].times)) {
            return "day active selected"
        }
        else if (currentMonth.days[index].isAvailable && areAnyTimesShown(currentMonth.days[index].times)) {
            return "day active"
        }
        else {
            return "day"
        }
    }

    const goForwardMonthTimeSelected = () => {
        setIsAddMeetingClicked(true)
        goForwardMonth()
    }

    const goBackMonthTimeSelected = () => {
        setIsAddMeetingClicked(true)
        goBackMonth()
    }

    const goForwardMonth = () => {
        setCurrentMonthIndex(currentMonthIndex + 1);
        setCurrentMonth(calendarData[currentMonthIndex + 1])
        setSelectedDay(-1)
    }

    const goBackMonth = () => {
        setCurrentMonthIndex(currentMonthIndex - 1);
        setCurrentMonth(calendarData[currentMonthIndex - 1])
        setSelectedDay(-1)
    }

    const handleTimeSelected = (timeObject) => {
        // Add a field to the timeObject so we know it's been selected
        // Update state so the change is rendered
        timeObject.isSelected = true
        setCurrentMonth({ ...currentMonth })
    }

    const isTimeSelected = () => {
        // Returns true if there's a time that the user clicked on
        // Returns false otherwise
        let isTimeSelected = false

        Object.entries(currentMonth.days[selectedDay].times).forEach(([, time]) => {
            if (time?.isSelected) {
                isTimeSelected = true
            }
        })
        return isTimeSelected
    }

    const removeTimeSelected = (times) => {
        Object.entries(times).forEach(([, time]) => {
            if (time?.isSelected) {
                time.isSelected = false
            }
        })
        setCurrentMonth({ ...currentMonth })
    }

    const handleConfirmMeeting = (chosenDay) => {
        setIsConfirmMeetingClicked(true)
        // Add the monthIndex and dayIndex to the object
        chosenDay.monthIndex = currentMonthIndex
        chosenDay.dayIndex = selectedDay
        // confirmMeeting(user, mentorId, chosenDay)
    }

    const handleAddMeeting = (chosenDay) => {
        const day = formatSelectedDay(chosenDay.date)
        let selectedTime;
        const dayIndex = new Date(chosenDay.date._seconds * 1000).getDate() - 1
        // Loop through all of the times to get the one that was just added by the user
        Object.entries(chosenDay.times).forEach(([, time]) => {
            if (time?.isSelected) {
                selectedTime = time.time
                time.isAdded = true
            }
        })
        setCurrentMonth({ ...currentMonth })
        setIsAddMeetingClicked(true)
        setProposedMeetings([...proposedMeetings, { chosenDay, monthIndex: currentMonthIndex, dayIndex, day, time: selectedTime }])
    }

    const handleRemoveMeeting = (chosenDay) => {
        // Get rid of currentMonth when we call the function
        // Find the day that selected and change isSelected and isAdded to false
        Object.entries(chosenDay.times).forEach(([, time]) => {
            if (time?.isSelected) {
                time.isAdded = false
                time.isSelected = false
            }
        })
        // Get the month and day 
        const dayIndex = new Date(chosenDay.date._seconds * 1000).getDate() - 1
        const monthIndex = Math.abs(new Date().getMonth() - new Date(chosenDay.date._seconds * 1000).getMonth())
        setCurrentMonth({ ...currentMonth })
        let proposedMeetingsOneRemoved = []
        proposedMeetings.forEach((meeting) => {
            if (meeting.monthIndex === monthIndex && dayIndex === meeting.dayIndex) { }
            else {
                proposedMeetingsOneRemoved.push(meeting)
            }
        })
        setProposedMeetings([...proposedMeetingsOneRemoved])
        setMeetingsAdded(meetingsAdded - 1)
        // If there are now 0 meetings in the proposedMeetings array
        // Make isAddMeetingClicked be false
        if (proposedMeetings.length === 1) {
            setIsAddMeetingClicked(false)
        }
    }

    const isDaySelectable = async (index) => {
        const result = await getEST()
        // Make sure the day is avaialbe
        if (!currentMonth.days[index].isAvailable) {
            return
        }

        if (isDayAlreadyAdded(index)) {
            return
        }

        if (proposedMeetings.length === 3) {
            return
        }

        // Make sure the date is not in the past 
        if (currentMonthIndex === 0 && index + 1 < new Date(result.data.datetime).getDate()) {
            return
        }

        // Set the date that the user clicked on as the selected day
        setSelectedDay(index)
        setIsAddMeetingClicked(false)
    }

    const shouldTimeBeShown = (timeObject) => {
        const SIX_HOURS = 6 * 60 * 60 * 1000
        return new Date(timeObject.dateTime) - new Date(date.datetime) > SIX_HOURS && !timeObject.isBooked
    }

    const canUserNotBookMeeting = () => {
        if (user.meetings) {
            return isConfirmMeetingClicked || mentorEmail === user.email || user?.meetings?.length > 2
        }
        else {
            return isConfirmMeetingClicked || mentorEmail === user.email
        }
    }

    return (
        <div>
            <div className="calendar-border">
                <p className="calendar-title">Select a Date and Time</p>
                <p className="calendar-month-year">{currentMonth.month} {currentMonth.year} </p>
                <FontAwesomeIcon
                    icon={faChevronRight}
                    className={currentMonthIndex !== 2 ? "arrow-right arrow-active" : "arrow-right"}
                    onClick={currentMonthIndex !== 2 ? proposedMeetings.length > 0 ? goForwardMonthTimeSelected : goForwardMonth : null}
                />
                <FontAwesomeIcon
                    icon={faChevronLeft}
                    className={currentMonthIndex !== 0 ? "arrow-left arrow-active" : "arrow-left"}
                    onClick={currentMonthIndex !== 0 ? proposedMeetings.length > 0 ? goBackMonthTimeSelected : goBackMonth : null}
                />
                {selectedDay === -1 ? null : <p className="selected-day"> {formatSelectedDay(currentMonth.days[selectedDay].date)}</p>}
                {
                    date === false ? <LoadingSection /> :
                        <div className={selectedDay !== -1 && isTimeSelected() ? "available-times no-scroll" : "available-times"}>
                            {isAddMeetingClicked ? <p className="added-meetings">Your Added Meetings:</p> : null}
                            {!isAddMeetingClicked && meetingsAdded > 0 ? <p onClick={() => setIsAddMeetingClicked(true)} className="added-meetings">Adding meeting # {meetingsAdded + 1}</p> : null}
                            {selectedDay === -1 || meetingsAdded > 0 ? null : <p onClick={() => setSelectedDay(-1)} className="calendar-month-year-blue">See Instructions</p>}
                            {selectedDay === -1 && proposedMeetings.length === 0 ?
                                <div>
                                    <p className="no-availability-message">Step 1.</p>
                                    <p className="select-meeting-instructions">Select 3 times that you are available for a meeting. The times must be on different days.</p>
                                    <p className="no-availability-message">Step 2.</p>
                                    <p className="select-meeting-instructions">Submit your meeting request to the mentor. The mentor will accept one of your three proposed meeting times.</p>
                                    <p className="no-availability-message">Step 3.</p>
                                    <p className="select-meeting-instructions">Once accepted, your meeting is booked! You will be notified about the booking via email.</p>
                                    <a href="/info/meetings" target="_blank" className="get-more-info-link">
                                        <p className="book-meeting-faq">
                                            FAQ about booking a meeting
                                        </p>
                                    </a>
                                </div>
                                : isAddMeetingClicked ?
                                    <>
                                        {proposedMeetings.length === 1 ?
                                            proposedMeetings.map((meeting, index) => {
                                                return (
                                                    <>
                                                        <div className="meeting-flex">
                                                            <p className="proposed-meeting-header">Proposed Meeting # {index + 1}</p>
                                                            <FontAwesomeIcon className="check-icon-default-cursor" icon={faCheckCircle} />
                                                        </div>
                                                        <div className="added-meeting-flex">
                                                            <p className="meeting-day">{meeting.day} <br></br> @ {meeting.time} EST</p>
                                                            <FontAwesomeIcon onClick={() => handleRemoveMeeting(calendarData[meeting.monthIndex].days[meeting.dayIndex])} title="Deselect time" className="deselect-day-icon" icon={faCircleMinus} />
                                                        </div>
                                                        <div className="meeting-flex">
                                                            <p className="proposed-meeting-header">Proposed Meeting # {index + 2}</p>
                                                            <FontAwesomeIcon className="exclamation-icon" icon={faExclamationCircle} />
                                                        </div>

                                                        <p className="meeting-proposal-text">Select a 2<sup>nd</sup> day on the calendar<br></br> when you are available to meet.</p>
                                                        <div className="meeting-flex">
                                                            <p className="proposed-meeting-header">Proposed Meeting # {index + 3}</p>
                                                            <FontAwesomeIcon className="exclamation-icon" icon={faExclamationCircle} />

                                                        </div>
                                                        <p className="meeting-proposal-text">Select a 3<sup>rd</sup> day on the calendar<br></br>when you are available to meet.</p>
                                                    </>
                                                )
                                            }) :
                                            proposedMeetings.length === 2 ?
                                                proposedMeetings.map((meeting, index) => {
                                                    return (
                                                        <>
                                                            <div className="meeting-flex">
                                                                <p className="proposed-meeting-header">Proposed Meeting # {index + 1}</p>
                                                                <FontAwesomeIcon className="check-icon-default-cursor" icon={faCheckCircle} />
                                                            </div>
                                                            <div className="added-meeting-flex">
                                                                <p className="meeting-day">{meeting.day} <br></br> @ {meeting.time} EST</p>
                                                                <FontAwesomeIcon onClick={() => handleRemoveMeeting(calendarData[meeting.monthIndex].days[meeting.dayIndex])} title="Deselect time" className="deselect-day-icon" icon={faCircleMinus} />
                                                            </div>
                                                            {
                                                                index === 1 ?
                                                                    <>
                                                                        <div className="meeting-flex">
                                                                            <p className="proposed-meeting-header">Proposed Meeting # {index + 2}</p>
                                                                            <FontAwesomeIcon className="exclamation-icon" icon={faExclamationCircle} />
                                                                        </div>
                                                                        <p className="meeting-proposal-text">Select a 3<sup>rd</sup> day on the calendar<br></br>when you are available to meet.</p>
                                                                    </> : null
                                                            }
                                                        </>
                                                    )
                                                }) : proposedMeetings.map((meeting, index) => {
                                                    return (
                                                        <>
                                                            <div className="meeting-flex">
                                                                <p className="proposed-meeting-header">Proposed Meeting # {index + 1}</p>
                                                                <FontAwesomeIcon className="check-icon-default-cursor" icon={faCheckCircle} />
                                                            </div>
                                                            <div className="added-meeting-flex">
                                                                <p className="meeting-day">{meeting.day} <br></br> @ {meeting.time} EST</p>
                                                                <FontAwesomeIcon onClick={() => handleRemoveMeeting(calendarData[meeting.monthIndex].days[meeting.dayIndex])} title="Deselect time" className="deselect-day-icon" icon={faCircleMinus} />
                                                            </div>
                                                            {index === 2 ?
                                                                <button className="submit-meeting-request-button"
                                                                    onClick={() => {
                                                                        setIsReviewRequestShowing(true)
                                                                        setIsReviewRequestShowing(true)
                                                                        setReviewRequestData(proposedMeetings)
                                                                    }}>
                                                                    Review your request
                                                                </button>
                                                                : null
                                                            }
                                                        </>
                                                    )
                                                })
                                        }
                                    </>
                                    : !isAddMeetingClicked && isTimeSelected() ?
                                        <>
                                            {Object.entries(currentMonth.days[selectedDay].times).map(([, time]) => {
                                                if (time?.isSelected) {
                                                    return <>
                                                        <p className="meeting-details">Meeting Details:</p>
                                                        <p className="meeting-details-date">Date: {formatSelectedDay(currentMonth.days[selectedDay].date)}</p>
                                                        <p className="meeting-details-time">Time: {time.time} EST</p>
                                                    </>
                                                }
                                                else {
                                                    return null
                                                }
                                            })}
                                            <button onClick={() => {
                                                setMeetingsAdded(meetingsAdded + 1)
                                                handleAddMeeting(currentMonth.days[selectedDay])
                                                if (canUserNotBookMeeting()) {
                                                    return

                                                }
                                                return
                                                // else {
                                                //     handleConfirmMeeting(currentMonth.days[selectedDay])
                                                // }
                                            }}
                                                className={canUserNotBookMeeting() ? "confirm-meeting-button-disabled" : "confirm-meeting-button"}>
                                                Add meeting
                                            </button>
                                            <p className="meeting-details-notice">{meetingsAdded === 2 ? "Cancel or reschedule anytime." :
                                                'Select the "Add Meeting" button to add this meeting as one of your 3 available meeting times.'}<br></br>
                                            </p>
                                            <div onClick={() => removeTimeSelected(currentMonth.days[selectedDay].times)} className="flex-left">
                                                <p className="all-times">All times</p>
                                                <FontAwesomeIcon className="back-icon" icon={faCircleLeft} />
                                            </div>
                                        </>
                                        :
                                        Object.entries(currentMonth.days[selectedDay].times).map(([, time]) => {
                                            return shouldTimeBeShown(time) ? <p
                                                onClick={() => handleTimeSelected(time)}
                                                key={time.militaryTime}
                                                className="available-time">
                                                {time.time} EST
                                            </p> : null
                                        }).sort((a, b) => parseInt(a?.key?.split(":")[0]) - parseInt(b?.key?.split(":")[0]) === 0 ?
                                            parseInt(a?.key?.split(":")[1]) - parseInt(b?.key?.split(":")[1]) : parseInt(a?.key?.split(":")[0]) - parseInt(b?.key.split(":")[0]))}
                        </div>}
                <div className="calendar-container">
                    <p className="calendar-day">SUN</p>
                    <p className="calendar-day">MON</p>
                    <p className="calendar-day">TUE</p>
                    <p className="calendar-day">WED</p>
                    <p className="calendar-day">THU</p>
                    <p className="calendar-day">FRI</p>
                    <p className="calendar-day">SAT</p>
                    {[...Array(currentMonth.firstDayOfTheMonth).keys()].map((elem, index) => {
                        return <p
                            key={index}
                            className="day">
                        </p>
                    })}
                    {[...Array(currentMonth.daysInMonth).keys()].map((elem, index) => {
                        return <p
                            title={isDayAlreadyAdded(index) ? "You already selected a time from this day. Select a different day or deselect the time you already added." : null}
                            onClick={async () => await isDaySelectable(index)}
                            key={index}
                            className={getClassForDay(index)}>
                            {index + 1}
                        </p>
                    })}
                </div>
            </div>
        </div >
    )
}

export default Calendar