
const filterMentorsByMajor = (filter, majors) => {
    if (!filter) { return true }
    let filterMatch = false
    majors.forEach(major => {
        if (filter === "eng") {
            if (major.includes("Engineering")) {
                filterMatch = true
            }
        }
        else if (filter === "cs") {
            if (major.includes("Computer") || major.includes("Data")) {
                filterMatch = true
            }
        }
        else if (filter === "art") {
            if (major.includes("Art")) {
                filterMatch = true
            }
        }
        else if (filter === "poli") {
            if (major.includes("Political Science") || major.includes("Politics")) {
                filterMatch = true
            }
        }
        else if (filter === "bus") {
            if (major.includes("Business") || major.includes("Economics") || major.includes("Management")) {
                filterMatch = true
            }
        }
        else if (filter === "psych") {
            if (major.includes("Psychology") || major.includes("Neuroscience")) {
                filterMatch = true
            }
        }
        else if (filter === "med") {
            if (major.includes("Biology") || major.includes("Neuroscience")) {
                filterMatch = true
            }
        }
        else if (filter === "chem") {
            if (major.includes("Chemistry") || major.includes("Chemical")) {
                filterMatch = true
            }
        }
        else if (filter === "aero") {
            if (major.includes("Aerospace")) {
                filterMatch = true
            }
        }
        else if (filter === "nurse") {
            if (major.includes("Nursing")) {
                filterMatch = true
            }
        }
        else if (filter === "edu") {
            if (major.includes("Education")) {
                filterMatch = true
            }
        }
    })
    return filterMatch
}

export default filterMentorsByMajor