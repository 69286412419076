import React, { useContext, useState, useEffect } from "react"
import CreateMentorProfile from "../../CreateMentorProfile"
import { ContextProvider } from '../../../context';
import Navbar from "../../Navbar";
import Footer from "../../Footer"
import Overview from "../Overview";
import "./index.css"
import Calendar from "../Calendar";
import { Link } from "react-router-dom"


const MentorDashboard = ({ tabSelectedProp }) => {

    const { user } = useContext(ContextProvider)

    const [tabSelected, setTabSelected] = useState(tabSelectedProp)

    useEffect(() => {
        setTabSelected(tabSelectedProp)
    }, [tabSelectedProp])

    return !user.isProfileCreationCompleted ? <CreateMentorProfile />
        : <div>
            <Navbar />
            <div className="mentor-dashboard-tabs">
                <Link style={{ textDecoration: "none" }} to="/mentor"><p onClick={() => setTabSelected(0)} className={tabSelected === 0 ? "selected-tab" : "tab-name"}>Overview</p></Link>
                <Link style={{ textDecoration: "none" }} to="/mentor/calendar"><p onClick={() => setTabSelected(1)} className={tabSelected === 1 ? "selected-tab" : "tab-name"}>Calendar</p></Link>
                {/* <p onClick={() => setTabSelected(2)} className={tabSelected === 2 ? "selected-tab" : "tab-name"}>Insights</p> */}
            </div>
            <hr className="mentor-tabs-hr"></hr>
            {tabSelected === 0 ?
                <Overview setTabSelected={setTabSelected} />
                : tabSelected === 1 ?
                    <Calendar /> : null
            }
            {tabSelected === 1 ? null : <Footer />}
        </div>
}

export default MentorDashboard