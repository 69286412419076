
const { API_ROOT_URL } = require("../../../configs/activebuildconfig.json")


/**
 * CONTROLLER - user
 * FUNCTION - getEST
 * @returns current Estern Standard Time
 */

export const getEST = async () => {
    const response = await fetch(`${API_ROOT_URL}/user/get_EST`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-type": "application/json",
        },
    })
    const result = await response.json();
    return result;
}