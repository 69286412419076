
const { API_ROOT_URL } = require("../../../configs/activebuildconfig.json")


/**
 * CONTROLLER - user
 * FUNCTION - signUpUser
 * VERIFY - User who just authenticated on the frontend (currently with Google)
 * @param {*} userData name and email
 * @returns Returns nothing
 */

export const signUpUser = async (userData) => {
    const response = await fetch(`${API_ROOT_URL}/user/sign_up`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-type": "application/json",
        },
        body: JSON.stringify({
            userData
        })
    })
    const result = await response.json();
    return result;
}