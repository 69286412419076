import React from "react"
import ReactLoading from 'react-loading';
import "./index.css"

const Loading = () => {
    return (
        <div className="loading">
            <ReactLoading type="spin" color="#0071c5" height={375} width={100} />
        </div>
    )
}

export default Loading