import React, { useState, useEffect } from 'react'
import GridLoader from "react-spinners/GridLoader";


const LoadingSection = () => {
    const [width, setWidth] = useState(document.body.clientWidth);

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const handleWindowSizeChange = () => {
        setWidth(document.body.clientWidth)
    }

    let override = {
        position: "absolute",
        marginLeft: 600,
        marginTop: 100,
    };

    if (width < 800) {
        override = {
            position: "absolute",
            margin: "auto",
            left: 0,
            right: 0,
            marginTop: 550,
        };
    }

    return (
        <div className="sweet-loading">
            <GridLoader
                color={"#007FFF"}
                cssOverride={override}
                size={15}
                aria-label="Loading Spinner"
                data-testid="loader"
            />
        </div>
    );
}

export default LoadingSection