const minors = [
    "None",
    "Afroamerican and African Studies",
    "American Culture",
    "Anthropology",
    "Applied Statistics",
    "Arab and Muslim American Studies",
    "Art & Design",
    "Asian Languages and Cultures",
    "Asian Studies",
    "Asian / Pacific Islander American Studies",
    "Astronomy and Astrophysics",
    "Biochemistry",
    'Biological Anthropology',
    "Biology",
    "Biophysics",
    "Bosnian / Croatian / Serbian, Literature and Culture",
    "Business",
    "Central Eurasian Studies",
    "Chemical Measurement Science",
    "Chemical Physics",
    "Chemistry",
    "Classical Archaeology",
    "Classical Civilization",
    "Climate and Space Sciences and Engineering",
    "Community Action and Social Change",
    "Complex Systems",
    "Computer Science",
    "Creative Writing",
    "Crime and Justice",
    "Cultures and Literatures of Eastern Europe",
    "Czech Language, Literature, and Culture",
    "Digital Studies",
    "Drama - Text - to - Performance(T - t - P)",
    "Earth Sciences",
    "East European and Eurasian Studies",
    "Ecology and Evolutionary Biology(EEB)",
    "Economics",
    "Education for Empowerment",
    "Electrical Engineering",
    "Energy Science and Policy",
    "English",
    "Entrepreneurship",
    "Environment",
    "Environmental Geology",
    "Epistemology and Philosophy of Science",
    "Food and the Environment",
    "French and Francophone Studies",
    "Gender and Health",
    "Gender, Race, and Nation",
    "Geology",
    "German Studies",
    "Global History",
    "Global Media Studies",
    "Global Theatre and Ethnic Studies",
    "Greek(Ancient) Language and Literature",
    "Greek(Modern) Language and Culture",
    "History",
    "History of Art",
    "History of Law and Policy",
    "History of Medicine and Health",
    "History of Philosophy",
    "Interdisciplinary Astronomy",
    "Intergroup Relations Education",
    "International Studies",
    "Islamic Studies",
    "Italian",
    "Judaic Studies",
    "Latin American and Caribbean Studies",
    "Latin Language and Literature",
    "Latina / o Studies",
    "Law, Justice, and Social Change",
    "Lesbian Gay Bisexual Transgender Queer Sexuality Studies",
    "Linguistics",
    "Mathematics",
    "Medical Anthropology",
    "Medieval and Early Modern Studies",
    "Middle East Studies",
    "Mind and Meaning",
    "Modern European Studies",
    "Modern Middle Eastern and North African Studies",
    "Moral and Political Philosophy",
    "Multidisciplinary Design",
    "Museum Studies",
    "Music",
    "Native American Studies",
    "Naval Engineering",
    "Oceanography",
    "Paleontology",
    "Performing Arts Management and Entrepreneurship",
    "Philosophy",
    "Physics",
    "Plant Biology",
    "Playwriting",
    "Polish Language, Literature and Culture",
    "Political Science",
    "Polymer Chemistry",
    "Portuguese",
    "Public Policy",
    "Quantitative Methods in the Social Sciences",
    "Religion",
    "Russian Language, Literature, and Culture",
    "Russian Studies",
    "Scandinavian Studies",
    "Science, Technology, and Society(STS)",
    "Social Class and Inequality Studies",
    "Sociology of Health and Medicine",
    "Spanish Language, Literature, and Culture",
    "Statistics",
    "Sustainability",
    "Theatre Design and Production",
    "Translation Studies",
    "Ukrainian Language, Literature, and Culture",
    "Urban Studies",
    "Water and the Environment",
    "Writing",
    "Yiddish Studies"
]

export default minors




