import { signUpMentor } from "../API/api";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "../firestore"
import handleUserSignUp from "./handleUserSignUp";


const provider = new GoogleAuthProvider();
const auth = getAuth();
provider.setCustomParameters({
    prompt: "select_account"
});

const signUpWithGoogle = async (user, userType = "user") => {
    // If the user is already authenticated and the user is trying to sign up 
    // to be a user(userType === "user"), then return
    if (user?.isUserAuthenticated && userType === "user") {
        return
    }
    // If the user is already authenticated and the user is signing up to be a mentor 
    // (userType === "mentor"), then sign, them up as a mentor
    else if (user?.isUserAuthenticated && userType === "mentor") {

        const { name, email } = user

        // Only allow emails that are @umich.edu to sign up
        if (!email.endsWith("@umich.edu")) {
            window.location.href = "/email/error"
            return
        }

        signUpMentor({ name, email: email }, (result) => {
            // If the mentor sign up is successful, then send the mentor to the mentor sign up page
            if (result.success) {
                window.location.href = `/mentor/signup`
            }
        })

        // Otherwise, they are already signed up as a mentor so send them straight to the mentor page
        window.location.href = '/mentor'
    }
    // If the user is not authenticated
    else {
        signInWithPopup(auth, provider)
            .then((result) => {
                const signUpType = localStorage.getItem('signUpType');
                handleUserSignUp(result, signUpType)
            })
    }
}

export default signUpWithGoogle

