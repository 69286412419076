
const controllers = {
  mentor: require("./controllers/mentor"),
  user: require("./controllers/user")
};

// ************************************************ Functions for Mentor Controller ********************************************************** //
export const getMentors = async () => {
  return controllers.mentor.getMentors()
}

export const getMentor = async (id) => {
  return controllers.mentor.getMentor(id)
}

export const createMentor = async (mentorData, callback) => {
  return controllers.mentor.createMentor(mentorData, callback)
}

export const getAuthenticatedMentor = async (callback) => {
  return controllers.mentor.getAuthenticatedMentor(callback)
}

export const signUpMentor = async (mentorData, callback) => {
  return controllers.mentor.signUpMentor(mentorData, callback)
}

export const updateCalendar = async (calendarData, callback) => {
  return controllers.mentor.updateCalendar(calendarData, callback)
}

export const updateMentor = async (mentorData, callback) => {
  return controllers.mentor.updateMentor(mentorData, callback)
}

export const updatePayment = async (paymentData, callback) => {
  return controllers.mentor.updatePayment(paymentData, callback)
}

export const getMeetingMentor = async (meetingId) => {
  return controllers.mentor.getMeeting(meetingId)
}

export const acceptMeeting = async (meetingId, meetingDate, currentDate) => {
  return controllers.mentor.acceptMeeting(meetingId, meetingDate, currentDate)
}

export const cancelMeetingMentor = async (meetingId, callback) => {
  return controllers.mentor.cancelMeeting(meetingId, callback)
}

export const declineMeeting = async (meetingId) => {
  return controllers.mentor.declineMeeting(meetingId)
}

export const addForumMessageMentor = async (messageObject, callback) => {
  return controllers.mentor.addForumMessage(messageObject, callback)
}

export const joinMeetingMentor = async (meetingId) => {
  return controllers.mentor.joinMeeting(meetingId)
}







// ************************************************ Functions for User Controller ********************************************************** //

export const getAuthenticatedUser = async (callback) => {
  return controllers.user.getAuthenticatedUser(callback)
}

export const signUpUser = async (userData) => {
  return controllers.user.signUpUser(userData)
}

export const createMeeting = async (meetingDate, mentorId, callback) => {
  return controllers.user.createMeeting(meetingDate, mentorId, callback)
}

export const cancelMeeting = async (meeting, callback) => {
  return controllers.user.cancelMeeting(meeting, callback)
}

export const updateUser = async (userData, callback) => {
  return controllers.user.updateUser(userData, callback)
}

export const preMeetingQuestions = async (questions, meeting, callback) => {
  return controllers.user.preMeetingQuestions(questions, meeting, callback)
}

export const getMeetingUser = async (meetingId) => {
  return controllers.user.getMeeting(meetingId)
}

export const getEST = async () => {
  return controllers.user.getEST()
}

export const userInterestedInMeetingWithMentor = async (data, callback) => {
  return controllers.user.userInterestedInMeetingWithMentor(data, callback)
}

export const addForumMessageUser = async (messageObject, callback) => {
  return controllers.user.addForumMessage(messageObject, callback)
}

export const getMessageReplies = async (messageId) => {
  return controllers.user.getMessageReplies(messageId)
}

export const createMeetingRequest = async (meetingData, mentorId, callback) => {
  return controllers.user.createMeetingRequest(meetingData, mentorId, callback)
}

export const joinMeetingUser = async (meetingId) => {
  return controllers.user.joinMeeting(meetingId)
}