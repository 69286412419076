const majors = [
    "Mechanical Engineering",
    "Climate and Meteorology",
    "Space Sciences & Engineering",
    "Nuclear Engineering and Radiological Sciences",
    "Materials Science & Engineering",
    "Environmental Engineering",
    "Climate & Space Engineering",
    "Electrical Engineering",
    "Computer Hardware Engineering",
    "Computer Engineering",
    "Engineering Physics",
    "Robotics",
    "Naval Architecture & Marine Engineering",
    "Civil Engineering",
    "Chemical Engineering",
    "Biomedical Engineering",
    "Aerospace Engineering",
    "Afroamerican and African Studies",
    "American Culture",
    "Anthropology",
    "Arts & Ideas in the Humanities",
    "Asian Studies",
    "Astronomy and Astrophysics",
    "Biochemistry",
    "Biology",
    "Biology, Health, and Society",
    "Biomolecular Science",
    "Biophysics",
    "Biopsychology, Cognition, and Neuroscience",
    "Cellular and Molecular Biomedical Science",
    "Chemical Science",
    "Chemistry",
    "Classical Archaeology",
    "Classical Civilization",
    "Classical Languages and Literatures",
    "Cognitive Science",
    "Communication and Media",
    "Comparative Literature",
    "Computer Science",
    "Creative Writing and Literature",
    "Data Science",
    "Drama",
    "Earth and Environmental Sciences",
    "Ecology, Evolution, and Biodiversity",
    "Economics",
    "English",
    "Environment",
    "Evolutionary Anthropology",
    "Film, Television, and Media",
    "French and Francophone Studies",
    "Gender and Health",
    "General Studies",
    "German",
    "Greek(Ancient) Language and Literature",
    "Greek(Modern) Language and Culture",
    "History",
    "History of Art",
    "Informatics",
    "Interdisciplinary Astronomy",
    "Interdisciplinary Chemical Sciences",
    "Interdisciplinary Physics",
    "International Studies",
    "Italian",
    "Judaic Studies",
    "Latin American and Caribbean Studies",
    "Latin Language and Literature",
    "Latina / Latino Studies",
    "Linguistics",
    "Mathematics",
    "Microbiology",
    "Middle East Studies",
    "Middle Eastern and North African Studies",
    "Molecular, Cellular, and Developmental Biology",
    "Music",
    "Neuroscience",
    "Organizational Studies",
    "Philosophy",
    "Philosophy, Politics, and Economics",
    "Physics",
    "Plant Biology",
    "Polish",
    "Political Science",
    "Psychology",
    "Romance Languages and Literatures",
    "Russian",
    "Russian, East European and Eurasian Studies",
    'Social Theory and Practice',
    'Sociology',
    'Spanish',
    'Statistics',
    "Women's and Gender Studies",
    "Business Administration (BBA)",
    "Business",
    "Business Law",
    "Accounting",
    "Business Communications",
    "Business Economics and Public Policy",
    "Finance",
    "Management and Organizations",
    "Marketing",
    "Strategy",
    "Technology & operations",
    "Business Networks",
    "Global Business",
    "Healthcare",
    "Positive Business",
    "Real Estate",
    "Social Impact",
    "Supply Chain Management",
    "Tax Policy",
    "Applied Exercise Science",
    "Athletic Training",
    "Movement Science",
    "Sport Management",
    "Nursing (BSN)",
    "Elementary Education",
    "Education",
    "Bachelor of Fine Arts (BFA)",
    "Art and Design",
    "Bachelor of Science in Information (BSI)",
    "BA in Public Policy",
    "Energy Policy",
    "Health Care Policy & Infectious Disease Control/Prevention",
    "Economic Perspectives on Public Policy",
    "Historical Perspectives on Foreign Policy",
    "Development Policy in Southeast Asia",
    "Middle Eastern Policy",
    "Public Health Sciences",
    "Community and Global Public Health",
    "Biostatistics",
    "Environmental Health Sciences",
    "Epidemiology",
    "Health Behavior and Health Education",
    "Health Informatics",
    "Health Management and Policy",
    "Nutritional Sciences",
    "Population and Health Sciences",
    "Social Work",
    "Pharmaceutical Sciences",
    "Architecture",
    "Environment and Sustainability",
    "Music Thatre & Dance",
    "Composition",
    "Conducting",
    "Dance",
    "Entrepreneurship & Leadership",
    "Jazz & Contemporary Improvisation",
    "Music Education",
    "Music Theory",
    "Musical Theatre",
    "Musicology",
    "Organ",
    "Performing Arts Technology",
    "Piano",
    "Strings",
    "Theatre & Drama",
    "Voice",
    "Winds & Percussion"
]

export default majors