const formatTime = (time) => {

    // Get the date using UTC from database
    const date = new Date(time)

    const options = { timeZone: "America/New_York", hour: '2-digit', minute: '2-digit' };

    let formattedTime = date.toLocaleTimeString("en-US", options)

    const hours = formattedTime.split(":")[0]

    // Get rid of leading 0
    if (hours[0] === "0") {
        formattedTime = formattedTime.substring(1)
    }
    return formattedTime
}

export default formatTime