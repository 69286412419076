import React, { useEffect, useState, useContext, useRef } from "react"
import { useLocation } from "react-router-dom"
import Navbar from "../Navbar"
import Footer from "../Footer"
import Loading from "../Loading"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faXmark, faCheckCircle
} from "@fortawesome/pro-solid-svg-icons";
import { preMeetingQuestions } from "../../API/api"
import { ContextProvider } from '../../context';
import { getEST } from "../../API/api"
import "./index.css"

const MeetingBooked = () => {

    const location = useLocation();

    const { user } = useContext(ContextProvider)

    const meetingId = location.pathname.split("/")[3]

    const [isLoading, setIsLoading] = useState(true)

    const [meeting, setMeeting] = useState()

    const [faq, setFaq] = useState(-1)

    const [width, setWidth] = useState(document.body.clientWidth)

    const [numberOfQuestions, setNumberOfQuestions] = useState(3)

    const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true)

    const [meetingQuestions, setMeetingQuestions] = useState({ "question0": "", "question1": "", "question2": "", "question3": "", "question4": "", "question5": "" })

    const [areQuestionsSubmitted, setAreQuestionsSubmitted] = useState(false)

    const [isCannotJoinMeetingModalShown, setisCannotJoinMeetingModalShown] = useState(false)

    const [isWarningModalShown, setIsWarningModalShown] = useState(true)

    const cannotJoinModalRef = useRef()

    const warningModalRef = useRef()



    const questionExamplesSmallWidth = [
        "Ex) Pros and cons of joining a fraternity?",
        "Ex) Best way to find a roommate?",
        "Ex) Best classes if I don't know my major?",
        "Ex) Should I join the marching band?",
        "Ex) Experience living on North Campus?",
        "Ex) Experience coming from a small school?"
    ]

    const questionExamples = [
        "Ex) What are the pros and cons of joining a fraternity at Michigan?",
        "Ex) Can you talk about dorm life and the best ways to find a roommate?",
        "Ex) I don't know what I want to major in yet. What classes should I take?",
        "Ex) I'm thinking about joining the marching band. What's the time commitment like?",
        "Ex) I placed out of Intro Biology from my AP credit. Should I retake it at Michigan?",
        "Ex) How was your experience not knowing anyone coming into Michigan?"
    ]


    const handleMeetingQuestionsChange = (e, index) => {
        meetingQuestions[`question${index}`] = e.target.value

        if (e.target.value) {
            setIsSubmitButtonDisabled(false)
        }

        else if (!e.target.value && !meetingQuestions.question0 &&
            !meetingQuestions.question1 && !meetingQuestions.question2
            && !meetingQuestions.question3 && !meetingQuestions.question4 && !meetingQuestions.question5) {
            setIsSubmitButtonDisabled(true)
        }

        setMeetingQuestions({ ...meetingQuestions })
    }

    const handleSubmitMeetingQuestions = () => {
        setIsSubmitButtonDisabled(true)
        preMeetingQuestions(meetingQuestions, meeting, (result) => {
            if (result.success) {
                setAreQuestionsSubmitted(true)
            }
        })
    }

    const formatDay = (utcDate) => {

        // Get the date using UTC from database
        const date = new Date(utcDate)

        const options = { timeZone: "America/New_York", weekday: 'long', month: 'long', day: 'numeric' };

        let formattedDate = date.toLocaleDateString("en-US", options)

        const number = formattedDate.split(" ")[2]

        if (number > 3 && number < 21) {
            formattedDate += "th";
            return formattedDate;
        }

        switch (number % 10) {
            case 1: formattedDate += "st";
                break;
            case 2: formattedDate += "nd";
                break;
            case 3: formattedDate += "rd";
                break;
            default: formattedDate += "th";
        }

        return formattedDate;
    }

    const formatTime = (utcDate) => {

        // Get the date using UTC from database
        const date = new Date(utcDate)

        const options = { timeZone: "America/New_York", hour: '2-digit', minute: '2-digit' };

        let formattedTime = date.toLocaleTimeString("en-US", options)

        const hours = formattedTime.split(":")[0]

        // Get rid of leading 0
        if (hours[0] === "0") {
            formattedTime = formattedTime.substring(1)
        }
        return formattedTime
    }

    useEffect(() => {
        // Check if the user has access to the meetingId that we get from the url
        user.meetings.forEach(meeting => {
            if (meeting.id.toString() === meetingId) {
                console.log(meeting)
                setMeeting(meeting)
                setIsLoading(false)
            }
        })
    })

    useEffect(() => {
        let closeCannotJoinModalRef = (event) => {
            if (!cannotJoinModalRef?.current.contains(event.target)) {
                setisCannotJoinMeetingModalShown(false)
            }
        }

        let closeWarningModalRef = (event) => {
            if (!warningModalRef?.current.contains(event.target)) {
                setIsWarningModalShown(false)
            }
        }
        document.addEventListener("mousedown", closeCannotJoinModalRef);
        document.addEventListener("mousedown", closeWarningModalRef);
        return () => {
            document.removeEventListener("mousedown", closeCannotJoinModalRef);
            document.removeEventListener("mousedown", closeWarningModalRef);
        }

    }, [])

    const handleWindowSizeChange = () => {
        setWidth(document.body.clientWidth)
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);


    const isMeetingWithinFiveMinutesOfStarting = async (meetingStartTime) => {
        const currentEstTime = await getEST()
        const isLessThanFive = new Date(meetingStartTime) - new Date(currentEstTime.data.datetime)
        if (isLessThanFive <= 300000) {
            return true
        }
        return false
    }

    return isLoading ? <Loading /> : (
        <>
            <Navbar />
            {isCannotJoinMeetingModalShown ?
                <div ref={cannotJoinModalRef} className='cannot-join-meeting-yet'>
                    <FontAwesomeIcon icon={faXmark} className="icon-x-mark" onClick={() => setisCannotJoinMeetingModalShown(false)} />
                    <p className="cannot-join-meeting-header-text">
                        {!meeting.hasMentorAcceptedMeetingRequest ? "Your meeting request has not been accepted yet" : "Your meeting has not started yet"}
                    </p>
                    <p className='refresh-warning'>Make sure that you have refreshed the page recently.</p>
                    <p className="cannot-join-meeting-sub-text">
                        {!meeting.hasMentorAcceptedMeetingRequest ? "Your mentor must accept your meeting request before the meeting can begin." : "The join button will become active 5 minutes before your meeting begins."}
                    </p>
                </div>
                : null}
            {isWarningModalShown ?
                <div ref={warningModalRef} className="edit-profile-modal warning-modal">
                    <FontAwesomeIcon icon={faXmark} className="icon-x-mark" onClick={() => setIsWarningModalShown(false)} />
                    <p className="edit-header">Please Read!</p>
                    <div className="modal-content">
                        <p className="warning-text">Your meeting request was submitted</p>
                        <p className="warning-explanation">Your mentor will soon receive the meeting request you just submitted containing your three proposed meeting times. The mentor will accept one of your three meeting times.</p>
                        <p className="warning-text warning-second">Your meeting request must be accepted</p>
                        <p className="warning-explanation">Before your meeting is officially booked, the mentor must accept one of your proposed meeting times from the meeting request you just submitted. Once accepted by the mentor, your meeting will officially be booked!</p>
                        <p className="warning-text warning-second">How will I know when my meeting is officially booked?</p>
                        <p className="warning-explanation">As soon as your mentor accepts one of your proposed meeting times, you will receive an email notifying you that your meeting is booked! The email will also contain the details of your meeting!</p>
                        <p className="warning-text warning-second">How long will it take for my meeting request to be accepted?</p>
                        <p className="warning-explanation">Your mentor will likely accept your meeting request within a few hours of receiving the request. Although mentors are full-time students so occasionally it can take a day or longer.</p>
                    </div>
                    <div className="save-cancel-flex">
                        <button
                            onClick={() => setIsWarningModalShown(false)}
                            className="save-changes">
                            I Understand
                        </button>
                    </div>
                </div>
                : null}
            <div className={isCannotJoinMeetingModalShown || isWarningModalShown ? "blur-background" : ""}>
                <div className="meeting-booked-container">
                    <div className="meeting-booked-flex">
                        <p className="meeting-booked-text">{meeting.hasMentorAcceptedMeetingRequest ? "Your meeting is booked!" : "Your meeting request has been sent!"}</p>
                        <FontAwesomeIcon icon={faCheckCircle} className="meeting-booked-icon" />
                    </div>
                    {meeting.hasMentorAcceptedMeetingRequest ?
                        <>
                            <div className="date-time-grid">
                                <div style={{ textAlign: "center" }}>
                                    <p className="date">Date</p>
                                    <p className="date-text">{formatDay(meeting.startTime)}</p>
                                </div>
                                <div style={{ textAlign: "center" }}>
                                    <p className="time">Time</p>
                                    <p className="date-text">{formatTime(meeting.startTime)} - {formatTime(new Date(new Date(meeting.startTime).getTime() + 30 * 60000))} EST</p>
                                </div>
                            </div>
                            <p className="how-to-join-text">Join your meeting when it's about to start</p>
                            <button onClick={async () => meeting?.hasMentorAcceptedMeetingRequest && await isMeetingWithinFiveMinutesOfStarting(meeting?.startTime) ? window.location.href = meeting.zoomLink : setisCannotJoinMeetingModalShown(true)} className="join-meeting-button-user">Join Meeting</button>
                            <p className="join-meeting-text">You can join your meeting 5 minutes before it starts.</p>
                        </> :
                        <>
                            <p className="accept-meeting-text">{meeting.mentorName} will accept one of your proposed meeting times:</p>
                            <div className="proposed-meeting-container">
                                <p className="proposed-meeting-time">{formatDay(meeting["0"].startTime)} @ {formatTime(meeting["0"].startTime)}</p>
                                <p className="proposed-meeting-time">{formatDay(meeting["1"].startTime)} @ {formatTime(meeting["1"].startTime)}</p>
                                <p className="proposed-meeting-time">{formatDay(meeting["2"].startTime)} @ {formatTime(meeting["2"].startTime)}</p>
                            </div>
                        </>}
                    <div className="common-questions">
                        <p>Frequently asked questions:</p>
                        <p onClick={() => faq === 0 ? setFaq(-1) : setFaq(0)} className="common-question">How will I know if my meeting request was accepted by my mentor?</p>
                        {faq === 0 ? <p className="faq">Once your meeting request is accepted by your mentor, you will receive an email notification with the details of your meeting.</p> : null}
                        <p onClick={() => faq === 1 ? setFaq(-1) : setFaq(1)} className="common-question">How will I join the meeting?</p>
                        {faq === 1 ? <p className="faq">Your confirmation email will include a Zoom link to your meeting.</p> : null}
                        <p onClick={() => faq === 2 ? setFaq(-1) : setFaq(2)} className="common-question">What happens if my meeting request is not accepted by my mentor?</p>
                        {faq === 2 ? <p className="faq">If your meeting request is not accepted, you can propose different times to meet with the same mentor, or make a meeting request with a different mentor.</p> : null}
                        <p onClick={() => faq === 3 ? setFaq(-1) : setFaq(3)} className="common-question">Do I need to have questions prepared?</p>
                        {faq === 3 ? <p className="faq">No, but you are expected and encouraged to ask questions!</p> : null}
                        <p onClick={() => faq === 4 ? setFaq(-1) : setFaq(4)} className="common-question">How do I cancel this meeting?</p>
                        {faq === 4 ? <button onClick={() => window.location.href = "/user/meetings"} className="faq-button">Manage Meetings</button> : null}
                        <p onClick={() => faq === 5 ? setFaq(-1) : setFaq(5)} className="common-question">Can I extend the length of the meeting?</p>
                        {faq === 5 ? <p className="faq">Currently, meetings only last 30 minutes. But you can always book another!</p> : null}
                    </div>
                </div>
                <div className="meeting-booked-container pre-meeting-questions-container">
                    <p className="meeting-booked-text">Ask questions before your meeting begins!</p>
                    <p className="pre-meeting-questions-explanation">Help prepare your mentor for their meeting with you by telling them what questions you have or what topics you'd like to cover!</p>
                    <p className="submit-question">Submit a question or topic:</p>
                    {areQuestionsSubmitted ? <p className="pre-meeting-questions-success">Your questions have been successfully submitted!</p>
                        : <>
                            {[...Array(numberOfQuestions).keys()].map((elem, index) => {
                                return <input onChange={(e) => handleMeetingQuestionsChange(e, index)} maxLength="200" placeholder={width < 650 ? questionExamplesSmallWidth[index] : questionExamples[index]} className="submit-question-input"></input>
                            })}
                            {numberOfQuestions < 6 ? <button onClick={() => setNumberOfQuestions(numberOfQuestions + 1)}
                                className="add-another-question">Add another question
                            </button> : null}
                            <div>
                                <button onClick={() => isSubmitButtonDisabled ? null : handleSubmitMeetingQuestions()}
                                    className={isSubmitButtonDisabled ? "submit-pre-meeting-questions disabled" : "submit-pre-meeting-questions"}>
                                    Submit Questions
                                </button>
                            </div>
                        </>}
                </div>
                <div className="meeting-booked-container-short pre-meeting-questions-container">
                    <p className="meeting-booked-text">Please Read!</p>
                    <p className="warning-text">Your meeting request must be accepted</p>
                    <p className="warning-explanation">Before your meeting is officially booked, the mentor must accept one of your proposed meeting times from the meeting request you just submitted. Once accepted by the mentor, your meeting will officially be booked!</p>
                    <p className="warning-text warning-second">How will I know when my meeting is officially booked?</p>
                    <p className="warning-explanation">As soon as your mentor accepts one of your proposed meeting times, you will receive an email notifying you that your meeting is booked! The email will also contain the details of your meeting!</p>
                    <p className="warning-text warning-second">How long will it take for my meeting request to be accepted?</p>
                    <p className="warning-explanation">Your mentor will likely accept your meeting request within a few hours of receiving the request. Although mentors are full-time students so occasionally it can take a day or longer.</p>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default MeetingBooked