import React, { useContext, useState, useEffect } from "react"
import "./index.css"
import { ContextProvider } from '../../../context';
import {
    faCalendarDays, faMoneyCheckDollarPen, faAddressCard, faCalendarDay, faCommentsQuestion
} from "@fortawesome/pro-duotone-svg-icons";
import formatDate from "../../../utils/formatDate";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import formatTime from "../../../utils/formatTime";
import areDatesEqual from "../../../utils/areDatesEqual";
import { Link } from "react-router-dom"
import { getEST } from "../../../API/api";

const Overview = ({ setTabSelected }) => {
    const { user } = useContext(ContextProvider)

    const [currentTime, setCurrentTime] = useState(formatTime(new Date()))

    const [todaysMeetings, setTodaysMeetings] = useState([])

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTime(formatTime(new Date()))
        }, 1000);

        return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }, [])

    useEffect(() => {
        const todaysMeetings = []

        // Check if the user has any meetings in their meetings array
        if (user?.meetings.length === 0) {
            return
        }

        // Check if any of the meetings in the meetings array are today
        user?.meetings.forEach(meeting => {
            if (areDatesEqual(meeting.startTime, new Date())) {
                todaysMeetings.push(meeting)
            }
        })

        setTodaysMeetings([...todaysMeetings])
    }, [])

    // Returns true if the mentor has a meeting sometime in the future
    const getNextMeeting = () => {
        user.meetings.sort((a, b) => new Date(a.startTime) - new Date(b.startTime))
        const nextMeeting = new Date(user.meetings[0].startTime)
        return `${formatDate(nextMeeting)} at ${formatTime(nextMeeting)}`
    }

    const isMeetingWithinFiveMinutesOfStarting = async (meetingStartTime) => {
        const currentEstTime = await getEST()
        const isLessThanFive = new Date(meetingStartTime) - new Date(currentEstTime.data.datetime)
        if (isLessThanFive <= 300000) {
            return true
        }
        return false
    }


    const handleMeetingText = () => {
        console.log(user.meetings)
        // If the user has no meetings today or any in the future
        if (todaysMeetings.length === 0 && user.meetings.length === 0) {
            return "You don't have any meetings scheduled today."
        }

        // If there are no meetings today
        if (todaysMeetings.length === 0) {
            return <>
                <p>You don't have any meetings scheduled today.</p>
                {/* <p className="next-meeting-text">Your next meeting is {getNextMeeting()}</p > */}
            </>
        }

        // If there is one meeting
        if (todaysMeetings.length === 1) {
            return `You have a meeting today at ${formatTime(todaysMeetings[0].startTime)}`
        }

        // If there is more than one meeting
        if (todaysMeetings.length > 1) {
            return `You have ${todaysMeetings.length} meetings today. The first meeting is at ${formatTime(todaysMeetings[0].startTime)}`
        }
    }

    return <>
        <div className="today">
            <div className="today-grid">
                <div className="date-and-time">
                    <p className="todays-date">{formatDate(new Date())}</p>
                    <p className="todays-date">{currentTime} EST</p>
                </div>
                <div className="meeting-message-button">
                    <p className="meeting-today">{handleMeetingText()}</p>
                    <button onClick={() => window.location.href = "/mentor/meetings"} className="join-meeting-button">
                        Manage my meetings
                    </button>
                </div>
            </div>
        </div>
        <p className="welcome-message">Welcome {user?.name.split(" ")[0]}!</p>
        <p className="action-items-message">Here are some action items to get you started.</p>
        <div className="action-items-flex">
            <Link style={{ textDecoration: "none" }} to="/mentor/calendar"> <div onClick={() => setTabSelected(1)} className="action-item">
                <FontAwesomeIcon icon={faCalendarDays} className="action-icon calendar" />
                <p className="action-item-text">Tell us your availability</p>
            </div>
            </Link>
            <Link style={{ textDecoration: "none" }} to={`/mentor/profile/${user.id}`}>
                <div className="action-item">
                    <FontAwesomeIcon icon={faAddressCard} className="action-icon profile" />
                    <p className="action-item-text">Customize your profile</p>
                </div>
            </Link>
            <Link style={{ textDecoration: "none" }} to={`/mentor/earnings`}>
                <div className="action-item">
                    <FontAwesomeIcon icon={faMoneyCheckDollarPen} className="action-icon money" />
                    <p className="action-item-text">Setup your payment</p>
                </div>
            </Link>
            <Link style={{ textDecoration: "none" }} to={`/mentor/forum`}>
                <div className="action-item">
                    <FontAwesomeIcon icon={faCommentsQuestion} className="action-icon forum" />
                    <p className="action-item-text">Answer Forum Questions</p>
                    <p className="mentor-description">Answering questions helps build a rapport with students so they book meetings with you!</p>
                </div>
            </Link>
            <Link style={{ textDecoration: "none" }} to="/?view=mentors">
                <div className="action-item">
                    <FontAwesomeIcon icon={faCalendarDay} className="action-icon book-meeting" />
                    <p className="action-item-text">Book a meeting</p>
                    <p className="mentor-description">Even though you are a mentor, you can still book meetings with other mentors!</p>
                </div>
            </Link>
        </div>
    </>
}

export default Overview