import React, { useEffect, useState } from 'react'
import formatTime from "../../utils/formatTime"
import formatDateShort from "../../utils/formatDateShort"
import formatDateShortest from "../../utils/formatDateShortest"

import { getStorage, ref, getDownloadURL } from "firebase/storage"

const ForumComment = ({ textObject, user, setIsUserReplying, fetchMessageReplies, setForumText, forumText, setAreRepliesShown, isUserReplying }) => {

    const [image, setImage] = useState(null);

    const storage = getStorage();


    useEffect(() => {
        // Check if the user is a mentor
        if (textObject.image) {
            const mentorImageRef = ref(storage, textObject.image)
            getDownloadURL(mentorImageRef).then((img) => {
                setImage(img)
            })
        }
    }, [])


    return (
        <>
            <div className={image ? 'text-picture' : `text-picture colors-${textObject.color} `}>
                {image ?
                    <img className="forum-image" alt="mentor" src={image}
                        onClick={() => window.location.href = `/mentors/${textObject.userId}`}></img> :
                    <p className='user-initials'>{textObject.name.split(" ")[0][0]} {textObject.name.split(" ")[1][0]}</p>
                }
            </div>
            <div>
                <p>
                    <span className={isUserReplying.replyingToId === textObject.id ? "forum-message-username-reply" : 'forum-message-username'}>{textObject.name}</span>
                    <span className={isUserReplying.replyingToId === textObject.id ? "forum-date-reply" : 'forum-date'}>{formatDateShort(new Date(textObject.date))}</span>
                    <span className={isUserReplying.replyingToId === textObject.id ? "forum-date-reply-short" : 'forum-date-short'}>{formatDateShortest(new Date(textObject.date))}</span>
                    <span className={isUserReplying.replyingToId === textObject.id ? "forum-time-reply" : 'forum-time'}>{formatTime(new Date(textObject.date))} EST</span>
                </p>
                <p className='forum-text'>{textObject.message}</p>
                {image ?
                    <a className="book-meeting-link" target="_blank" href={`/mentors/${textObject.userId}`}
                        rel="noreferrer">
                        <p className="book-meeting-link">Schedule a meeting with {textObject.name}</p>
                    </a> : null}
                {textObject.numberOfReplies > 0 ?
                    <div className='reply-flex'>
                        <p className='reply-button' onClick={() => {
                            if (textObject.showReplies) {
                                forumText.forEach(messageObject => {
                                    if (messageObject.id === textObject.id) {
                                        messageObject.showReplies = false
                                        setAreRepliesShown(false)
                                        setIsUserReplying({ isReplying: false })
                                    }
                                })
                                setForumText([...forumText])
                            }
                            else {
                                // Set all the replies to false first
                                forumText.forEach(messageObject => {
                                    messageObject.showReplies = false
                                })
                                setForumText([...forumText])
                                setAreRepliesShown(false)
                                setIsUserReplying({ isReplying: false })
                                fetchMessageReplies(textObject.id)
                                setIsUserReplying({ isReplying: true, isReplyingName: `${textObject.name}'s thread`, replyingToId: textObject.id })
                            }
                        }}>
                            {textObject.showReplies ? "Hide replies" : `View ${textObject.numberOfReplies} ${textObject.numberOfReplies === 1 ? "reply" : "replies"}`}
                        </p>

                    </div>
                    :
                    textObject.email !== user.email && !textObject?.isReply ?
                        <p className='reply-button' onClick={() => {
                            forumText.forEach(messageObject => {
                                messageObject.showReplies = false
                            })
                            setForumText([...forumText])
                            setIsUserReplying({ isReplying: true, isReplyingName: textObject.name, replyingToId: textObject.id })
                            setAreRepliesShown(false)
                        }
                        }>
                            Reply to {textObject.name.split(" ")[0]}
                        </p>
                        : null}
            </div>
        </>
    )
}

export default ForumComment