import React, { useContext, useState, useRef, useEffect } from 'react'
import Navbar from '../Navbar'
import Footer from '../Footer'
import "./index.css"
import { ContextProvider } from '../../context';
import { updateUser } from '../../API/api';

const UserProfile = () => {

    const { user, setUser } = useContext(ContextProvider)

    const firstNameRef = useRef();

    const lastNameRef = useRef();

    const [firstName, setFirstName] = useState(user.name.split(" ")[0])

    const [lastName, setLastName] = useState(user.name.split(" ")[1])

    const [isFirstNameError, setIsFirstNameError] = useState(false)

    const [isLastNameError, setIsLastNameError] = useState(false)

    const [isFirstNameInputActive, setIsFirstNameInputActive] = useState(false)

    const [isLastNameInputActive, setIsLastNameInputActive] = useState(false)

    const [isEditNameClicked, setIsEditNameClicked] = useState(false)

    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

    const [isSubmitted, setIsSubmitted] = useState(false)

    const handleEditName = () => {
        setIsEditNameClicked(!isEditNameClicked)
    }

    useEffect(() => {
        let highlightFirstNameInput = (event) => {
            if (!firstNameRef.current.contains(event.target)) {
                setIsFirstNameInputActive(false)
            }
            // When the user clicks back into the search bar, show the auto-complete again
            if (firstNameRef.current.contains(event.target)) {
                setIsFirstNameInputActive(true)
            }
        }

        document.addEventListener("mousedown", highlightFirstNameInput);
        return () => {
            document.removeEventListener("mousedown", highlightFirstNameInput);
        }
    }, [])

    useEffect(() => {
        let highlightLastNameInput = (event) => {
            if (!lastNameRef.current.contains(event.target)) {
                setIsLastNameInputActive(false)
            }
            // When the user clicks back into the search bar, show the auto-complete again
            if (lastNameRef.current.contains(event.target)) {
                setIsLastNameInputActive(true)
            }
        }

        document.addEventListener("mousedown", highlightLastNameInput);
        return () => {
            document.removeEventListener("mousedown", highlightLastNameInput);
        }
    }, [])

    const handleChangeFirstName = (e) => {
        if (!isLastNameError)
            setIsSubmitDisabled(false)
        setIsFirstNameError(false)
        const userInput = e.target.value
        setFirstName(userInput)

        if (!userInput) {
            setIsFirstNameError(true)
            setIsSubmitDisabled(true)
        }
    }

    const handleChangeLastName = (e) => {
        if (!isFirstNameError)
            setIsSubmitDisabled(false)
        setIsLastNameError(false)
        const userInput = e.target.value
        setLastName(userInput)

        if (!userInput) {
            setIsLastNameError(true)
            setIsSubmitDisabled(true)
        }
    }

    const handleSubmit = () => {
        if (isFirstNameError || isLastNameError || isSubmitDisabled) {
            return
        }
        setIsSubmitted(true)
        updateUser({ firstName, lastName }, (result) => {
            setUser(result.data)
            setIsSubmitted(false)
            setIsSubmitDisabled(true)
        })

    }

    return (
        <>
            <Navbar />
            <div className='user-profile'>
                <div className='user-profile-container'>
                    <p className='personal-information-header'>Personal Information</p>
                    <div className='personal-information-box'>
                        <div className='personal-information-flex'>
                            <p className='personal-information-text'>Name</p>
                            <p onClick={() => handleEditName()}
                                className='personal-information-edit'>
                                {isEditNameClicked ? "Cancel" : "Edit"}
                            </p>
                        </div>
                        {isEditNameClicked ?
                            <>
                                <div className='info-boxes-flex'>
                                    <div ref={firstNameRef} className={isFirstNameError ? 'name-container-personal-info active-border-red-profile' : isFirstNameInputActive ? 'name-container-personal-info active-border-info' : 'name-container-personal-info'}>
                                        <p className={isFirstNameError ? 'red name-header' : 'name-header'}>First name</p>
                                        <input onChange={handleChangeFirstName} placeholder="First Name" value={firstName} className='name-input-info'></input>
                                    </div>
                                    <div ref={lastNameRef} className={isLastNameError ? 'name-container-personal-info active-border-red-profile' : isLastNameInputActive ? 'name-container-personal-info active-border-info' : 'name-container-personal-info'}>
                                        <p className={isLastNameError ? 'red name-header' : 'name-header'}>Last name</p>
                                        <input onChange={handleChangeLastName} placeholder="Last Name" value={lastName} className='name-input-info'></input>
                                    </div>
                                </div>
                                <button onClick={handleSubmit} className={isSubmitted ? "personal-info-save-submitting" : isSubmitDisabled ? 'personal-info-save-disabled' : 'personal-info-save'}>
                                    {isSubmitted ? "Saving" : "Save"}
                                </button>
                            </>
                            : null}
                        {isEditNameClicked ? null : <p className='personal-information-value'>{user.name}</p>}
                    </div>
                    <div className='personal-information-box'>
                        <div className='personal-information-flex'>
                            <p className='personal-information-text'>Email</p>
                        </div>
                        <p className='personal-information-value'>{user.email}</p>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default UserProfile