import firebase from "firebase/compat/app"
import { getAuth, signInWithPopup, GoogleAuthProvider, getRedirectResult } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
require("firebase/compat/firestore");
require("firebase/compat/auth");
require("firebase/compat/database");
require("firebase/compat/functions");
const activeBuildConfig = require("./configs/activebuildconfig.json")


let firebaseConfig = {
  apiKey: activeBuildConfig.API_KEY,
  authDomain: activeBuildConfig.AUTH_DOMAIN,
  projectId: activeBuildConfig.PROJECT_ID,
  storageBucket: activeBuildConfig.STORAGE_BUCKET,
  messagingSenderId: activeBuildConfig.MESSAGING_SENDER_ID,
  appId: activeBuildConfig.APP_ID,
  measurementId: activeBuildConfig.MEASUREMENT_ID
};



const app = firebase.initializeApp(firebaseConfig)
const analytics = getAnalytics(app);
if (process.env.NODE_ENV === 'development') {
  firebase.functions().useEmulator("localhost", 2000);
}
const db = firebase.firestore();
db.settings({ timestampsInSnapshots: true })


export {
  firebase,
  db,
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  getRedirectResult
}