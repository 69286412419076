import React, { useContext, useEffect, useState, useRef } from "react"
import { ContextProvider } from '../../../context';
import { timesArray } from "../../../utils/times";
import { updateCalendar } from "../../../API/api";
import {
    faCheckCircle, faXmark
} from "@fortawesome/pro-solid-svg-icons";
import getMonthIndex from "../../../utils/monthMap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getEST } from "../../../API/api";
import Loading from "../../Loading";
import "./index.css"

const Calendar = () => {

    const { user, setUser } = useContext(ContextProvider)

    const refs = useRef([]);

    const scrollToMiddle = useRef()

    const scrollToTop = useRef()

    const [orginalCalendarData, setOriginalCalendarData] = useState()

    const [calendarData, setCalendarData] = useState(user.calendarData)

    const [isAvailabilitySaved, setIsAvailabilitySaved] = useState(false)

    // Controls the month that is shown on the fixed bar at the top
    const [monthIndex, setMonthIndex] = useState(0)

    const [daySelected, setDaySelected] = useState({ month: 0, day: 0 })

    const [date, setDate] = useState(false)

    const [isSideBarShown, setIsSideBarShown] = useState(document.body.clientWidth > 1000)

    const [isDayDropdownShown, setIsDayDropdownShown] = useState(false)

    useEffect(() => {
        handleGetESTDate()
    }, [])

    useEffect(() => {
        // Every time the screen is resized, check if we should show the hamburger menu
        window.addEventListener('resize', () => {
            setIsSideBarShown(document.body.clientWidth > 1000)
        });
    }, [])

    useEffect(() => {
        scrollToMiddle?.current?.scrollIntoView({ behavior: 'auto', block: 'nearest', inline: 'start' })
        setOriginalCalendarData(structuredClone(user.calendarData))
    }, [isDayDropdownShown, daySelected])


    useEffect(() => {
        scrollToTop?.current?.scrollIntoView({ block: "start", inline: "nearest" })
    }, [])


    useEffect(() => {
        const handleScroll = event => {
            if (monthIndex !== 2 && window.scrollY >= refs.current[monthIndex].offsetTop) {
                setMonthIndex(monthIndex + 1)
            }
            if (monthIndex !== 0 && window.scrollY <= refs.current[monthIndex - 1].offsetTop) {
                setMonthIndex(monthIndex - 1)
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [monthIndex]);

    const handleGetESTDate = async () => {
        const result = await getEST()
        setDaySelected({ month: 0, day: new Date(result.data.datetime).getDate() - 1 })
        setDate(new Date(result.data.datetime))
    }

    const handleCalendarDayClassName = (dayIndex, calendarIndex) => {
        const todaysDate = date.getDate();
        let isBookedToday = false
        const times = calendarData[calendarIndex].days[dayIndex].times
        for (const [, timeObject] of Object.entries(times)) {
            if (timeObject?.isBooked) {
                isBookedToday = true
            }
        }
        return isBookedToday && dayIndex + 1 >= todaysDate ? "mentor-calendar-day-booked" : "mentor-calendar-day"
    }

    const getCalendarBoxClassName = (dayIndex, calendarIndex) => {
        const todaysDate = date.getDate();

        // Check if any of the times for the current day are from the backend
        // If they are, then make the border color of the box blue to signify that the mentor has availability scheduled that day
        // Do the same for isBooked
        let isAvailableToday = false
        let isBookedToday = false
        const times = calendarData[calendarIndex].days[dayIndex].times
        for (const [, timeObject] of Object.entries(times)) {
            if (!("addOrDelete" in timeObject)) {
                isAvailableToday = true
            }
            if (timeObject?.isBooked) {
                isBookedToday = true
            }
        }
        if (dayIndex === 0) {
            if (calendarIndex !== 0 || dayIndex + 1 >= todaysDate) {
                if (dayIndex === daySelected.day && calendarIndex === daySelected.month) {
                    return isBookedToday ? "left-border day-selected booked-box" : isAvailableToday ? "left-border day-selected available-border" : "left-border day-selected"
                }
                else {
                    return isBookedToday ? "calendar-box left-border booked-box" : isAvailableToday ? "calendar-box left-border available-border" : "calendar-box left-border"
                }
            }
            else {
                return "calendar-box left-border not-available"
            }
        }
        else {
            if (calendarIndex !== 0 || dayIndex + 1 >= todaysDate) {
                if (dayIndex === daySelected.day && calendarIndex === daySelected.month) {
                    return isBookedToday ? "day-selected booked-box" : isAvailableToday ? "day-selected available-border" : "day-selected"
                }
                else {
                    return isBookedToday ? "calendar-box booked-box" : isAvailableToday ? "calendar-box available-border" : "calendar-box"
                }
            }
            else {
                return "calendar-box not-available"
            }

        }
    }

    const handleTimeClassName = (time) => {
        // Get the current day from calendar data
        const times = calendarData[daySelected.month].days[daySelected.day].times

        // Loop through the times array
        // If we find a time that matches the time displayed by the element and the element's
        // "addOrDelete" field is not "delete", mark it as selected
        const dateString = date.getHours().toString().padStart(2, '0') + ":" + date.getMinutes().toString().padStart(2, '0')
        // If the time is in the past, disable the time button
        if (daySelected.month === 0 && daySelected.day === date.getDate() - 1 && time.militaryTime <= dateString) {
            return "time-button-disabled"
        }
        for (const [, timeObject] of Object.entries(times)) {
            // If the timeObject time matches the time from the argument of the function
            if (timeObject.time === time.time) {

                // Check if the value of isBooked is true
                // Even if the user clicks on a booked time it should remain green 
                // Because right now we aren't letting users unbook a booked meeting
                if (timeObject?.isBooked) {
                    return "time-button-booked"
                }

                // Add the addOrDelete object doesn't exists or it has value add
                // (This means the time came from the backend so it doesn't have the field, or the user just added it)
                if (!timeObject.addOrDelete || timeObject.addOrDelete === "add") {
                    return "time-button-selected"
                }
            }
        }
        return "time-button"
    }

    const handleSaveAvailabilityButtonClassName = (day) => {
        if (day?.isSaveAvailabilityClicked) {
            return !isSideBarShown ? "save-availability-button-dropdown availability-button-clicked" : "save-availability-button availability-button-clicked"
        }
        if (day?.isAvailabilityChanged) {
            return !isSideBarShown ? "save-availability-button-dropdown" : "save-availability-button"
        }
        else {
            return !isSideBarShown ? "save-availability-button-dropdown button-disabled" : "save-availability-button button-disabled"
        }
    }

    const formatSelectedDay = (utcDate) => {

        // Get the date using UTC from database
        const date = new Date(utcDate._seconds * 1000)

        const options = { timeZone: "America/New_York", weekday: 'short', month: 'short', day: 'numeric' };

        let formattedDate = date.toLocaleDateString("en-US", options)

        const number = formattedDate.split(" ")[2]

        if (number > 3 && number < 21) {
            formattedDate += "th";
            return formattedDate;
        }

        switch (number % 10) {
            case 1: formattedDate += "st";
                break;
            case 2: formattedDate += "nd";
                break;
            case 3: formattedDate += "rd";
                break;
            default: formattedDate += "th";
        }

        return formattedDate;
    }

    const handleSelectTime = (time) => {
        // If the time is in the past, we are done here
        const dateString = date.getHours().toString().padStart(2, '0') + ":" + date.getMinutes().toString().padStart(2, '0')
        if (daySelected.month === 0 && daySelected.day === date.getDate() - 1 && time.militaryTime < dateString) {
            return
        }

        // Get the selected day from calendarData
        const times = calendarData[daySelected.month].days[daySelected.day].times

        // If the time is already booked by a user, we don't want to let the mentor deselect it
        // Later we will need to develop a cancellation process 
        for (const [, timeObject] of Object.entries(times)) {
            // If the timeObject time matches the time from the argument of the function
            if (timeObject.time === time.time) {

                // If the the time is already booked, then don't let the user do anything
                if (timeObject?.isBooked) {
                    return
                }
            }
        }

        // Extract the military time from the time object
        const { militaryTime } = time

        // Checks if the time object already exists and the addOrDelete field exists
        if (times[militaryTime] && times[militaryTime].addOrDelete) {
            // If the mentor added the time and now wants to get rid of it, just delete the object
            if (times[militaryTime].addOrDelete === "add") {
                delete times[militaryTime]
            }
            // If the mentor deleted the object and now wants it add it back
            // Get rid of the addOrDelete field since this must have come from the database
            else {
                delete times[militaryTime].addOrDelete
            }
        }

        // Otherwise if the mentor is clicking on a time that came from the database, don't delete it
        // Just change its addOrDelete field to "delete"
        else if (times[militaryTime]) {
            times[militaryTime].addOrDelete = "delete"
        }

        // If the time object never existed and the mentor wants to add it, we need to create the object
        else {
            times[militaryTime] = time
            times[militaryTime].addOrDelete = "add"
        }

        // Get the original calendar data from the backend
        const orignalTimes = orginalCalendarData[daySelected.month].days[daySelected.day].times
        let isEditedByUser = false;
        // Loop through the new timeObject to see if we have anything different from the orignal
        // If there's a key in the new timeObject that's not in the old one, then an edit has been made by the user
        for (const [key, timeObject] of Object.entries(times)) {
            if (!(key in orignalTimes)) {
                isEditedByUser = true;
                break
            }
            else {
                if (timeObject.addOrDelete === "delete") {
                    isEditedByUser = true;
                    break
                }
            }
        }
        calendarData[daySelected.month].days[daySelected.day].isAvailabilityChanged = isEditedByUser
        // // Set calendarData state with the updated time array
        setCalendarData({ ...calendarData })
    }

    const handleSaveAvailability = (day) => {
        // Only send the request to the backend to save the availability if the availability has been changed
        if (day?.isAvailabilityChanged) {
            // Add a property that tells us that the user has clicked on the Save Availability button so we change the color of the button 
            day.isSaveAvailabilityClicked = true
            setCalendarData({ ...calendarData })
            // Add the month that the user clicked on to the day object
            // We can figure it out on the backend but it'd be easier to just send it from the frontend
            day.monthIndex = daySelected.month

            // Same thing for the day
            day.dayIndex = daySelected.day
            for (const [, timeObject] of Object.entries(day.times)) {
                const dateTime = `${calendarData[day.monthIndex].year}-${getMonthIndex(calendarData[day.monthIndex].month).padStart(2, '0')}-${(daySelected.day + 1).toString().padStart(2, '0')}T${timeObject.militaryTime}:00`
                timeObject.dateTime = new Date(dateTime).toISOString()
            }
            updateCalendar(day, (result) => {
                user.calendarData = result.data
                setUser({ ...user })
                setCalendarData(structuredClone(result.data))
                setOriginalCalendarData(result.data)
                setIsAvailabilitySaved(true)
            })
        }
        else {
            return
        }
    }

    return !date ? <Loading />
        : <>
            <div ref={scrollToTop} className="calendar-page-container">
                <div className="mentor-calendar-container">
                    <div>
                        <div className="fixed-month-year-weekday">
                            <p className="calendar-month">{user.calendarData[monthIndex].month} {user.calendarData[monthIndex].year}</p>
                            <div className="weekdays">
                                <p>Su</p>
                                <p>Mo</p>
                                <p>Tu</p>
                                <p>We</p>
                                <p>Th</p>
                                <p>Fr</p>
                                <p>Sa</p>
                            </div>
                            <hr className="weekday-line"></hr>
                        </div>
                        <div className="mentor-calendar-grid">
                            {/* Loop over all the months */}

                            {Object.entries(user.calendarData).map(([, value], calendarIndex) => {
                                const currentMonth = value
                                let calendarNames = []
                                // Don't show the name of the month for the first month since it'll already be fixed at the top
                                if (calendarIndex !== 0) {
                                    calendarNames = [...Array(7).keys()].map((elem, i) => {
                                        // Only show the name of the month for the first column of the grid, otherwise just show an empty box
                                        if (i === 0) {
                                            return <p
                                                className="month-year-in-calendar"
                                                ref={(el) => {
                                                    refs.current.splice(0, 0);
                                                    if (refs.current.length < 12) {
                                                        refs.current.push(el)
                                                    }
                                                }} >
                                                {currentMonth.month} {currentMonth.year}
                                            </p>
                                        }
                                        return <div className="calendar-box-blank"></div>
                                    })
                                }
                                // Go through all the weekdays where the month hasn't started yet
                                // Ex. if the month starts on a Friday, Loop through Sunday to Thursday showing a blank box
                                const blankCalendarDays = [...Array(currentMonth.firstDayOfTheMonth).keys()].map(() => {
                                    return <div className="calendar-box-blank"></div>
                                })
                                // Actually loop through the days in the month
                                const calendarDays = [...Array(currentMonth.daysInMonth).keys()].map((elem, dayIndex) => {
                                    return <div
                                        key={dayIndex}
                                        className={getCalendarBoxClassName(dayIndex, calendarIndex)}
                                        onClick={() => {
                                            // If the date the mentor clicks on is in the past, don't let them select the day
                                            const todaysDate = date.getDate();
                                            if (calendarIndex === 0 && dayIndex + 1 < todaysDate) { return }
                                            setDaySelected({ month: calendarIndex, day: dayIndex })

                                            // If the screen size is small enough, then we need to show the overlay to show the current clicked on day
                                            setIsDayDropdownShown(true)
                                        }
                                        }>
                                        <p className={handleCalendarDayClassName(dayIndex, calendarIndex)}>{dayIndex + 1}</p>
                                    </div>
                                })

                                // Loop through the remaining days of the month to finish out the week
                                // So if the month ends on a Wednesday, continue looping until Saturday
                                const remainingCalendarDays = [...Array(7 - ((currentMonth.firstDayOfTheMonth + currentMonth.daysInMonth) % 7)).keys()].map((elem, index) => {
                                    return <div className="calendar-box-blank"></div>
                                })
                                return [...calendarNames, ...blankCalendarDays, ...calendarDays, ...remainingCalendarDays]
                            })
                            }
                        </div>
                    </div>
                    <div className="calendar-grid">

                    </div>
                </div>
                {!isSideBarShown && !isDayDropdownShown ? null
                    : !isSideBarShown && isDayDropdownShown ?
                        <div className="day-selected-overlay">
                            <FontAwesomeIcon onClick={() => setIsDayDropdownShown(false)} icon={faXmark} className="x-icon-calendar" />
                            <p className="current-day-selected-dropdown">{daySelected.month != null ? formatSelectedDay(calendarData[daySelected.month].days[daySelected.day].date) : null}</p>
                            <p className="select-availability-dropdown">Select Availability</p>
                            <p className="select-availability-instructions-dropdown">You are marked unavailable by default. Click on the time slots you are available for a meeting.</p>
                            <hr className="calendar-horizontal-line" />
                            <div className="available-time-grid-dropdown">
                                {timesArray.map((timeObject) => {
                                    if (date.getHours() === 23) {
                                        if (parseInt(timeObject.militaryTime.split(":")[0]) === 23) {
                                            return <button ref={scrollToMiddle} onClick={() => handleSelectTime(timeObject)} className={handleTimeClassName(timeObject)}>{timeObject.time} EST</button>
                                        }
                                    }
                                    else if (parseInt(timeObject.militaryTime.split(":")[0]) - 1 === date.getHours()) {
                                        return <button ref={scrollToMiddle} onClick={() => handleSelectTime(timeObject)} className={handleTimeClassName(timeObject)}>{timeObject.time} EST</button>
                                    }
                                    return <button onClick={() => handleSelectTime(timeObject)} className={handleTimeClassName(timeObject)}>{timeObject.time} EST</button>
                                })}
                            </div>
                            <hr className="calendar-horizontal-line" />
                            <button
                                className={handleSaveAvailabilityButtonClassName(calendarData[daySelected.month].days[daySelected.day])}
                                onClick={() => handleSaveAvailability(calendarData[daySelected.month].days[daySelected.day])}>
                                {calendarData[daySelected.month].days[daySelected.day].isSaveAvailabilityClicked ? "Saving availability..." : "Save availability"}
                            </button>
                            <p className="save-availability-instructions-dropdown">Your availability will not be saved unless <br></br> this button is clicked.</p>
                            {isAvailabilitySaved ? <div onClick={setTimeout(() => { setIsAvailabilitySaved(false) }, 3000)} className="availability-saved-container-dropdown">
                                <p className="availability-saved">Availability Saved</p>
                                <FontAwesomeIcon icon={faCheckCircle} className="circle-check-icon" />
                            </div> : null}
                        </div> :
                        <div className="current-day-container">
                            <p className="current-day-selected">{daySelected.month != null ? formatSelectedDay(calendarData[daySelected.month].days[daySelected.day].date) : null}</p>
                            <hr className="current-day-line"></hr>
                            <p className="select-availability">Select Availability</p>
                            <p className="select-availability-instructions">You are marked unavailable by default. Click on the time slots you are available for a meeting.</p>
                            <div className="available-time-grid">
                                {timesArray.map((timeObject) => {
                                    if (date.getHours() === 23) {
                                        if (parseInt(timeObject.militaryTime.split(":")[0]) === 23) {
                                            return <button ref={scrollToMiddle} onClick={() => handleSelectTime(timeObject)} className={handleTimeClassName(timeObject)}>{timeObject.time} EST</button>
                                        }
                                    }
                                    else if (parseInt(timeObject.militaryTime.split(":")[0]) - 1 === date.getHours()) {
                                        return <button ref={scrollToMiddle} onClick={() => handleSelectTime(timeObject)} className={handleTimeClassName(timeObject)}>{timeObject.time} EST</button>
                                    }
                                    return <button onClick={() => handleSelectTime(timeObject)} className={handleTimeClassName(timeObject)}>{timeObject.time} EST</button>
                                })}
                            </div>
                            <button
                                className={handleSaveAvailabilityButtonClassName(calendarData[daySelected.month].days[daySelected.day])}
                                onClick={() => handleSaveAvailability(calendarData[daySelected.month].days[daySelected.day])}>
                                {calendarData[daySelected.month].days[daySelected.day].isSaveAvailabilityClicked ? "Saving availability..." : "Save availability"}
                            </button>
                            <p className="save-availability-instructions">Your availability will not be saved unless <br></br> this button is clicked.</p>
                            {isAvailabilitySaved ? <div onClick={setTimeout(() => { setIsAvailabilitySaved(false) }, 3000)} className="availability-saved-container">
                                <p className="availability-saved">Availability Saved</p>
                                <FontAwesomeIcon icon={faCheckCircle} className="circle-check-icon" />
                            </div> : null}
                        </div>}
            </div>
        </>
}

export default Calendar