import React, { useState, useContext, useEffect } from "react"
import Navbar from "../../Navbar"
import Footer from "../../Footer"
import { updatePayment } from "../../../API/api"
import { ContextProvider } from '../../../context';
import {
    faCheckCircle
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "./index.css"

const MentorEarnings = () => {

    const { user, setUser } = useContext(ContextProvider)

    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)

    const [isSubmitClicked, setIsSubmitClicked] = useState(false)

    const [venmoUsernameOne, setVenmoUsernameOne] = useState(user.payment.venmo ? user.payment.venmo : "")

    const [venmoUsernameTwo, setVenmoUsernameTwo] = useState(user.payment.venmo ? user.payment.venmo : "")

    const [venmo, setVenmo] = useState(user.payment.venmo)

    const [isPaymentSetup, setIsPaymentSetup] = useState(user.payment.venmo ? true : false)

    useEffect(() => {
        window.scrollTo(0, 0)
    })

    const handleCheckVenmoUsername = (updatedUsername, usernameNum) => {
        if (usernameNum === 1) {
            if (updatedUsername && updatedUsername === venmoUsernameTwo) {
                setIsSubmitDisabled(false)
            }
            else {
                setIsSubmitDisabled(true)
            }
        }
        else {
            if (updatedUsername && updatedUsername === venmoUsernameOne) {
                setIsSubmitDisabled(false)
            }
            else {
                setIsSubmitDisabled(true)
            }
        }
    }

    const handleSubmitVenmo = () => {
        setIsSubmitClicked(true)
        updatePayment({ "venmo": venmoUsernameOne }, (result) => {
            setIsSubmitDisabled(true)
            setIsSubmitClicked(false)
            user.payment = result.data.payment
            setUser({ ...user })
            setVenmo(result.data.payment.venmo)
            setIsPaymentSetup(true)
        })
    }

    return (
        <>
            <Navbar />
            <div className="payment-container">
                <p className="payment-title">Get paid quickly and securely with Venmo</p>
                {isPaymentSetup ?
                    <>
                        <div className="payment-flex">
                            <p className="payment-title-2">Your payment has been set up.</p>
                            <FontAwesomeIcon icon={faCheckCircle} className="payment-check" />
                        </div>
                        <p className="venmo-username">Venmo username:</p>
                        <input disabled value={venmo} className="venmo-input-no-edit"></input>
                        <button onClick={() => setIsPaymentSetup(false)}
                            className="update-venmo">
                            Update Payment Info
                        </button>
                    </>
                    :
                    <>
                        <p className="payment-title-2">Set up your payment</p>
                        <input value={venmoUsernameOne} onChange={(e) => {
                            setVenmoUsernameOne(e.target.value)
                            handleCheckVenmoUsername(e.target.value, 1)
                        }} className="venmo-input" placeholder="Enter your Venmo username"></input>
                        <input value={venmoUsernameTwo} onChange={(e) => {
                            setVenmoUsernameTwo(e.target.value)
                            handleCheckVenmoUsername(e.target.value, 2)
                        }} className="venmo-input" placeholder="Re-enter your Venmo username"></input>
                        <button disabled={isSubmitDisabled} onClick={() => isSubmitClicked ? null : handleSubmitVenmo()}
                            className={isSubmitDisabled ? "submit-venmo disabled" : "submit-venmo"}>
                            {isSubmitClicked ? "Submitting..." : "Submit"}
                        </button>
                    </>}
            </div>
            <Footer />
        </>
    )
}

export default MentorEarnings