import React, { useEffect, useState, useRef } from 'react'
import Navbar from '../Navbar'
import Footer from '../Footer'
import { useLocation } from "react-router-dom"
import { getMeetingUser } from '../../API/api'
import { getEST } from '../../API/api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faXmark
} from "@fortawesome/pro-solid-svg-icons";
import Loading from '../Loading'
import { joinMeetingUser } from '../../API/api'


const JoinMeetingUser = () => {

    const location = useLocation();

    const [meeting, setMeeting] = useState(null)

    const [isCannotJoinMeetingModalShown, setisCannotJoinMeetingModalShown] = useState(false)

    const cannotJoinModalRef = useRef()

    const [isLoading, setIsLoading] = useState(true)

    const [isMeetingError, setIsMeetingError] = useState(false)

    const [meetingId, setMeetingId] = useState(null)

    useEffect(() => {
        // Get the mentor's id from the query string
        const meetingId = location.pathname.split("/")[4];

        setMeetingId(meetingId)

        // Call getMentor endpoint to get the mentor information
        fetchMeeting(meetingId)

    }, [])

    useEffect(() => {
        let closeCannotJoinModalRef = (event) => {
            if (!cannotJoinModalRef?.current.contains(event.target)) {
                setisCannotJoinMeetingModalShown(false)
            }
        }

        document.addEventListener("mousedown", closeCannotJoinModalRef);
        return () => {
            document.removeEventListener("mousedown", closeCannotJoinModalRef);
        }
    }, [])

    const fetchMeeting = async (meetingId) => {
        const result = await getMeetingUser(meetingId);
        if (result.success) {
            setMeeting(result.data)
            setIsLoading(false)
        }
        if (!result.success) {
            setIsMeetingError(true)
            setIsLoading(false)
        }

    }

    const formatDay = (utcDate) => {

        // Get the date using UTC from database
        const date = new Date(utcDate)

        const options = { timeZone: "America/New_York", weekday: 'long', month: 'long', day: 'numeric' };

        let formattedDate = date.toLocaleDateString("en-US", options)

        const number = formattedDate.split(" ")[2]

        if (number > 3 && number < 21) {
            formattedDate += "th";
            return formattedDate;
        }

        switch (number % 10) {
            case 1: formattedDate += "st";
                break;
            case 2: formattedDate += "nd";
                break;
            case 3: formattedDate += "rd";
                break;
            default: formattedDate += "th";
        }

        return formattedDate;
    }

    const formatTime = (utcDate) => {

        // Get the date using UTC from database
        const date = new Date(utcDate)

        const options = { timeZone: "America/New_York", hour: '2-digit', minute: '2-digit' };

        let formattedTime = date.toLocaleTimeString("en-US", options)

        const hours = formattedTime.split(":")[0]

        // Get rid of leading 0
        if (hours[0] === "0") {
            formattedTime = formattedTime.substring(1)
        }
        return formattedTime
    }

    const isMeetingWithinFiveMinutesOfStarting = async (meetingStartTime) => {
        const currentEstTime = await getEST()
        const isLessThanFive = new Date(meetingStartTime) - new Date(currentEstTime.data.datetime)
        if (isLessThanFive <= 300000) {
            return true
        }
        return false
    }

    return isLoading ? <Loading />
        :
        <>
            <Navbar />
            {isCannotJoinMeetingModalShown ?
                <div ref={cannotJoinModalRef} className='cannot-join-meeting-yet'>
                    <FontAwesomeIcon icon={faXmark} className="icon-x-mark" onClick={() => setisCannotJoinMeetingModalShown(false)} />
                    <p className="cannot-join-meeting-header-text">
                        {!meeting.hasMentorAcceptedMeetingRequest ? "Your meeting request has not been accepted yet" : "Your meeting has not started yet"}
                    </p>
                    <p className='refresh-warning'>Make sure that you have refreshed the page recently.</p>
                    <p className="cannot-join-meeting-sub-text">
                        {!meeting.hasMentorAcceptedMeetingRequest ? "Your mentor must accept your meeting request before the meeting can begin." : "The join button will become active 5 minutes before your meeting begins."}
                    </p>
                </div>
                : null}
            {isMeetingError ?
                <div className="meeting-booked-container">
                    <p className="meeting-booked-text">There was an error with your meeting</p>
                    <p className="no-meeting-error">Please contact wilteducation@wilteducation for assistance.</p>
                </div>
                :
                <div className={isCannotJoinMeetingModalShown ? "meeting-booked-container blur-background meeting-booked-long" : "meeting-booked-container meeting-booked-long"}>
                    <p className="meeting-booked-text">Join your meeting!</p>
                    <div className="date-time-grid">
                        <div style={{ textAlign: "center" }}>
                            <p className="date">Date</p>
                            <p className="date-text">{formatDay(meeting.startTime)}</p>
                        </div>
                        <div style={{ textAlign: "center" }}>
                            <p className="time">Time</p>
                            <p className="date-text">{formatTime(meeting.startTime)} - {formatTime(new Date(new Date(meeting.startTime).getTime() + 30 * 60000))} EST</p>
                        </div>
                    </div>
                    <p className="how-to-join-text">Join your meeting when it's about to start</p>
                    <button onClick={async () => {
                        if (meeting?.hasMentorAcceptedMeetingRequest && await isMeetingWithinFiveMinutesOfStarting(meeting?.startTime)) {
                            window.location.href = meeting.zoomLink
                            await joinMeetingUser(meetingId)
                        } else {
                            setisCannotJoinMeetingModalShown(true)
                        }
                    }} className="join-meeting-button-user">Join Meeting</button>
                    <p className="join-meeting-text">You can join your meeting 5 minutes before it starts.</p>
                    <p className="reminders-header">Important Reminders:</p>
                    <ol type="1">
                        <li className="reminders-text">Please join your meeting five minutes before it begins. Make sure to refresh the page if the "Join Meeting" button does not allow you to join.</li>
                        <li className="reminders-text">If your mentor does not show up to your meeting after five minutes, please email wilteducation@wilteducation.com for assistance.</li>
                        <li className="reminders-text">We strongly encourage you to have your Zoom camera on, ask lots of questions, and treat the meeting like a normal conversation. Your mentor has great college advice and experience to share, so make sure to ask them about it!</li>
                    </ol>
                </div>
            }
            <Footer />
        </>
}

export default JoinMeetingUser