import React from "react";
import "./index.css";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import PrivateRouteMentor from "../../utils/PrivateRouteMentor";
import PrivateRouteUser from "../../utils/privateRouteUser";
import BaseRoute from "../../utils/BaseRoute";
import Homepage from "../Homepage";
import { AboutUs } from "../AboutUs";
import { Context } from "../../context"
import MentorBio from "../MentorBio";
import ScrollToTop from "../ScrollToTop";
import MentorSignup from "../MentorSignup";
import MentorDashboard from "../MentorDashboard/Dashboard";
import MeetingBooked from "../MeetingBooked";
import UserProfile from "../UserProfile.js";
import UserMeetings from "../UserMeetings";
import MentorProfile from "../MentorProfile";
import MentorMeetings from "../MentorMeetings";
import EmailError from "../EmailError";
import InfoMeeting from "../Info/Meetings";
import MentorEarnings from "../MentorDashboard/MentorEarnings";
import JoinMeetingUser from "../JoinMeetingUser";
import JoinMeetingMentor from "../JoinMeetingMentor";
import AcceptMeeting from "../AcceptMeeting";
import { TermsAndConditions } from "../TermsAndConditions";
import MentorForum from "../MentorForum";


const App = () => {
  return (
    <Context>
      <Router>
        <ScrollToTop>
          <Routes>
            <Route
              exact path="/"
              element={
                <BaseRoute>
                  <Homepage />
                </BaseRoute>
              }
            />
            <Route
              path="/mentor"
              element={
                <PrivateRouteMentor>
                  <MentorDashboard tabSelectedProp={0} />
                </PrivateRouteMentor>
              }
            />
            <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
            <Route path="/meeting/accept/:meetingId" element={<AcceptMeeting />} />
            <Route path="/meeting/join/user/:meetingId" element={<JoinMeetingUser />} />
            <Route path="/meeting/join/mentor/:meetingId" element={<JoinMeetingMentor />} />
            <Route path="/product" element={<AboutUs />} />
            <Route path="/info/meetings" element={<InfoMeeting />} />
            <Route path="/enterprise" element={<AboutUs />} />
            <Route path="/email/error" element={<EmailError />} />
            <Route path="/pricing" element={<AboutUs />} />
            <Route path="/sales" element={<AboutUs />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/mentor/signup" element={<MentorSignup />} />
            <Route path="/mentors/:mentorEmail" element={<MentorBio />} />
            <Route
              exact path="/meeting/booked/:meetingId"
              element={
                <PrivateRouteUser>
                  <MeetingBooked />
                </PrivateRouteUser>
              }
            />
            <Route
              exact path="/mentor/profile/:mentorId"
              element={
                <PrivateRouteMentor>
                  <MentorProfile />
                </PrivateRouteMentor>
              }
            />
            <Route
              exact path="/mentor/earnings"
              element={
                <PrivateRouteMentor>
                  <MentorEarnings />
                </PrivateRouteMentor>
              }
            />
            <Route
              exact path="/mentor/meetings"
              element={
                <PrivateRouteMentor>
                  <MentorMeetings />
                </PrivateRouteMentor>
              }
            />
            <Route
              exact path="/mentor/calendar"
              element={
                <PrivateRouteMentor>
                  <MentorDashboard tabSelectedProp={1} />
                </PrivateRouteMentor>
              }
            />
            <Route
              exact path="/mentor/forum"
              element={
                <PrivateRouteMentor>
                  <MentorForum />
                </PrivateRouteMentor>
              }
            />
            <Route
              exact path="/user/profile"
              element={
                <PrivateRouteUser path="/user/profile">
                  <UserProfile />
                </PrivateRouteUser>
              }
            />
            <Route
              exact path="/user/meetings"
              element={
                <PrivateRouteUser path="/user/meetings">
                  <UserMeetings />
                </PrivateRouteUser>
              }
            />
          </Routes>
        </ScrollToTop>
      </Router>
    </Context>
  );
}

export default App;
