
import firebase from 'firebase/compat/app';
const { API_ROOT_URL } = require("../../../configs/activebuildconfig.json");

/**
 * CONTROLLER - mentor
 * FUNCTION - updateMentor
 * VERIFY - an authenticated mentor
 * @param token the token of the authenticated mentor
 * @returns The account data for the authenticated mentor
 * NOTE: Currently this function will just update the mentors name, but eventually, it should be able to update the entire mentor object
 * We don't want a scenario where we have many different functions that update different parts of the mentor object
 */

export const updateMentor = async (mentorData, callback) => {
    firebase.auth().onAuthStateChanged(async user => {
        if (!user) {
            callback({ isUserAuthenticated: false })
            return;
        }
        const token = await user.getIdToken(false);
        const response = await fetch(`${API_ROOT_URL}/mentor/update_mentor`, {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-type": "application/json",
            },
            body: JSON.stringify({
                token,
                mentorData
            }),
        })
        const result = await response.json();
        if (result.success) {
            result.isUserAuthenticated = true
        }
        else {
            result.isUserAuthenticated = false
        }
        callback(result)
    })
}