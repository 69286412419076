import React, { useEffect, useContext } from 'react'
import Navbar from '../Navbar'
import Footer from '../Footer'
import Loading from "../Loading"
import { ContextProvider } from '../../context';
import { TypeAnimation } from 'react-type-animation';
import signUpWithGoogle from "../../utils/signUp"

import "./index.css"


export const TermsAndConditions = () => {

    const { user, isLoading, setIsLoading } = useContext(ContextProvider)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return isLoading ? <Loading />
        : (
            <>
                <Navbar />

                <div className='terms-and-conditions-container'>

                </div>

                <div className='terms-and-conditions-container-2'>
                    <div className='terms-and-conditions-grid'>
                        <div className='school-tours-top-center'>

                            <p className='terms-and-conditions-header'>Terms and conditions</p>
                            <p className='terms-and-conditions-text'>

                                <p clasName='under-header'>
                                    By using Wilteducation.com, you are agreeing to be bound by the following terms and conditions:
                                </p>
                                <p className='eligibility-text'>
                                    Eligibility: You must be at least 18 years old to use Wilteducation.com, or have the consent of a parent or legal guardian if you are under 18.
                                </p>

                                <p className='user-connduct-text'>
                                    User Conduct: You agree to use Wilteducation.com and its services only for lawful purposes and in accordance with these terms and conditions. You will not use the website to engage in any activity that is harmful, threatening, abusive, harassing, tortuous, defamatory, vulgar, obscene, libelous, invasive of another's privacy, hateful, or racially, ethnically or otherwise objectionable.
                                </p>

                                <p className='privacy-text'>
                                    Privacy: We respect your privacy and will not share your personal information withs any third party without your consent. However, we may use your personal information to contact you about the website and our services, or to send you promotional materials from our partners.
                                </p>

                                <p className='video-meeting-text'>
                                    Video Meetings: You understand that the video meetings facilitated through Wilteducation.com are intended for informational purposes only and are not intended as a substitute for professional advice. You also understand that we do not endorse or guarantee the accuracy or completeness of any information provided by current college students during the video meetings.
                                </p>

                                <p className='disclaimers-text'>
                                    Disclaimers and Limitations of Liability: Wilteducation.com and its services are provided "as is" and "as available" without warranty of any kind, either express or implied, including, but not limited to, the implied warranties of merchantability and fitness for a particular purpose. We will not be liable for any damages whatsoever, including but not limited to direct, indirect, special, incidental, or consequential damages, arising out of or in connection with the use or inability to use Wilteducation.com or its services.
                                </p>

                                <p className='changes-text'>
                                    Changes to the Terms and Conditions: We reserve the right to make changes to these terms and conditions at any time. Your continued use of the website following any changes indicates your acceptance of the new terms and conditions.
                                </p>

                                <p className='governing-law-text'>
                                    Governing Law and Jurisdiction: These terms and conditions shall be governed by and construed in accordance with the laws of the jurisdiction in which the website is headquartered, without giving effect to any principles of conflicts of law.
                                </p>


                                By using Wilteducation.com, you are indicating your acceptance of these terms and conditions. If you do not agree to these terms and conditions, please do not use Wilteducation.com.
                            </p>
                        </div>
                    </div>
                </div>

                <Footer />
            </>
        )//return

}