import React from 'react'
import { faLaptopCode, faGear, faPaintBrushFine, faLandmarkDome, faUserTieHairLong, faHeadSideBrain, faUserDoctorHair, faFlask, faPlane, faUserNurseHairLong, faPersonChalkboard, faEarthAmericas, faBuildingColumns, faChildReaching } from "@fortawesome/pro-thin-svg-icons";
import {
    faLaptopCode as faLaptopCodeSolid, faGear as faGearSolid, faPaintBrushFine as faPaintBrushFineSolid, faLandmarkDome as faLandmarkDomeSolid,
    faUserTieHairLong as faUserTieHairLongSolid, faHeadSide as faHeadSideBrainSolid, faUserDoctorHair as faUserDoctorHairSolid, faFlask as faFlaskSolid, faPlane as faPlaneSolid, faUserNurseHairLong as faUserNurseHairLongSolid,
    faPersonChalkboard as faPersonChalkboardSolid, faEarthAmericas as faEarthAmericasSolid, faBuildingColumns as faBuildingColumnsSolid, faChildReaching as faChildReachingSolid
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
const SchoolFilter = ({ filterSelected, handleSelectFilter }) => {
    return (
        <>
            <div className={filterSelected === "lsa" ? "filter-icon-selected" : "filter-icon"} onClick={() => handleSelectFilter("lsa")}>
                <FontAwesomeIcon icon={filterSelected === "lsa" ? faEarthAmericasSolid : faEarthAmericas} className="icon" />
                <p className={filterSelected === "lsa" ? "filter-icon-text-selected" : "filter-icon-text"}>LSA</p>
            </div>
            <div className={filterSelected === "eng" ? "filter-icon-selected" : "filter-icon"} onClick={() => handleSelectFilter("eng")}>
                <FontAwesomeIcon icon={filterSelected === "eng" ? faGearSolid : faGear} className="engineering-icon icon" />
                <p className={filterSelected === "eng" ? "filter-icon-text-selected" : "filter-icon-text"}>Engineering</p>
            </div>
            <div className={filterSelected === "arch" ? "filter-icon-selected" : "filter-icon"} onClick={() => handleSelectFilter("arch")} >
                <FontAwesomeIcon icon={filterSelected === "arch" ? faBuildingColumnsSolid : faBuildingColumns} className="icon arch-icon" />
                <p className={filterSelected === "arch" ? "filter-icon-text-selected" : "filter-icon-text"}>Architecture</p>
            </div>
            <div className={filterSelected === "kin" ? "filter-icon-selected" : "filter-icon"} onClick={() => handleSelectFilter("kin")} >
                <FontAwesomeIcon icon={filterSelected === "kin" ? faChildReachingSolid : faChildReaching} className="poli-sci-icon icon kin-icon" />
                <p className={filterSelected === "kin" ? "filter-icon-text-selected" : "filter-icon-text"}>Kinesiology</p>
            </div>
            <div className={filterSelected === "bus" ? "filter-icon-selected" : "filter-icon"} onClick={() => handleSelectFilter("bus")}>
                <FontAwesomeIcon icon={filterSelected === "bus" ? faUserTieHairLongSolid : faUserTieHairLong} className="business-icon icon" />
                <p className={filterSelected === "bus" ? "filter-icon-text-selected" : "filter-icon-text"}>Business</p>
            </div>
            <div className={filterSelected === "health" ? "filter-icon-selected" : "filter-icon"} onClick={() => handleSelectFilter("health")}>
                <FontAwesomeIcon icon={filterSelected === "health" ? faUserDoctorHairSolid : faUserDoctorHair} className="pre-med-icon icon health-icon" />
                <p className={filterSelected === "health" ? "filter-icon-text-selected" : "filter-icon-text"}>Public Health</p>
            </div>
            <div className={filterSelected === "nurse" ? "filter-icon-selected" : "filter-icon"} onClick={() => handleSelectFilter("nurse")}>
                <FontAwesomeIcon icon={filterSelected === "nurse" ? faUserNurseHairLongSolid : faUserNurseHairLong} className="nursing-icon icon" />
                <p className={filterSelected === "nurse" ? "filter-icon-text-selected" : "filter-icon-text"}>Nursing</p>
            </div>
            <div className={filterSelected === "edu" ? "filter-icon-selected" : "filter-icon"} onClick={() => handleSelectFilter("edu")}>
                <FontAwesomeIcon icon={filterSelected === "edu" ? faPersonChalkboardSolid : faPersonChalkboard} className="education-icon icon" />
                <p className={filterSelected === "edu" ? "filter-icon-text-selected" : "filter-icon-text"}>Education</p>
            </div>
        </>
    )
}

export default SchoolFilter