const formatDate = (dateToBeFormatted) => {

    // Get the date using UTC from database
    const date = new Date(dateToBeFormatted)

    const options = { timeZone: "America/New_York", weekday: 'long', month: 'long', day: 'numeric' };

    let formattedDate = date.toLocaleDateString("en-US", options)

    const number = formattedDate.split(" ")[2]

    if (number > 3 && number < 21) {
        formattedDate += "th";
        return formattedDate;
    }

    switch (number % 10) {
        case 1: formattedDate += "st";
            break;
        case 2: formattedDate += "nd";
            break;
        case 3: formattedDate += "rd";
            break;
        default: formattedDate += "th";
    }

    return formattedDate;
}

export default formatDate