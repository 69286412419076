
const { API_ROOT_URL } = require("../../../configs/activebuildconfig.json")


/**
 * CONTROLLER - user
 * FUNCTION - getMessageReplies
 * VERIFY - Any User
 * @param {*} messageId The id of the message we want to get the replies to
 * @returns Returns the message replies
 */

export const getMessageReplies = async (messageId) => {
    const response = await fetch(`${API_ROOT_URL}/user/get_message_replies`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-type": "application/json",
        },
        body: JSON.stringify({
            messageId
        })
    })
    const result = await response.json();
    return result;
}