
const { API_ROOT_URL } = require("../../../configs/activebuildconfig.json")


/**
 * CONTROLLER - mentor
 * FUNCTION - getMentors
 * VERIFY - any user
 * @returns Returns a preview of data for all the mentors on our service (currently name, email, major, minor, and clubs)
 */

export const getMentors = async () => {
    const response = await fetch(`${API_ROOT_URL}/mentor/get_mentors`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-type": "application/json",
        }
    })
    const result = await response.json();
    return result;
}