
const { API_ROOT_URL } = require("../../../configs/activebuildconfig.json")


/**
 * CONTROLLER - mentor
 * FUNCTION - declineMeeting
 * VERIFY - any user (Eventually should be an authenticated route)
 * @param {*} meetingId The id of the meeting being fetched
 * @returns The updated meeting
 */

export const declineMeeting = async (meetingId) => {
    const response = await fetch(`${API_ROOT_URL}/mentor/decline_meeting`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-type": "application/json",
        },
        body: JSON.stringify({
            meetingId
        })
    })
    const result = await response.json();
    return result;
}