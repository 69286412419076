import firebase from 'firebase/compat/app';
const { API_ROOT_URL } = require("../../../configs/activebuildconfig.json")



/**
 * CONTROLLER - mentor
 * FUNCTION - createMentor
 * VERIFY - User who is authenticated on the frontend (Currently with google)
 * @param {*} mentorData data about the mentor including their name, email, major, minor and clubs
 * @param {*} callback callback function that's takes in the result from the endpoint
 * @returns Returns the newly created mentor
 */

export const createMentor = async (mentorData, callback) => {
    firebase.auth().onAuthStateChanged(async mentor => {
        const token = await mentor.getIdToken(false);
        const response = await fetch(`${API_ROOT_URL}/mentor/create_mentor`, {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-type": "application/json",
            },
            body: JSON.stringify({
                token,
                mentorData
            }),
        })
        const result = await response.json();
        callback(result)
    })
}

