const formatDateShortest = (dateToBeFormatted) => {

    // Get the date using UTC from database
    const date = new Date(dateToBeFormatted)

    const options = { timeZone: "America/New_York", weekday: 'short' };

    let formattedDate = date.toLocaleDateString("en-US", options)


    return formattedDate;
}

export default formatDateShortest