import React, { useEffect, useState, useContext } from "react"
import { getStorage, ref, getDownloadURL } from "firebase/storage"
import { ContextProvider } from '../../context';
import Navbar from "../Navbar"
import Calendar from "../Calendar"
import Footer from "../Footer"
import { getMentor } from "../../API/api"
import { useLocation } from "react-router-dom"
import Loading from "../Loading"
import {
    faMessages, faBookmark, faStarSharp, faBlockQuestion, faHouseBuilding, faSchool, faBuildingColumns, faDiploma, faGraduationCap, faUserGraduate
} from "@fortawesome/pro-solid-svg-icons";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "./index.css"
import confirmMeetingRequest from "../../utils/confirmMeetingRequest"


const MentorBio = () => {

    const { user } = useContext(ContextProvider)

    const location = useLocation();

    const [mentorProfile, setMentorProfile] = useState(null);

    const [image, setImage] = useState(null);

    const [isLoading, setIsLoading] = useState(true)

    const storage = getStorage();

    const colors = ["blue", "pink", "purple", "red", "orange"]

    const [width, setWidth] = useState(document.body.clientWidth);

    const [isReviewRequestShowing, setIsReviewRequestShowing] = useState(false)

    const [reviewRequestData, setReviewRequestData] = useState({})

    const [faqIndex, setFaqIndex] = useState(null)

    const [isSubmitMeetingRequestClicked, setIsSubmitMeetingRequestClicked] = useState(false)

    useEffect(() => {
        // Get the mentor's id from the query string
        const id = location.pathname.split("/")[2];

        // Call getMentor endpoint to get the mentor information
        fetchMentor(id);

        // Make sure the window starts at the top of the screen
        window.scrollTo(0, 0)

    }, [])

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const handleWindowSizeChange = () => {
        setWidth(document.body.clientWidth)
    }

    const fetchMentor = async (id) => {

        // This will get the data from the backend	
        const response = await getMentor(id);

        // If the response was not successfuly, show an error
        if (!response.success) {
            console.log("Error in fetching mentor")
        }
        else {
            // Once we have the data, put it in state
            setMentorProfile(response.data)
            setIsLoading(false)
        }

        const mentorImageRef = ref(storage, response.data.image)
        getDownloadURL(mentorImageRef).then((img) => {
            setImage(img)
        })
    }

    const returnHighSchoolSize = (size) => {
        if (size === "50") { return "1-50" }
        else if (size === "100") { return "50-100 students" }
        else if (size === "200") { return "100-200 students" }
        else if (size === "300") { return "200-300 students" }
        else if (size === "400") { return "300-400 students" }
        else if (size === "500") { return "400-500 students" }
        else if (size === "1000") { return "500-1000 students" }
        else if (size === "2000") { return "1000-2000 students" }
        else if (size === "3000") { return "2000+ students" }
    }

    const handleSubmitMeetingRequest = () => {
        setIsSubmitMeetingRequestClicked(true)
        const mentorId = location.pathname.split("/")[2]
        confirmMeetingRequest(user, mentorId, reviewRequestData)
    }

    return (
        isLoading ? <Loading />
            :
            <>
                <Navbar />
                {isReviewRequestShowing ?
                    <div className="review-request-modal">
                        <div className="review-request-header">
                            <p className="review-request-header-text">Confirm your meeting with {mentorProfile.name.split(" ")[0]}</p>
                        </div>
                        <div className="meeting-request-details">
                            <p className="meeting-request-header">Meeting Details</p>
                            <p className="meeting-details-text"><span className="meeting-request-subheader">With:</span> {mentorProfile.name}</p>
                            <p className="meeting-details-text"><span className="meeting-request-subheader">Where:</span> Zoom (The Zoom meeting link will be sent via email)</p>
                            <p className="meeting-details-text"><span className="meeting-request-subheader">Duration:</span> 30 minutes</p>
                            <p className="meeting-request-header">Proposed Meeting Times</p>
                            <p className="meeting-details-text"><span className="meeting-request-subheader">Meeting 1:</span> {reviewRequestData[0].day} @ {reviewRequestData[0].time} EST</p>
                            <p className="meeting-details-text"><span className="meeting-request-subheader">Meeting 2:</span> {reviewRequestData[1].day} @ {reviewRequestData[1].time} EST</p>
                            <p className="meeting-details-text"><span className="meeting-request-subheader">Meeting 3:</span> {reviewRequestData[2].day} @ {reviewRequestData[2].time} EST</p>
                            <p className="meeting-request-header">Next Steps</p>
                            <p className="meeting-details-text"><span className="meeting-request-subheader">1.</span> Submit your meeting request by clicking the button below.</p>
                            <p className="meeting-details-text"><span className="meeting-request-subheader">2.</span> {mentorProfile.name} will receive your meeting request and accept <b>one of your three</b> proposed meeting times.</p>
                            <p className="meeting-details-text"><span className="meeting-request-subheader">3.</span> Once accepted, you will receive an email with the details to join your meeting!</p>
                            <p className="meeting-request-header">FAQ</p>
                            <p className="faq-questions" onClick={() => faqIndex === 0 ? setFaqIndex(null) : setFaqIndex(0)}>I said I was available for three different times. Does that mean I will have three meetings?</p>
                            {faqIndex === 0 ? <p className="faq-answer">No, only 1 of your 3 proposed meeting times will be chosen for your actual meeting.</p> : null}
                            <p className="faq-questions" onClick={() => faqIndex === 1 ? setFaqIndex(null) : setFaqIndex(1)}>Why did I need to propose three meeting times if only one of them is being chosen?</p>
                            {faqIndex === 1 ? <p className="faq-answer">Our mentors are full-time students with busy schedules. Having 3 potential meeting times increases the likelihood that they are available at one of your preferred times.</p> : null}
                            <p className="faq-questions" onClick={() => faqIndex === 2 ? setFaqIndex(null) : setFaqIndex(2)}>Is this meeting free?</p>
                            {faqIndex === 2 ? <p className="faq-answer">Yes. This meeting is free and solely for your benefit.</p> : null}
                            <p className="faq-questions" onClick={() => faqIndex === 3 ? setFaqIndex(null) : setFaqIndex(3)}>When will I find out when my meeting request was accepted?</p>
                            {faqIndex === 3 ? <p className="faq-answer">Once the mentor accepts your meeting request, you will be sent an email notifying you that your request has been accepted. The email will also provide information for joining your meeting.</p> : null}
                            <p className="faq-questions" onClick={() => faqIndex === 4 ? setFaqIndex(null) : setFaqIndex(4)}>How will I join the Zoom meeting?</p>
                            {faqIndex === 4 ? <p className="faq-answer">The email that you receive when your meeting is accepted will include a Zoom link to join your meeting.</p> : null}
                            <p className="faq-questions" onClick={() => faqIndex === 5 ? setFaqIndex(null) : setFaqIndex(5)}>Am I able to cancel or reschedule the meeting?</p>
                            {faqIndex === 5 ? <p className="faq-answer">Yes, after you submit your meeting request there will be an option to cancel or reschedule your meeting.</p> : null}
                        </div>
                        <div className="review-request-footer">
                            <div className="meeting-request-flex">
                                <button onClick={handleSubmitMeetingRequest} className="submit-meeting-request-button1">{isSubmitMeetingRequestClicked ? "Submitting request..." : "Submit meeting request"}</button>
                                <button onClick={() => setIsReviewRequestShowing(false)} className="cancel-meeting-request-button">Cancel</button>
                            </div>
                        </div>
                    </div> : null}
                <div className={isReviewRequestShowing ? "mentor-bio-page blur-background" : "mentor-bio-page"}>
                    <div>
                        {mentorProfile ?
                            <div className="mentor-profile-grid">
                                <div className="mentor-profile-image-container">
                                    {image ? <img
                                        alt="mentor"
                                        src={image}
                                        className="mentor-profile-image"
                                    /> : <div className="mentor-profile-image"></div>}
                                    <p className="mentor-profile-name">{mentorProfile.name}</p>
                                    <p className="mentor-profile-details-major">{mentorProfile.mentorBio.majors[0]} in the School of {mentorProfile.mentorBio.schools[0]}</p>
                                    <p className="mentor-profile-details-year-state">{mentorProfile.mentorBio.year} from
                                        {mentorProfile.mentorBio.city ? ` ${mentorProfile.mentorBio.city},` : null} {mentorProfile.mentorBio.state}
                                    </p>
                                    <div className="mentor-profile-buttons-flex">
                                        <div className="mentor-profile-button-message-icon-flex">
                                            <FontAwesomeIcon icon={faMessages} className="messages-icon icon" />
                                            <button className="mentor-profile-message-button">Message</button>
                                        </div>
                                        <div className="mentor-profile-button-bookmark-icon-flex">
                                            <FontAwesomeIcon icon={faBookmark} className="bookmark-icon icon" />
                                            <button className="mentor-profile-bookmark-button">Bookmark</button>
                                        </div>
                                    </div>
                                    <div className="coming-soon-flex">
                                        <i>Coming Soon!</i>
                                        <i>Coming Soon!</i>
                                    </div>
                                    <div className="mentor-rating-flex">
                                        <p className="mentor-rating">_ /5</p>
                                        <FontAwesomeIcon icon={faStarSharp} className="star-icon icon" />
                                        <FontAwesomeIcon icon={faStarSharp} className="star-icon icon" />
                                        <FontAwesomeIcon icon={faStarSharp} className="star-icon icon" />
                                        <FontAwesomeIcon icon={faStarSharp} className="star-icon icon" />
                                        <FontAwesomeIcon icon={faStarSharp} className="star-icon icon" />
                                        <p className="number-of-ratings">(0)</p>
                                    </div>
                                    <p className="testimonial">- "Ratings and testimonials coming soon!" </p>
                                </div>
                                <Calendar calendarData={mentorProfile.calendarData} mentorEmail={mentorProfile.email}
                                    setIsReviewRequestShowing={setIsReviewRequestShowing} setReviewRequestData={setReviewRequestData} />
                            </div>
                            : null}
                    </div>
                    <div className="cards">
                        <div className="about-card">
                            <p className="about-card-header">About</p>
                            {
                                mentorProfile?.mentorBio?.introduction ?
                                    <p className="about-card-text">{mentorProfile.mentorBio.introduction}</p>
                                    :
                                    <p className="about-card-text">Hi, I'm {mentorProfile.name.split(" ")[0]}! I'm a {mentorProfile.mentorBio.year} majoring in {mentorProfile.mentorBio.majors[0]}
                                        {" "}    {mentorProfile.mentorBio.minors[0] === "None" ? null : `and minoring in ${mentorProfile.mentorBio.minors[0]}`} in the School of {mentorProfile.mentorBio.schools[0]}. Looking forward to speaking with you!</p>
                            }
                        </div>
                    </div>
                    <div className="cards">
                        <div className="college-profile-card">
                            <p className="about-card-header">Student Profile</p>
                            <div className="student-profile-grid-container">
                                <div>
                                    <div className="profile-flex-container">
                                        <FontAwesomeIcon icon={faBuildingColumns} className="school-icon icon" />
                                        <p className="profile-header">{mentorProfile.mentorBio.schools.length > 1 ? "Schools" : "School"}</p>
                                    </div>
                                    {mentorProfile.mentorBio.schools.map(school => {
                                        return <p className="profile-text-school">{school} </p>
                                    })}
                                </div>
                                <div>
                                    <div className="profile-flex-container">
                                        <FontAwesomeIcon icon={faGraduationCap} className="major-icon icon" />
                                        <p className="profile-header">{mentorProfile.mentorBio.majors.length > 1 ? "Majors" : "Major"}</p>
                                    </div>
                                    {mentorProfile.mentorBio.majors.map(major => {
                                        return <p className="profile-text-major">{major} </p>
                                    })}
                                </div>
                                <div>
                                    <div className="profile-flex-container">
                                        <FontAwesomeIcon icon={faDiploma} className="minor-icon icon" />
                                        <p className="profile-header pf-minor">{mentorProfile.mentorBio.minors.length > 1 ? "Minors" : "Minor"}</p>
                                    </div>
                                    {mentorProfile.mentorBio.minors.map(minor => {
                                        return <p className="profile-text-minor">{minor} </p>
                                    })}
                                </div>
                                <div>
                                    <div className="profile-flex-container">
                                        <FontAwesomeIcon icon={faHouseBuilding} className="school-icon icon profile-2-a" />
                                        <p className="profile-header profile-2-a">Freshman Dorm</p>
                                    </div>
                                    <p className="profile-text-school">{mentorProfile.mentorBio.dorm} </p>
                                </div>
                                <div>
                                    <div className="profile-flex-container">
                                        <FontAwesomeIcon icon={faSchool} className="high-school-icon icon  profile-2-b" />
                                        <p className="profile-header profile-2-b">High School Class Size</p>
                                    </div>
                                    <p className="profile-text-major">{returnHighSchoolSize(mentorProfile.mentorBio.highSchoolSize)} </p>
                                </div>
                                <div>
                                    <div className="profile-flex-container">
                                        <FontAwesomeIcon icon={faUserGraduate} className="graduate-icon icon  profile-2-b" />
                                        <p className="profile-header profile-2-b">Expected Graduation</p>
                                    </div>
                                    <p className="profile-text-minor">{mentorProfile.mentorBio.graduationYear}</p>
                                </div>
                            </div>
                            <hr className="profile-line-separator" />
                            {width < 1300 ? <hr className="profile-line-separator-2" /> : null}
                            {width < 800 ? <hr className="profile-line-separator-3" /> : null}
                            {width < 800 ? <hr className="profile-line-separator-4" /> : null}
                            {width < 800 ? <hr className="profile-line-separator-5" /> : null}


                        </div>
                        <div className="about-card college-experience">
                            <p className="about-card-header">College Experience</p>
                            {mentorProfile.mentorBio.clubs.map((club, index) => {
                                return (
                                    <>
                                        <p className="club-headers">{club.club}</p>
                                        <p className="club-title">{club.title}</p>
                                        {club.startMonth && club.startYear ? <p className="club-date">{club.startMonth} {club.startYear} - {club.endMonth && club.endYear ? `${club.endMonth} ${club.endYear}` : "Present"}</p> : null}
                                        <p className="club-description">{club.description}</p>
                                        {index !== mentorProfile.mentorBio.clubs.length - 1 ? <hr className="clubs-line-separator"></hr> : null}
                                    </>
                                )
                            })}
                        </div>
                    </div>
                    <div className="statistics-container">
                        {
                            mentorProfile.mentorBio.askMeQuestions.map((question, index) => {
                                if (question.text) {
                                    return <div className={"one-statistic border-" + colors[index]}>
                                        <p className={"stat-label-workload " + colors[index]}>Ask Me About...</p>
                                        <FontAwesomeIcon className={"backpack-icon " + colors[index]} icon={faBlockQuestion} />
                                        <p className={"stat-explanation " + colors[index]}>
                                            {question.text}
                                        </p>
                                    </div>
                                }
                                else {
                                    return null
                                }
                            })
                        }
                    </div>
                </div>
                <Footer />
            </>
    )
}

export default MentorBio;
