
const filterMentorsBySchool = (filter, schools) => {
    if (!filter) { return true }
    let filterMatch = false
    schools.forEach(school => {
        if (filter === "eng") {
            if (school.includes("Engineering")) {
                filterMatch = true
            }
        }
        else if (filter === "lsa") {
            if (school.includes("LSA")) {
                filterMatch = true
            }
        }
        else if (filter === "arch") {
            if (school.includes("Architecture")) {
                filterMatch = true
            }
        }
        else if (filter === "kin") {
            if (school.includes("Kinesiology")) {
                filterMatch = true
            }
        }
        else if (filter === "bus") {
            if (school.includes("Business")) {
                filterMatch = true
            }
        }
        else if (filter === "health") {
            if (school.includes("Public Health")) {
                filterMatch = true
            }
        }
        else if (filter === "nurse") {
            if (school.includes("Nursing")) {
                filterMatch = true
            }
        }
        else if (filter === "edu") {
            if (school.includes("Education")) {
                filterMatch = true
            }
        }
    })
    return filterMatch
}

export default filterMentorsBySchool