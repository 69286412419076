import React, { useContext, useState, useEffect, useRef } from 'react'
import "./index.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faPaperPlaneTop, faCaretDown, faBarsSort, faXmark
} from "@fortawesome/pro-solid-svg-icons";
import { useLocation } from "react-router-dom"
import { ContextProvider } from '../../context';
import { addForumMessageUser } from '../../API/api';
import { addForumMessageMentor } from '../../API/api';
import { getEST } from '../../API/api';
import { db } from "../../firestore"
import { getMessageReplies } from '../../API/api';
import ForumComment from '../ForumComment';
import signUpWithGoogle from '../../utils/signUp';


const Forum = () => {

    const { user, setUser } = useContext(ContextProvider)

    const location = useLocation();

    const isMentorRoute = location.pathname.split("/")[1];

    const [userText, setUserText] = useState("")

    const [forumText, setForumText] = useState([])

    const [forumTextSnapshot, setForumTextSnapshot] = useState([])

    const [isUserReplying, setIsUserReplying] = useState({ isReplying: false, isReplyingName: "", replyingToId: "" })

    const [areRepliesShown, setAreRepliesShown] = useState(false)

    const [isPostNumberExceeded, setIsPostNumberExceeded] = useState(false)

    const [width, setWidth] = useState(document.body.clientWidth);


    const query = db.collection("forumMessages")

    const postNumberExceededRef = useRef()

    const inputRef = useRef()

    useEffect(() => {
        getSnapshot()
    }, [])

    const getSnapshot = () => {
        query.onSnapshot((querySnapshot) => {
            const forumTextTemp = []
            querySnapshot.forEach(doc => {
                forumTextTemp.push(doc.data())
            })
            setForumText([...forumTextTemp])
            setForumTextSnapshot([...forumTextTemp])
        })
    }

    useEffect(() => {
        let closeCannotPostModalRef = (event) => {
            if (!postNumberExceededRef?.current.contains(event.target)) {
                setIsPostNumberExceeded(false)
            }
        }

        document.addEventListener("mousedown", closeCannotPostModalRef);
        return () => {
            document.removeEventListener("mousedown", closeCannotPostModalRef);
        }
    }, [])


    useEffect(() => {
        if (isUserReplying.isReplying) {
            inputRef?.current?.scrollIntoView(false)
        }
    }, [isUserReplying])


    useEffect(() => {
        if (isUserReplying.isReplying) {
            fetchMessageReplies(isUserReplying.replyingToId)
        }
    }, [forumTextSnapshot])

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const handleWindowSizeChange = () => {
        setWidth(document.body.clientWidth)
    }

    const fetchMessageReplies = async (messageId) => {
        const result = await getMessageReplies(messageId)

        if (result.success) {
            const { data } = result
            // Loop through all the messages and add the replies to the message
            forumText.forEach(messageObject => {
                if (messageObject.id === data[0].replyId) {
                    messageObject.replies = data
                    messageObject.showReplies = true
                    setAreRepliesShown(true)
                    setForumText([...forumText])
                }
            })
        }

    }

    const handleSubmitQuestion = async (e) => {
        if (!userText && e.keyCode === 13) {
            e.preventDefault()
            return
        }
        if (e.keyCode === 13 && e.shiftKey === false) {
            e.preventDefault()
            setUserText("")
            await handleSubmitForumMessage()
        }
        else if (e.keyCode === 13 && e.shiftKey) {
            setUserText(userText.concat("\n"))
        }
    }

    const handleSubmitForumMessage = async () => {

        // Check if the user is allowed to post
        if (user.isUserAuthenticated && user?.numberOfForumPosts >= 2 && !isUserReplying.isReplying) {
            setIsPostNumberExceeded(true)
            return
        }
        // Check if the user is authenticated
        if (isUserReplying.isReplying) {
            localStorage.setItem('reply', isUserReplying.replyingToId);
        }

        // Get the current time
        const result = await getEST()
        // Create the message object
        const messageObject = {
            message: userText,
            date: result.data.datetime,
            isReply: isUserReplying.isReplying,
            replyId: isUserReplying.replyingToId
        }

        if (!user.isUserAuthenticated) {
            localStorage.setItem('signUpType', 'userForumPost');
            localStorage.setItem('messageObject', JSON.stringify(messageObject));
            signUpWithGoogle(user, "user")
        }
        else {
            if (isMentorRoute) {
                await addForumMessageMentor(messageObject, (result) => {
                })
            }
            else {
                await addForumMessageUser(messageObject, (result) => {
                    user.numberOfForumPosts = result.data.numberOfForumPosts
                    setUser({ ...user })
                })
            }
        }
    }

    return (
        <>
            <div className={isPostNumberExceeded ? "blur-background forum-grid" : 'forum-grid'}>
                <div className='forum-sidebar'>
                    <div className='sidebar-grid'>
                        <div className='sidebar-grid-1'>
                            <p className='sidebar-header'>W.I.L.T. Forums</p>
                            <div className='forum-name-flex forum-first'>
                                <p className='active-circle'></p>
                                <p className='sidebar-text'>
                                    {user?.isUserAuthenticated ? user.name : "User"}
                                </p>
                            </div>
                        </div>
                        <div className='sidebar-grid-2'>
                            <div className='forum-name-flex forum-second'>
                                <FontAwesomeIcon className="channels-icon" icon={faCaretDown}></FontAwesomeIcon>
                                <p className='channels-header'>Channels</p>
                                <p></p>
                                <p className='channel-name'># General</p>
                            </div>
                        </div>
                        <div className='sidebar-grid-3'>
                            <div className='forum-name-flex forum-second'>
                                <FontAwesomeIcon className="channels-icon" icon={faBarsSort}></FontAwesomeIcon>
                                <p className='channels-header'>Threads</p>
                                <p></p>
                                <p className='channel-name'># Main</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='forum-text-input-grid'>
                    <div className={areRepliesShown ? "forum-text-area-replies" : 'forum-text-area'}>
                        <div className={areRepliesShown && width < 800 ? 'display-none' : 'scroll-bottom'}>
                            {forumText.map((textObject) => {
                                return (
                                    <div key={textObject.date}>
                                        <div className='forum-text-flex'>
                                            <ForumComment textObject={textObject}
                                                user={user} setIsUserReplying={setIsUserReplying}
                                                fetchMessageReplies={fetchMessageReplies} setForumText={setForumText}
                                                forumText={forumText} setAreRepliesShown={setAreRepliesShown}
                                                isUserReplying={isUserReplying}
                                            />
                                        </div>
                                    </div>
                                )
                            }).sort((a, b) => new Date(b.key) - new Date(a.key))}
                        </div>
                        {areRepliesShown ?
                            <div className='replies-container'>
                                <div className='close-replies'>
                                    <p className='reply-button'
                                        onClick={() => {
                                            forumText.forEach(messageObject => {
                                                messageObject.showReplies = false
                                                setAreRepliesShown(false)
                                                setIsUserReplying({ isReplying: false })
                                            })
                                            setForumText([...forumText])
                                        }}>
                                        Close replies
                                    </p>

                                </div>
                                <div className='forum-replies-text'>
                                    {forumText.map((textObject, index) => {
                                        return (
                                            <>
                                                {
                                                    textObject.showReplies ?
                                                        textObject.replies.map(reply => {
                                                            return (
                                                                <div key={reply.date} className='forum-text-flex'>
                                                                    <ForumComment textObject={reply}
                                                                        user={user} setIsUserReplying={setIsUserReplying}
                                                                        fetchMessageReplies={fetchMessageReplies} setForumText={setForumText}
                                                                        forumText={forumText} setAreRepliesShown={setAreRepliesShown}
                                                                        isUserReplying={isUserReplying}
                                                                    />
                                                                </div>
                                                            )
                                                        }).sort((a, b) => new Date(a.key) - new Date(b.key))
                                                        : null
                                                }
                                            </>
                                        )
                                    })}
                                </div>
                            </div> : null}
                    </div>
                    <div className='text-area-container'>
                        <textarea
                            ref={inputRef}
                            value={userText}
                            onChange={(e) => {
                                if (width < 500 && !user.isUserAuthenticated) { return }
                                if (isMentorRoute && !isUserReplying.isReplying) { return }
                                setUserText(e.target.value)
                            }
                            }
                            className={isUserReplying.isReplying ? "forum-input reply-input" : 'forum-input'}
                            placeholder={isMentorRoute ? "Click 'Reply' under one of the student's questions to begin typing!" :
                                width < 500 && !user.isUserAuthenticated ? "Login or sign up to ask a question" :
                                    isUserReplying.isReplying ? `Reply to ${isUserReplying.isReplyingName}` :
                                        'Ask a question that will be answered by a Michigan Student!'}
                            onKeyDown={async (e) => await handleSubmitQuestion(e)}>
                        </textarea>
                        <div className={isUserReplying.isReplying ? 'forum-input-bottom reply-input-bottom' : 'forum-input-bottom'}>
                            <div className='text-tools-flex'>
                                <FontAwesomeIcon className="paper-plane-icon" icon={faPaperPlaneTop} onClick={async () => {
                                    if (!userText) {
                                        return
                                    }
                                    // Also check if the user is authenticated
                                    setUserText("")
                                    await handleSubmitForumMessage()
                                }}></FontAwesomeIcon>
                                {width < 500 && !user.isUserAuthenticated ?
                                    <p onClick={() => {
                                        localStorage.setItem('signUpType', 'userForumPostOnMobile');
                                        localStorage.setItem('tab', 'forum');
                                        signUpWithGoogle(user, "user")
                                    }} className='forum-login-text'>Login or sign up</p>
                                    : null}
                                {isUserReplying.isReplying && (width > 500 || user.isUserAuthenticated) ?
                                    <p className='replying-text'>Replying <span className='replying-to-name'>to {isUserReplying.isReplyingName} </span></p>
                                    : <p className='newline'>Press Shift & Enter for new line</p>}
                                {isUserReplying.isReplying && (width > 500 || (user.isUserAuthenticated && width < 500)) ?
                                    <div className='cancel-flex' onClick={() => {
                                        if (areRepliesShown) {
                                            // Set all the replies to false first
                                            forumText.forEach(messageObject => {
                                                messageObject.showReplies = false
                                            })
                                            setForumText([...forumText])
                                            setAreRepliesShown(false)
                                            setIsUserReplying({ isReplying: false })
                                        }
                                        else {
                                            setIsUserReplying({ isReplying: false })
                                        }
                                    }}>
                                        <p className='cancel-reply'>{areRepliesShown ? "Close Replies" : "Cancel Reply"}</p>
                                        <FontAwesomeIcon className="cancel-reply-icon" icon={faXmark}></FontAwesomeIcon>
                                    </div>
                                    : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                isPostNumberExceeded ?
                    <div ref={postNumberExceededRef} className='cannot-post-modal'>
                        <FontAwesomeIcon icon={faXmark} className="icon-x-mark" onClick={() => setIsPostNumberExceeded(false)} />
                        <p className="cannot-join-meeting-header-text-2">
                            You have already made two posts. Book a meeting with a mentor to make more posts!
                        </p>
                        <p className='refresh-warning'>(You may still respond to other users' posts.)</p>
                        <p onClick={() => window.location.href = "/?view=mentors"} className="cannot-join-meeting-sub-text">
                            <span className='blue'> Click here to book a meeting! </span>
                        </p>
                    </div>
                    : null
            }
        </>
    )
}

export default Forum