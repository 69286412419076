import React, { useState, useEffect, useContext, useRef } from "react"
import firebase from 'firebase/compat/app';
import { ContextProvider } from '../../context';
import { Link, useLocation } from "react-router-dom"
import signUpWithGoogle from "../../utils/signUp";
import "./index.css"
const Navbar = () => {

    const { user, setUser, setIsLoading } = useContext(ContextProvider)

    const location = useLocation()

    const route = location.pathname.split("/")[1]

    const secondRoute = location.pathname.split("/")[2]

    const [isHamburgerMenuShown, setIsHamburgerMenuShown] = useState(document.body.clientWidth < 1072)

    const [isHamburgerMenuActive, setIsHamburgerMenuActive] = useState(false)

    const [isAccountCircleClicked, setIsAccountCircleClicked] = useState(false);

    useEffect(() => {
        // Every time the screen is resized, check if we should show the hamburger menu
        window.addEventListener('resize', () => {
            setIsHamburgerMenuShown(document.body.clientWidth < 1072)
        });
    }, [])

    return (
        <div className={!isHamburgerMenuActive ? "my-navbar" : isHamburgerMenuShown ? "my-navbar-long" : "my-navbar"}>
            {
                // When the hamburger menu isn't being shown, display about is and add my school text
                !isHamburgerMenuShown ?
                    <>
                        <div className={user?.isUserAuthenticated ? "navbar-container-authenticated" : "navbar-container"}>
                            <div className="navbar-container-left">
                                <Link style={{ textDecoration: "none" }} to={route === "mentor" ? "/mentor" : "/"}> <p className="wilt-navbar"> W.I.L.T.</p></Link>
                                {route === "mentor" ?
                                    <>
                                        <Link style={{ textDecoration: "none" }} to="/mentor/calendar"><p className="product-navbar">My Calendar</p></Link>
                                        <Link style={{ textDecoration: "none" }} to="/mentor/meetings"><p className="enterprise-navbar">My Meetings</p></Link>
                                        <Link style={{ textDecoration: "none" }} to={`/mentor/profile/${user.id}`}> <p className="pricing-navbar">My Profile</p></Link>
                                    </>
                                    :
                                    <>
                                        <Link style={{ textDecoration: "none" }} to="/?view=mentors"><p className="product-navbar">Book Meeting</p></Link>
                                        <Link style={{ textDecoration: "none" }} to="/user/meetings"><p className="enterprise-navbar">My Meetings</p></Link>
                                        <Link style={{ textDecoration: "none" }} to="/user/profile"> <p className="pricing-navbar">My Profile</p></Link>
                                        <Link style={{ textDecoration: "none" }} to="/about-us"><p className="about-us-navbar">About Us</p></Link>
                                    </>
                                }
                            </div>
                            <div className={user?.isUserAuthenticated ? "navbar-container-right-authenticated" : "navbar-container-right"}>
                                {user?.isUserAuthenticated ?
                                    <>
                                        <div>
                                            {route === "mentor" ?
                                                <Link to="/"><button
                                                    className="become-a-mentor-navbar">
                                                    {route === "mentor" ? "Switch to User" : null}
                                                </button>
                                                </Link>
                                                :
                                                <button onClick={() => { signUpWithGoogle(user, "mentor"); localStorage.setItem('signUpType', 'mentor'); setIsLoading(true) }}
                                                    className="become-a-mentor-navbar">
                                                    {user?.isAlsoMentor || user?.mentorBio ? "Switch to Mentor" : "Become a Mentor"}
                                                </button>}
                                            {isAccountCircleClicked ? <AccountDropdown setIsAccountCircleClicked={setIsAccountCircleClicked} user={user} setUser={setUser} route={route} /> : null}
                                        </div>
                                        <div onClick={() => setIsAccountCircleClicked(true)} className="circle">
                                            <p className="initials-text">{user.name.split(" ")[0][0].toUpperCase()}{user.name.split(" ")[1][0].toUpperCase()}</p>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <button onClick={() => { signUpWithGoogle(user, "mentor"); localStorage.setItem('signUpType', 'mentor'); setIsLoading(true) }} className="sign-up-navbar">Become a Mentor</button>
                                        <button onClick={() => { signUpWithGoogle(user, "user"); localStorage.setItem('signUpType', 'user'); setIsLoading(true) }} className="talk-to-sales-navbar">Login or Sign Up</button>
                                    </>
                                }
                            </div>
                        </div>
                    </>
                    :
                    // Check if the hamburger menu was clicked on
                    // If it is, then show the white screen drop down with about us and add my school as option
                    isHamburgerMenuActive ?
                        <>
                            <Link style={{ textDecoration: "none" }} to={route === "mentor" ? "/mentor" : "/"}> <p className="wilt-navbar-hamburger-active"> W.I.L.T.</p></Link>
                            {user?.isUserAuthenticated ?
                                route === "mentor" ?
                                    <>
                                        <Link onClick={() => !route || (route === "mentor" && !secondRoute) ? setIsHamburgerMenuActive(false) : null} style={{ textDecoration: "none" }} to={route === "mentor" ? "/mentor" : "/"}><p className="home navbar-hamburger">Home</p></Link>
                                        <Link onClick={() => setIsHamburgerMenuActive(false)} to="/mentor/calendar"><p className="book navbar-hamburger">My Calendar</p></Link>
                                        <Link to={route === "mentor" ? "/mentor/meetings" : "/user/profile"}><p className="product navbar-hamburger">My Meetings</p></Link>
                                        <Link to={route === "mentor" ? `/mentor/profile/${user.id}` : "/user/meetings"}><p className="enterprise navbar-hamburger">My Profile</p></Link>
                                        {route === "mentor" ? <Link to="/"><p className="pricing navbar-hamburger">Switch to User</p></Link>
                                            : <p onClick={() => { signUpWithGoogle(user, "mentor"); localStorage.setItem('signUpType', 'mentor'); setIsLoading(true) }} className="pricing navbar-hamburger"> {user?.isAlsoMentor || user?.mentorBio ? "Switch to Mentor" : "Become a Mentor"}</p>}
                                        <p onClick={() => {
                                            firebase.auth().signOut().then(() => {
                                                setUser({ isUserAuthenticated: false })
                                                window.location.href = "/"
                                            })
                                        }} className="about-us navbar-hamburger">Logout</p>
                                    </>
                                    :
                                    <>
                                        <Link onClick={() => !route || (route === "mentor" && !secondRoute) ? setIsHamburgerMenuActive(false) : null} style={{ textDecoration: "none" }} to={route === "mentor" ? "/mentor" : "/"}><p className="home navbar-hamburger">Home</p></Link>
                                        <Link onClick={() => setIsHamburgerMenuActive(false)} to="/?view=mentors"><p className="book navbar-hamburger">Book a Meeting</p></Link>
                                        <Link to={route === "mentor" ? `/mentor/profile/${user.id}` : "/user/profile"}><p className="product navbar-hamburger">My Profile</p></Link>
                                        <Link to={route === "mentor" ? "/mentor/meetings" : "/user/meetings"}><p className="enterprise navbar-hamburger">My Meetings</p></Link>
                                        {route === "mentor" ? <Link to="/"><p className="pricing navbar-hamburger">Switch to User</p></Link>
                                            : <p onClick={() => { signUpWithGoogle(user, "mentor"); localStorage.setItem('signUpType', 'mentor'); setIsLoading(true) }} className="pricing navbar-hamburger"> {user?.isAlsoMentor || user?.mentorBio ? "Switch to Mentor" : "Become a Mentor"}</p>}
                                        <Link to="/about-us"><p className="about-us navbar-hamburger">About Us</p></Link>
                                        <p onClick={() => {
                                            firebase.auth().signOut().then(() => {
                                                setUser({ isUserAuthenticated: false })
                                                window.location.href = "/"
                                            })
                                        }} className="sign-up navbar-hamburger">Logout</p>
                                    </>
                                :
                                <>
                                    <Link onClick={() => !route || (route === "mentor" && !secondRoute) ? setIsHamburgerMenuActive(false) : null} style={{ textDecoration: "none" }} to={route === "mentor" ? "/mentor" : "/"}><p className="home navbar-hamburger">Home</p></Link>
                                    <p onClick={() => { signUpWithGoogle(user, "user"); localStorage.setItem('signUpType', 'user'); setIsLoading(true) }} className="book navbar-hamburger">Login or Sign Up</p>
                                    <p onClick={() => { signUpWithGoogle(user, "mentor"); localStorage.setItem('signUpType', 'mentor'); setIsLoading(true) }} className="product navbar-hamburger">Become a Mentor</p>
                                    <Link to="/about-us"><p className="enterprise navbar-hamburger">About Us</p></Link>
                                </>
                            }

                        </>
                        :
                        // Otherwise show nothing
                        null
            }
            {/* Controls the different styles of the hamburger button */}
            {
                isHamburgerMenuShown ?
                    <>
                        <div className="wilt-navbar-hamburger-container">
                            <Link style={{ textDecoration: "none" }} to={route === "mentor" ? "/mentor" : "/"}> <p className="wilt-navbar-hamburger"> W.I.L.T.</p></Link>
                        </div>
                        <Link to={location.pathname.split("?")[0]}><button
                            onClick={() => setIsHamburgerMenuActive(!isHamburgerMenuActive)}
                            className={isHamburgerMenuActive ?
                                "hamburger hamburger--spin is-active"
                                : "hamburger hamburger--spin"}
                            type="button"
                        >
                            <span className="hamburger-box">
                                <span className="hamburger-inner"></span>
                            </span>
                        </button>
                        </Link>
                    </>
                    :
                    null
            }
        </div >
    )
}

export default Navbar


function AccountDropdown({ setIsAccountCircleClicked, user, setUser, route }) {

    let accountDropdownRef = useRef();

    useEffect(() => {
        let closeDropdownHandler = (event) => {
            if (!accountDropdownRef.current.contains(event.target)) {
                setIsAccountCircleClicked(false);
            }
        }
        document.addEventListener("mousedown", closeDropdownHandler);
        return () => {
            document.removeEventListener("mousedown", closeDropdownHandler);
        }
    })

    const closeModal = () => {
        setIsAccountCircleClicked(false);
    }


    return (
        <div ref={accountDropdownRef} className={route === "mentor" ? "modal-style-mentor" : "modal-style-user"}>
            <button onClick={closeModal} className="close-modal">&times;</button>
            <div className="account-icon-in-modal">
                <p className="initials">{user.name.split(" ")[0][0]}{user.name.split(" ")[1][0]}</p>
            </div>
            <p className="username">{user.name}</p>
            <p className="email">{user.email}</p>
            <button onClick={() => route === "mentor" ? window.location.href = `/mentor/profile/${user.id}` : window.location.href = "/user/profile"} className="manage-account-button">Manage my account</button>
            <br></br>
            <hr></hr>
            <button onClick={() => {
                firebase.auth().signOut().then(() => {
                    setIsAccountCircleClicked(false)
                    setUser({ isUserAuthenticated: false })
                    window.location.href = "/"
                })
            }} className="sign-out-button">Sign-out</button>
        </div >
    )
}