import firebase from 'firebase/compat/app';
const { API_ROOT_URL } = require("../../../configs/activebuildconfig.json")



/**
 * CONTROLLER - user
 * FUNCTION - createMeeting
 * VERIFY - User who is authenticated
 * @param {*} meetingDate The date that the user selected for the meeting
 * @param {*} mentorId The id of the mentor with whom the user wants to meet
 * @param {*} callback Callback function to run after the result is returned
 * @returns Return nothing
 */

export const createMeeting = async (meetingDate, mentorId, callback) => {
    firebase.auth().onAuthStateChanged(async user => {
        const token = await user.getIdToken(false);
        const response = await fetch(`${API_ROOT_URL}/user/create_meeting`, {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-type": "application/json",
            },
            body: JSON.stringify({
                token,
                meetingDate,
                mentorId
            }),
        })
        const result = await response.json();
        callback(result)
    })
}

