import React from 'react'
import { faLaptopCode, faGear, faPaintBrushFine, faLandmarkDome, faUserTieHairLong, faHeadSideBrain, faUserDoctorHair, faFlask, faPlane, faUserNurseHairLong, faPersonChalkboard } from "@fortawesome/pro-thin-svg-icons";
import {
    faLaptopCode as faLaptopCodeSolid, faGear as faGearSolid, faPaintBrushFine as faPaintBrushFineSolid, faLandmarkDome as faLandmarkDomeSolid,
    faUserTieHairLong as faUserTieHairLongSolid, faHeadSide as faHeadSideBrainSolid, faUserDoctorHair as faUserDoctorHairSolid, faFlask as faFlaskSolid, faPlane as faPlaneSolid, faUserNurseHairLong as faUserNurseHairLongSolid,
    faPersonChalkboard as faPersonChalkboardSolid
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
const MajorFilter = ({ filterSelected, handleSelectFilter }) => {
    return (
        <>
            <div className={filterSelected === "cs" ? "filter-icon-selected" : "filter-icon"} onClick={() => handleSelectFilter("cs")}>
                <FontAwesomeIcon icon={filterSelected === "cs" ? faLaptopCodeSolid : faLaptopCode} className="icon" />
                <p className={filterSelected === "cs" ? "filter-icon-text-selected" : "filter-icon-text"}>CS</p>
            </div>
            <div className={filterSelected === "eng" ? "filter-icon-selected" : "filter-icon"} onClick={() => handleSelectFilter("eng")}>
                <FontAwesomeIcon icon={filterSelected === "eng" ? faGearSolid : faGear} className="engineering-icon icon" />
                <p className={filterSelected === "eng" ? "filter-icon-text-selected" : "filter-icon-text"}>Engineering</p>
            </div>
            <div className={filterSelected === "poli" ? "filter-icon-selected" : "filter-icon"} onClick={() => handleSelectFilter("poli")} >
                <FontAwesomeIcon icon={filterSelected === "poli" ? faLandmarkDomeSolid : faLandmarkDome} className="poli-sci-icon icon" />
                <p className={filterSelected === "poli" ? "filter-icon-text-selected" : "filter-icon-text"}>Poli Sci</p>
            </div>
            <div className={filterSelected === "bus" ? "filter-icon-selected" : "filter-icon"} onClick={() => handleSelectFilter("bus")}>
                <FontAwesomeIcon icon={filterSelected === "bus" ? faUserTieHairLongSolid : faUserTieHairLong} className="business-icon icon" />
                <p className={filterSelected === "bus" ? "filter-icon-text-selected" : "filter-icon-text"}>Business</p>
            </div>
            <div className={filterSelected === "psych" ? "filter-icon-selected" : "filter-icon"} onClick={() => handleSelectFilter("psych")}>
                <FontAwesomeIcon icon={filterSelected === "psych" ? faHeadSideBrainSolid : faHeadSideBrain} className="icon psych-icon" />
                <p className={filterSelected === "psych" ? "filter-icon-text-selected" : "filter-icon-text"}>Psych</p>
            </div>
            <div className={filterSelected === "med" ? "filter-icon-selected" : "filter-icon"} onClick={() => handleSelectFilter("med")}>
                <FontAwesomeIcon icon={filterSelected === "med" ? faUserDoctorHairSolid : faUserDoctorHair} className="pre-med-icon icon" />
                <p className={filterSelected === "med" ? "filter-icon-text-selected" : "filter-icon-text"}>Pre-Med</p>
            </div>
            <div className={filterSelected === "chem" ? "filter-icon-selected" : "filter-icon"} onClick={() => handleSelectFilter("chem")} >
                <FontAwesomeIcon icon={filterSelected === "chem" ? faFlaskSolid : faFlask} className="icon chem-icon" />
                <p className={filterSelected === "chem" ? "filter-icon-text-selected" : "filter-icon-text"}>Chem</p>
            </div>
            <div className={filterSelected === "aero" ? "filter-icon-selected" : "filter-icon"} onClick={() => handleSelectFilter("aero")} >
                <FontAwesomeIcon icon={filterSelected === "aero" ? faPlaneSolid : faPlane} className="aerospace-icon icon" />
                <p className={filterSelected === "aero" ? "filter-icon-text-selected" : "filter-icon-text"}>Aerospace</p>
            </div>
            <div className={filterSelected === "nurse" ? "filter-icon-selected" : "filter-icon"} onClick={() => handleSelectFilter("nurse")}>
                <FontAwesomeIcon icon={filterSelected === "nurse" ? faUserNurseHairLongSolid : faUserNurseHairLong} className="nursing-icon icon" />
                <p className={filterSelected === "nurse" ? "filter-icon-text-selected" : "filter-icon-text"}>Nursing</p>
            </div>
            <div className={filterSelected === "edu" ? "filter-icon-selected" : "filter-icon"} onClick={() => handleSelectFilter("edu")}>
                <FontAwesomeIcon icon={filterSelected === "edu" ? faPersonChalkboardSolid : faPersonChalkboard} className="education-icon icon" />
                <p className={filterSelected === "edu" ? "filter-icon-text-selected" : "filter-icon-text"}>Education</p>
            </div>
        </>
    )
}

export default MajorFilter