import React, { useState, useEffect, useRef } from 'react'
import Navbar from '../Navbar'
import Footer from '../Footer'
import { useLocation } from "react-router-dom"
import { getEST, getMeetingUser } from '../../API/api'
import Loading from '../Loading'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faXmark, faCheckCircle, faXmarkCircle
} from "@fortawesome/pro-solid-svg-icons";
import { acceptMeeting } from '../../API/api'
import { declineMeeting } from '../../API/api'
import "./index.css"


const AcceptMeeting = () => {

    const [meeting, setMeeting] = useState(null)

    const location = useLocation();

    const [isLoading, setIsLoading] = useState(true)

    const [isDeclineMeetingButtonClicked, setIsDeclineMeetingButtonClicked] = useState(false)

    const [isAcceptMeetingClicked, setIsAcceptMeetingClicked] = useState(false)

    const [isMeetingError, setIsMeetingError] = useState(false)

    const [isConfirmMeetingClicked, setIsConfirmMeetingClicked] = useState(false)

    const [isDeclineMeetingClicked, setIsDeclineMeetingClicked] = useState(false)

    const [selectedMeetingDate, setSelectedMeetingDate] = useState(null)

    const [currentDate, setCurrentDate] = useState(null)

    const declineMeetingRef = useRef()

    const acceptMeetingRef = useRef()



    useEffect(() => {
        // Get the mentor's id from the query string
        const meetingId = location.pathname.split("/")[3];

        // Call getMentor endpoint to get the mentor information
        fetchMeeting(meetingId)
        fetchEST()
    }, [])



    useEffect(() => {
        let declineMeetingModalRef = (event) => {
            if (!declineMeetingRef?.current.contains(event.target)) {
                setIsDeclineMeetingButtonClicked(false)
            }
        }

        let acceptMeetingModalRef = (event) => {
            if (!acceptMeetingRef?.current.contains(event.target)) {
                setIsAcceptMeetingClicked(false)
            }
        }

        document.addEventListener("mousedown", declineMeetingModalRef);
        document.addEventListener("mousedown", acceptMeetingModalRef);

        return () => {
            document.removeEventListener("mousedown", declineMeetingModalRef);
            document.removeEventListener("mousedown", acceptMeetingModalRef);
        }
    }, [])

    const fetchEST = async () => {
        const result = await getEST()
        setCurrentDate(result.data.datetime)
    }

    const fetchMeeting = async (meetingId) => {
        const result = await getMeetingUser(meetingId);
        if (result.success) {
            setMeeting(result.data)
            setIsLoading(false)
        }
        if (!result.success) {
            setIsMeetingError(true)
            setIsLoading(false)
        }
    }

    const isDayInThePast = (date) => {
        return new Date(date) < new Date(currentDate)
    }

    const formatDay = (utcDate) => {

        // Get the date using UTC from database
        const date = new Date(utcDate)

        const options = { timeZone: "America/New_York", weekday: 'long', month: 'long', day: 'numeric' };

        let formattedDate = date.toLocaleDateString("en-US", options)

        const number = formattedDate.split(" ")[2]

        if (number > 3 && number < 21) {
            formattedDate += "th";
            return formattedDate;
        }

        switch (number % 10) {
            case 1: formattedDate += "st";
                break;
            case 2: formattedDate += "nd";
                break;
            case 3: formattedDate += "rd";
                break;
            default: formattedDate += "th";
        }

        return formattedDate;
    }

    const formatTime = (utcDate) => {

        // Get the date using UTC from database
        const date = new Date(utcDate)

        const options = { timeZone: "America/New_York", hour: '2-digit', minute: '2-digit' };

        let formattedTime = date.toLocaleTimeString("en-US", options)

        const hours = formattedTime.split(":")[0]

        // Get rid of leading 0
        if (hours[0] === "0") {
            formattedTime = formattedTime.substring(1)
        }
        return formattedTime
    }

    const handleAcceptMeeting = async () => {
        setIsConfirmMeetingClicked(true)
        const result = await acceptMeeting(meeting.id, selectedMeetingDate, currentDate)
        if (result.success) {
            setMeeting({ ...result.data })
            setIsAcceptMeetingClicked(false)
        }

    }

    const handleDeclineMeeting = async () => {
        setIsDeclineMeetingClicked(true)
        const result = await declineMeeting(meeting.id)
        if (result.success) {
            setMeeting({ ...result.data.updatedMeeting })
            setIsDeclineMeetingButtonClicked(false)
        }
    }

    return isLoading ? <Loading /> : (
        <>
            <Navbar />
            {isDeclineMeetingButtonClicked ?
                <div ref={declineMeetingRef} className='cannot-join-meeting-yet'>
                    <FontAwesomeIcon icon={faXmark} className="icon-x-mark" onClick={() => setIsDeclineMeetingButtonClicked(false)} />
                    <p className="cannot-join-meeting-header-text">Are you sure you want to decline this meeting?</p>
                    <div className='cancel-grid'>
                        <button className={isDeclineMeetingClicked ? 'cancel-meeting-button accept disabled' : "cancel-meeting-button accept"}
                            onClick={() => isDeclineMeetingClicked ? null : setIsDeclineMeetingButtonClicked(false)}>
                            No, go back
                        </button>
                        <button className={isDeclineMeetingClicked ? 'cancel-meeting-button decline disabled' : "cancel-meeting-button decline"}
                            onClick={() => isDeclineMeetingClicked ? null : handleDeclineMeeting()}>
                            {isDeclineMeetingClicked ? "Declining..." : "Yes, decline"}
                        </button>
                    </div>
                </div>
                : null}
            {isAcceptMeetingClicked ?
                <div ref={acceptMeetingRef} className='cannot-join-meeting-yet'>
                    <FontAwesomeIcon icon={faXmark} className="icon-x-mark" onClick={() => setIsAcceptMeetingClicked(false)} />
                    <p className="cannot-join-meeting-header-text">Confirm your meeting for</p>
                    <div className='flex-center'>
                        <p className='meeting-date-confirmation-text'>{formatDay(selectedMeetingDate)} @ {formatTime(selectedMeetingDate)} - {formatTime(new Date(new Date(selectedMeetingDate).getTime() + 30 * 60000))} EST</p>
                    </div>
                    <div className='cancel-grid'>
                        <button className={isConfirmMeetingClicked ? 'cancel-meeting-button accept disabled' : "cancel-meeting-button accept"}
                            onClick={() => isConfirmMeetingClicked ? null : handleAcceptMeeting()}>
                            {isConfirmMeetingClicked ? "Confirming..." : "Confirm"}
                        </button>
                        <button className={isConfirmMeetingClicked ? 'cancel-meeting-button decline disabled' : "cancel-meeting-button decline"}
                            onClick={() => isConfirmMeetingClicked ? null : setIsAcceptMeetingClicked(false)}>
                            Go back
                        </button>
                    </div>
                </div>
                : null}
            {isMeetingError ?
                <div className="meeting-booked-container">
                    <p className="meeting-booked-text">There was an error with your meeting</p>
                    <p className="no-meeting-error">Please contact wilteducation@wilteducation for assistance.</p>
                </div>
                :
                <div className={isDeclineMeetingButtonClicked || isAcceptMeetingClicked ? "meeting-booked-container meeting-booked-extra-long blur-background" : "meeting-booked-container meeting-booked-extra-long"}>
                    {meeting?.hasMentorAcceptedMeetingRequest ?
                        <>
                            <div className='accepted-meeting-flex'>
                                <p className="how-to-join-text">Your meeting is booked</p>
                                <FontAwesomeIcon icon={faCheckCircle} className="payment-check accepted-meeting-check" />
                            </div>
                            <div className="date-time-grid">
                                <div style={{ textAlign: "center" }}>
                                    <p className="date">Date</p>
                                    <p className="date-text">{formatDay(meeting.startTime)}</p>
                                </div>
                                <div style={{ textAlign: "center" }}>
                                    <p className="time">Time</p>
                                    <p className="date-text">{formatTime(meeting.startTime)} - {formatTime(new Date(new Date(meeting.startTime).getTime() + 30 * 60000))} EST</p>
                                </div>
                            </div>
                        </> :
                        meeting?.hasMentorDeclinedMeeting ?
                            <>
                                <div className='accepted-meeting-flex'>
                                    <p className="how-to-join-text">Your meeting request was declined.</p>
                                    <FontAwesomeIcon icon={faCheckCircle} className="payment-check accepted-meeting-check" />
                                </div>
                                <p className='need-to-cancel-text'>Your student will automatically be notified that their meeting request was declined.</p>
                                <p className='need-to-cancel-text'>This meeting request will no longer exist after you refresh or leave this page.</p>
                            </> :
                            <>
                                <p className="meeting-booked-text">Choose which meeting time you prefer by clicking on one of the three dates below</p>
                                <div className="proposed-meetings-grid">
                                    {[...Array(3).keys()].map((key, index) => {
                                        return (
                                            <p onClick={() => {
                                                if (isDayInThePast(meeting[`${index}`].startTime)) { return }
                                                setIsAcceptMeetingClicked(true)
                                                setSelectedMeetingDate(meeting[`${index}`].startTime)
                                            }}
                                                className={isDayInThePast(meeting[`${index}`].startTime) ? "date-text pointer shadow gray disabled no-click" : "date-text pointer shadow"}>
                                                {formatDay(meeting[`${index}`].startTime)} @ {formatTime(meeting[`${index}`].startTime)} - {formatTime(new Date(new Date(meeting[`${index}`].startTime).getTime() + 30 * 60000))} EST</p>
                                        )
                                    })}
                                    <button onClick={() => setIsDeclineMeetingButtonClicked(true)} className='not-available-button shadow'>
                                        I am not available for any of the times above
                                    </button>
                                </div>
                            </>
                    }
                    {meeting?.hasMentorDeclinedMeeting ? null
                        : meeting?.hasMentorAcceptedMeetingRequest ?
                            <>
                                <br></br> <br></br>
                                <p className="reminders-header">Now that your meeting is booked:</p>
                                <br></br>
                                <ol type="1">
                                    <li className="reminders-text">You should soon receive a meeting confirmation email with details and instructions on how to join your meeting.</li>
                                    <li className="reminders-text">Your student will automatically be notified that you have accepted their meeting request.</li>
                                    <li className="reminders-text">If you need to cancel your meeting, you can do so by navigating to "My Meetings" in your mentor dashboard page.</li>
                                    <li className="reminders-text">For any issues or questions, please email wilteducation@wilteducation.com.</li>
                                </ol>
                            </>
                            :
                            <>
                                <p className="reminders-header">Instructions and Reminders:</p>
                                <ol type="1">
                                    <li className="reminders-text">Select one of the three following dates above. Once selected, your meeting will be booked on the date you have chosen. There is no further action required except to join your meeting 5 minutes before it begins!</li>
                                    <li className="reminders-text">After you select a date, you will receive a confirmation email telling you that your meeting is booked as well as instructions for how to join the meeting.</li>
                                    <li className="reminders-text">If you are not available for any of the times listed above, please select the button indicating you are not available.</li>
                                    <li className="reminders-text">If you have any issues or questions, please email wilteducation@wilteducation.com.</li>
                                </ol></>
                    }
                </div>}
            <Footer />
        </>
    )
}

export default AcceptMeeting