import firebase from 'firebase/compat/app';
const { API_ROOT_URL } = require("../../../configs/activebuildconfig.json")



/**
 * CONTROLLER - mentor
 * FUNCTION - addForumMessage
 * VERIFY - mentor who is authenticated
 * @param {*} messageObject The message that the user is trying to add to the forum along with date, time etc.
 * @param {*} callback Callback function to run after the result is returned
 * @returns Return nothing
 */

export const addForumMessage = async (messageObject, callback) => {
    firebase.auth().onAuthStateChanged(async user => {
        const token = await user.getIdToken(false);
        const response = await fetch(`${API_ROOT_URL}/mentor/add_forum_message`, {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-type": "application/json",
            },
            body: JSON.stringify({
                token,
                messageObject
            }),
        })
        const result = await response.json();
        callback(result)
    })
}

