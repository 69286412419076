import React from "react"
import "./index.css"

const LoadingCards = () => {
    const arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
    return (
        <>
            {
                arr.map((elem, index) => {
                    return (
                        <div key={index} className="loading-card-container">
                            <div className="loading-card">
                            </div>
                            <div className="name line"></div>
                            <div className="major line"></div>
                            <div className="major line"></div>
                            <div className="clubs line"></div>
                        </div>
                    )
                })
            }
        </>
    )
}

export default LoadingCards