import React, { useEffect, useContext } from 'react'
import Navbar from '../Navbar'
import Footer from '../Footer'
import Loading from "../Loading"
import { ContextProvider } from '../../context';
import { TypeAnimation } from 'react-type-animation';
import signUpWithGoogle from "../../utils/signUp"


import "./index.css"

export const AboutUs = () => {

    const { user, isLoading, setIsLoading } = useContext(ContextProvider)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return isLoading ? <Loading />
        : (
            <>
                <Navbar />
                <div className='about-page-container'>
                    <div className='about-page-grid'>
                        <div>
                            <p className='about-page-slogan'><span className='bold-letter'>W</span>hat's <span className='bold-letter'>I</span>t <span className='lower-slogan-text'><br /><span className='bold-letter'>L</span>ike <span className='bold-letter'>T</span>o</span>...</p>
                            <button
                                onClick={() => {
                                    user?.isUserAuthenticated ? window.location.href = "/user/meetings" : signUpWithGoogle(user, "user"); localStorage.setItem('signUpType', 'user'); setIsLoading(true)
                                }}
                                className='about-page-get-started-button'>
                                {user?.isUserAuthenticated ? "My Meetings" : "Get Started"}
                            </button>
                        </div>
                        <div>
                            <p className='about-page-slogan-2'>Be a Student at the University of Michigan</p>
                            <p className='about-page-sub-slogan'>Get advice from students who have already navigated it themselves.</p>
                        </div>
                    </div>
                </div>
                <div className='about-page-container-2'>
                    <p className='about-page-slogan-center'>What's It Like To</p>
                    <TypeAnimation
                        sequence={[
                            'Be a political science major',
                            1000,
                            'Be a first generation student',
                            1000,
                            'Live in South Quad',
                            1000,
                            'Be a Business major in Ross',
                            1000,
                            'Find engineering internships',
                            1000,
                            'Not know what to major in',
                            1000,
                            'Be an out-of-state student',
                            1000,
                            'Find a biology research lab',
                            1000,
                            'Study abroad in Spain',
                            1000,
                            'Join a professional fraternity',
                            1000,
                            'Join a club sports team',
                            1000,
                            'Choose your classes',
                            1000,
                        ]}
                        wrapper="div"
                        cursor={true}
                        repeat={Infinity}
                        className="about-page-type"
                    />
                    <p className='about-page-go-to-umich'>at the University of Michigan?</p>
                </div>
                <div className='about-page-container-3'>
                    <div className='school-tours-top-left'>
                        <p className='about-page-schools-tours-header'>School Tours are Old School</p>
                        <p className='about-page-school-tours-text'>College tours are designed to give general information that appeals to everyone.
                            That's probably why every school's college tour sounds the same! We believe students deserve information that's tailored to them. Whether you want to know about a specific major,
                            hear more about a club you'd consider joining on campus, or just get a sense of the social environment at Michigan, you can speak to a Michigan student one-on-one who can go into depth about
                            anything you might want to know.



                        </p>
                    </div>
                    <div className='school-tours-top-right'>
                        <p className='about-page-schools-tours-header'>Reimagining the Way You Choose a College</p>
                        <p className='about-page-school-tours-text'>Our mission is to put the power of choosing a college back in your hands by giving you access to the
                            greatest resource the college has to offer: the students! Our advanced filtering methods allow you to find students who can offer you the most relevant advice
                            because they share your interests and experiences. Why let colleges decide who talks to you when <i>you</i> can decide who talks to you? We're
                            changing the way students choose their college and we're thrilled for you to join us.



                        </p>
                    </div>
                    <div className='school-tours-bottom-left'>
                        <p className='about-page-schools-tours-header'>Information Tailored Specifically To You</p>
                        <p className='about-page-school-tours-text'>
                            With college students ranging from a diverse set of majors, backgrounds, extracurriculars, and college experiences, W.I.L.T. has something to offer for
                            any type of student. Rather than general information that appeals to everyone, college students on W.I.L.T. can provide tailored advice that applies directly to your situation.
                            Whether you're looking to speak to a student who majored in Data Science or Sports Management, a student from California or New Jersey, W.I.L.T has a student for you and everything in between.



                        </p>
                    </div>
                </div>
                <Footer />
            </>
        )
}
