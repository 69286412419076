import firebase from 'firebase/compat/app';
const { API_ROOT_URL } = require("../../../configs/activebuildconfig.json")



/**
 * CONTROLLER - mentor
 * FUNCTION - updateCalendar
 * VERIFY - Mentor who is authenticated on the frontend (Currently with google)
 * @param {*} calendarData - The day of the calendar with the new data to reflect the mentor's changed availability
 * @param {*} callback callback function that's takes in the result from the endpoint
 * @returns Returns the mentors's updated calendar info
 */

export const updateCalendar = async (calendarData, callback) => {
    firebase.auth().onAuthStateChanged(async mentor => {
        const token = await mentor.getIdToken(false);
        const response = await fetch(`${API_ROOT_URL}/mentor/update_calendar`, {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-type": "application/json",
            },
            body: JSON.stringify({
                token,
                calendarData
            }),
        })
        const result = await response.json();
        callback(result)
    })
}

