import React, { useRef, useEffect, useState, useContext } from 'react'
import { useNavigate } from "react-router-dom"
import { ContextProvider } from "../../context/index"
import { createMentor } from '../../API/api'
import moment from 'moment-timezone';
import majors from '../../utils/majors';
import minors from "../../utils/minors"
import Loading from "../Loading"
import "./index.css"

const MentorSignup = () => {

    const sortedMajors = majors.sort()

    const { user, setUser } = useContext(ContextProvider)

    const firstNameRef = useRef();

    const lastNameRef = useRef();

    const navigate = useNavigate();

    const [isFirstNameInputActive, setIsFirstNameInputActive] = useState(false)

    const [isLastNameInputActive, setIsLastNameInputActive] = useState(false)

    const [firstName, setFirstName] = useState("")

    const [lastName, setLastName] = useState("")

    const [email, setEmail] = useState("")

    const [major, setMajor] = useState("")

    const [minor, setMinor] = useState("")

    const [year, setYear] = useState("")

    const [isFirstNameError, setIsFirstNameError] = useState(false)

    const [isLastNameError, setIsLastNameError] = useState(false)

    const [isMajorError, setIsMajorError] = useState(false)

    const [isMinorError, setIsMinorError] = useState(false)

    const [isYearError, setIsYearError] = useState(false)

    const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false)

    const [isLoading, setIsLoading] = useState(true)

    const handleChangeMajor = (e) => {
        setIsMajorError(false)
        const major = e.target.value
        setMajor(major)
    }

    const handleChangeMinor = (e) => {
        setIsMinorError(false)
        const minor = e.target.value
        setMinor(minor)
    }

    const handleChangeYear = (e) => {
        setIsYearError(false)
        const year = e.target.value
        setYear(year)
    }

    const handleChangeFirstName = (e) => {
        setIsFirstNameError(false)
        const userInput = e.target.value
        setFirstName(userInput)

        if (!userInput) {
            setIsFirstNameError(true)
        }
    }


    const handleChangeLastName = (e) => {
        setIsLastNameError(false)
        const userInput = e.target.value
        setLastName(userInput)

        if (!userInput) {
            setIsLastNameError(true)
        }
    }

    const handleSubmit = () => {
        if (!major) {
            setIsMajorError(true)
        }
        if (!minor) {
            setIsMinorError(true)
        }
        if (!year) {
            setIsYearError(true)
        }

        // If ther are no errors, then proceed with submission
        else if (major && minor && year && firstName && lastName) {
            // Disable the user from clicking on the submit button twice
            setIsSubmitButtonClicked(true)
            createMentor({ firstName, lastName, email, major, minor, year, timezone: moment.tz.guess() }, (result) => {
                // If the creation of the mentor was successful, route the mentor to MentorDashboard
                // Also add their data to context
                if (result.success) {
                    result.data.isUserAuthenticated = true
                    setUser(result.data)
                    navigate('/mentor')
                }
            })
        }
    }

    useEffect(() => {
        setFirstName(user?.name?.split(" ")[0]);
        setLastName(user?.name?.split(" ")[1])
        setEmail(user?.email)
    }, [user])

    useEffect(() => {
        if (user?.email && !user?.email?.endsWith("@umich.edu")) {
            window.location.href = "/email/error"
        }

        if (user?.isUserAuthenticated && !user.isSignUpCompleted) {
            setIsLoading(false)
        }
        else if (user?.isUserAuthenticated === false) {
            navigate("/mentor")
        }
    }, [user])

    useEffect(() => {
        let highlightFirstNameInput = (event) => {
            if (!firstNameRef.current.contains(event.target)) {
                setIsFirstNameInputActive(false)
            }
            // When the user clicks back into the search bar, show the auto-complete again
            if (firstNameRef.current.contains(event.target)) {
                setIsFirstNameInputActive(true)
            }
        }

        document.addEventListener("mousedown", highlightFirstNameInput);
        return () => {
            document.removeEventListener("mousedown", highlightFirstNameInput);
        }
    }, [])

    useEffect(() => {
        let highlightFirstNameInput = (event) => {
            if (!lastNameRef.current.contains(event.target)) {
                setIsLastNameInputActive(false)
            }
            // When the user clicks back into the search bar, show the auto-complete again
            if (lastNameRef.current.contains(event.target)) {
                setIsLastNameInputActive(true)
            }
        }

        document.addEventListener("mousedown", highlightFirstNameInput);
        return () => {
            document.removeEventListener("mousedown", highlightFirstNameInput);
        }
    }, [])



    return isLoading ? <Loading />
        : (
            <div className='signup-modal'>
                <p className='signup-header-text'>Finish signing up</p>
                <hr></hr>
                <div className='names-container'>
                </div>
                <div ref={firstNameRef} className={isFirstNameError ? 'first name-container active-border-red' : isFirstNameInputActive ? 'first name-container active-border' : isLastNameInputActive ? "first-no-bottom name-container" : 'first name-container'}>
                    <p className={isFirstNameError ? 'red name-header' : 'name-header'}>First name</p>
                    <input placeholder="First Name" onChange={handleChangeFirstName} value={firstName} className='name-input'></input>
                </div>
                <div ref={lastNameRef} className={isLastNameError ? 'last-with-border name-container active-border-red' : isLastNameInputActive ? 'last-with-border name-container active-border' : 'last name-container'}>
                    <p className={isLastNameError ? 'red name-header' : 'name-header'}>Last name</p>
                    <input placeholder="Last Name" onChange={handleChangeLastName} value={lastName} className='name-input'></input>
                </div>
                {isFirstNameError ? <p className='error-text'>First Name is a required field</p> : null}
                {isLastNameError ? <p className='error-text'>Last Name is a required field</p> : null}
                <p className='name-message'>Make sure your name is what you want people to call you.</p>
                <div>
                    <p className='mentor-information'>Student Information</p>
                    {isMajorError || isMinorError || isYearError ? <p className='error-text'>The Student Information field is required</p> : null}
                    <div className='select-container'>
                        <select onChange={(e) => handleChangeMajor(e)} className={isMajorError ? 'select-mentor-info select-error' : 'select-mentor-info'}>
                            <option selected disabled >Major</option>
                            {sortedMajors.map(major => {
                                return <option value={major}>{major}</option>
                            })}
                        </select>
                        <select onChange={(e) => handleChangeMinor(e)} className={isMinorError ? 'select-mentor-info select-error' : 'select-mentor-info'}>
                            <option selected disabled >Minor</option>
                            {minors.map(minor => {
                                return <option value={minor}>{minor}</option>
                            })}
                        </select>
                        <select onChange={(e) => handleChangeYear(e)} className={isYearError ? 'select-mentor-info select-error' : 'select-mentor-info'}>
                            <option selected disabled >Year</option>
                            <option >Freshman</option>
                            <option >Sophomore</option>
                            <option >Junior</option>
                            <option >Senior</option>
                            <option >Fifth Year</option>
                            <option >Grad Student</option>
                            <option >PhD Student</option>
                        </select>
                    </div>
                    <p className='name-message'>This is basic information to get you started. You'll be able to edit and add more later.</p>
                </div>
                <div className='email-container'>
                    <p className='name-header'>Email</p>
                    <input value={email} className='name-input gray-1' disabled></input>
                </div>
                <p className='name-message'>We'll only send you emails when it's important to you.</p>
                <p className='policy-agreement'>By selecting
                    <span className='agree-and-continue'> Agree and continue</span>,
                    I agree to W.I.L.T's <span className='link'>Term of Service, Payments Terms of Service</span>,
                    and acknowledge the <span className='link'>Privacy Policy</span></p>
                <button onClick={!isSubmitButtonClicked ? handleSubmit : null} className={!isSubmitButtonClicked ? 'agree-and-continue-button' : "agree-and-continue-button-clicked"}>
                    {isSubmitButtonClicked ? "Loading..." : "Agree and continue"}
                </button>
            </div>
        )
}

export default MentorSignup