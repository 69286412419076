import React, { useContext, useEffect } from "react"
import Navbar from "../Navbar"
import Footer from "../Footer"
import signUpWithGoogle from "../../utils/signUp";
import { ContextProvider } from '../../context';
import Loading from "../Loading"
import "./index.css"
import {
    faCircleExclamation
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const EmailError = () => {

    const { isLoading, setIsLoading } = useContext(ContextProvider)


    return isLoading ? <Loading />
        : (
            <>
                <Navbar />
                <FontAwesomeIcon icon={faCircleExclamation} className="error-icon" />
                <p className="email-error-message">You need a @umich.edu email to become a mentor.</p>
                <button
                    onClick={() => {
                        signUpWithGoogle(null, "mentor"); localStorage.setItem('signUpType', 'mentor'); setIsLoading(true)
                    }}
                    className="change-email">
                    Change email
                </button>
                <Footer />
            </>
        )
}

export default EmailError