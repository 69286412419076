import React, { useRef, useEffect } from 'react'
import Forum from '../Forum'
import Navbar from '../Navbar'
import Footer from '../Footer'
import "./index.css"
const MentorForum = () => {

    const forumRef = useRef()

    useEffect(() => {
        forumRef.current.scrollIntoView()
    }, [])

    return (
        <>
            <Navbar />
            <p ref={forumRef} className='forum-header-text'>Reply to one of the student's questions!</p>
            <div className='forum-container'>
                <Forum />
            </div>
            <Footer />
        </>
    )

}

export default MentorForum