import React, { useState, useContext, useEffect, useRef } from 'react'
import { getStorage, ref, uploadString } from "firebase/storage";
import { ContextProvider } from '../../context';
import { createProfile } from '../../API/controllers/mentor';
import Navbar from "../Navbar"
import mentorImg from "../../utils/images/student3.jpg"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faPlus, faMinus, faCheck, faX,
} from "@fortawesome/pro-solid-svg-icons";
import majorsArray from "../../utils/majors"
import minorsArray from "../../utils/minors"
import states from "../../utils/states"
import "./index.css"
const CreateMentorProfile = () => {

    const sortedMajors = majorsArray.sort()

    const storage = getStorage();

    const { user } = useContext(ContextProvider)

    const [profileImage, setProfileImage] = useState(mentorImg);

    const [schools, setSchools] = useState({ schoolOne: "", schoolTwo: "" })

    const [majors, setMajors] = useState({ majorOne: user?.mentorBio?.majors[0], majorTwo: "", majorThree: "" })

    const [minors, setMinors] = useState({ minorOne: user?.mentorBio?.minors[0], minorTwo: "", minorThree: "" })

    const [introductionText, setIntroductionText] = useState("")

    const [isProfileImageUploaded, setIsProfileImageUploaded] = useState(false)

    const [isBackButtonDisabled, setIsBackButtonDisabled] = useState(true)

    const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true)

    const [currentPageNumber, setCurrentPageNumber] = useState(1)

    const [numSchools, setNumSchools] = useState(1);

    const [numMajors, setNumMajors] = useState(1)

    const [numMinors, setNumMinors] = useState(1)

    const [state, setState] = useState("")

    const [highSchoolSize, setHighSchoolSize] = useState(null)

    const [dorm, setDorm] = useState("")

    const [isTransferStudent, setIsTransferStudent] = useState(null)

    const [isFirstGen, setIsFirstGen] = useState(null)

    const [hasStudiedAbroad, setHasStudiedAbroad] = useState(null)

    const [referralEmail, setReferralEmail] = useState("")

    const [width, setWidth] = useState(document.body.clientWidth);

    const [graduationYear, setGraduationYear] = useState(null)

    const [askMeQuestions, setAskMeQuestions] = useState([{ text: "" }, { text: "" }, { text: "" }, { text: "" }, { text: "" }])

    const pageRef = useRef()

    const [clubs, setClubs] = useState(
        [
            { name: "Student Employment", clubType: "Student Employment", placeholder: "Enter the Job Name", isSelected: false, isModified: false, userText: "" },
            { name: "Greek Life", clubType: "Greek Life", placeholder: "Enter the Frat or Sorority", isSelected: false, isModified: false, userText: "" },
            { name: "Research Lab", clubType: "Research Lab", placeholder: "Enter the type of Research", isSelected: false, isModified: false, userText: "" },
            { name: "Student Government", clubType: "Student Government", placeholder: "Enter the Government Position", isSelected: false, isModified: false, userText: "" },
            { name: "Intramural Sports", clubType: "Intramural Sports", placeholder: "Enter the Sport", isSelected: false, isModified: false, userText: "" },
            { name: "Outreach/ Volunteering", clubType: "Outreach/ Volunteering", placeholder: "Enter the Organization Name", isSelected: false, isModified: false, userText: "" },
            { name: "Club Sports", clubType: "Club Sports", placeholder: "Enter Club Name", isSelected: false, isModified: false, userText: "" },
            { name: "Social Clubs", clubType: "Social Clubs", placeholder: "Enter Club Name", isSelected: false, isModified: false, userText: "" },
            { name: "Engineering Clubs", clubType: "Engineering Clubs", placeholder: "Enter the Club Name", isSelected: false, isModified: false, userText: "" },
            { name: "Art Clubs", clubType: "Art Clubs", placeholder: "Enter the Club Name", isSelected: false, isModified: false, userText: "" },
            { name: "Science Clubs", clubType: "Science Clubs", placeholder: "Enter the Club Name", isSelected: false, isModified: false, userText: "" },
            { name: "Journalism Clubs", clubType: "Journalism Clubs", placeholder: "Enter the Club Name", isSelected: false, isModified: false, userText: "" },
            { name: "Marching Band", clubType: "Marching Band", placeholder: "Enter the Section Name", isSelected: false, isModified: false, userText: "" },
            { name: "Business Clubs", clubType: "Business Clubs", placeholder: "Enter the Club Name", isSelected: false, isModified: false, userText: "" },
            { name: "Law Clubs", clubType: "Law Clubs", placeholder: "Enter the Club Name", isSelected: false, isModified: false, userText: "" },
            { name: "Gaming Clubs", clubType: "Gaming Clubs", placeholder: "Enter the Club Name", isSelected: false, isModified: false, userText: "" },
            { name: "Religious Organizations", clubType: "Religious Organizations", placeholder: "Enter the Organization Name", isSelected: false, isModified: false, userText: "" },
            { name: "Political Organization", clubType: "Political Organization", placeholder: "Enter the Organization Name", isSelected: false, isModified: false, userText: "" },
            { name: "Health/Wellness Clubs", clubType: "Health/Wellness Clubs", placeholder: "Enter the Club Name", isSelected: false, isModified: false, userText: "" },
            { name: "Cultural/Ethnic Clubs", clubType: "Cultural/Ethnic Clubs", placeholder: "Enter the Club Name", isSelected: false, isModified: false, userText: "" },
            { name: "Music Groups/Clubs", clubType: "Music Groups/Clubs", placeholder: "Enter the Club Name", isSelected: false, isModified: false, userText: "" },
            { name: "Entrepreneurship Clubs", clubType: "Entrepreneurship Clubs", placeholder: "Enter the Club Name", isSelected: false, isModified: false, userText: "" },
            { name: "Math Clubs", clubType: "Math Clubs", placeholder: "Enter the Club Name", isSelected: false, isModified: false, userText: "" },
            { name: "Honors Society", clubType: "Honors Society", placeholder: "Enter the Society Name", isSelected: false, isModified: false, userText: "" },
            { name: "Other", clubType: "Other", placeholder: "Enter the Club Name", isSelected: false, isModified: false, userText: "" },
        ]
    )

    const [originalClubNames, setOriginalClubNames] = useState([
        "Student Employment", "Greek Life", "Research Lab", "Student Government", "Intramural Sports", "Outreach/ Volunteering", "Club Sports", "Social Clubs", "Engineering Clubs", "Art Clubs", "Science Clubs",
        "Journalism Clubs", "Marching Band", "Business Clubs", "Law Clubs", "Gaming Clubs", "Religious Organizations", "Political Organization",
        "Health/Wellness Clubs", "Cultural/Ethnic Clubs", "Music Groups/Clubs", "Entrepreneurship Clubs", "Math Clubs", "Honors Society", "Other",
    ])

    const handleUploadProfileImage = (e) => {
        const file = e.target.files[0]
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            setProfileImage(reader.result)
            setIsProfileImageUploaded(true)
            setIsNextButtonDisabled(false)
        };
    }

    const handleSelectClub = (selectedIndex) => {
        clubs.forEach((club, index) => {
            if (index === selectedIndex) {
                club.isSelected = true
            }
        })
        setClubs([...clubs])
    }

    const handleDeselectClub = (selectedIndex) => {
        clubs.forEach((club, index) => {
            if (index === selectedIndex) {
                club.isSelected = false
                club.name = originalClubNames[index]
                club.isModified = false
            }
        })
        setClubs([...clubs])
    }

    const handleInputClubText = (selectedIndex, e) => {
        clubs.forEach((club, index) => {
            if (index === selectedIndex) {
                club.userText = e.target.value
            }
        })
        setClubs([...clubs])
    }

    const handleClubInputCheck = (selectedIndex) => {
        clubs.forEach((club, index) => {
            if (index === selectedIndex) {
                if (!club.userText) { return }
                club.name = club.userText
                club.isSelected = false
                club.isModified = true
            }
        })
        setClubs([...clubs])
    }

    const addAnotherClub = (index) => {
        const club = structuredClone(clubs[index])
        club.userText = ""
        club.name = clubs[index].clubType
        club.isSelected = false
        club.isModified = false
        clubs.splice(index + 1, 0, club);
        setClubs([...clubs])

        originalClubNames.splice(index + 1, 0, club.clubType);
        setOriginalClubNames([...originalClubNames])
    }

    const handleClubInputCheckKey = ((selectedIndex, e) => {
        if (e.key === "Enter" && e.target.value) {
            clubs.forEach((club, index) => {
                if (index === selectedIndex) {
                    club.name = club.userText
                    club.isSelected = false
                    club.isModified = true
                }
            })
            setClubs([...clubs])
        }
    })

    const handleAskMeQuestions = (e, index) => {
        askMeQuestions[index].text = e.target.value
        setAskMeQuestions([...askMeQuestions])
    }

    const canMentorClickNextOnPageTwo = (selectType) => {
        if (selectType === "SCHOOL") {
            if (minors.minorOne && majors.majorOne) {
                setIsNextButtonDisabled(false)
            }
        }
        else if (selectType === "MAJOR") {
            if (minors.minorOne && schools.schoolOne) {
                setIsNextButtonDisabled(false)
            }
        }
        else if (selectType === "MINOR") {
            if (majors.majorOne && schools.schoolOne) {
                setIsNextButtonDisabled(false)
            }
        }
        else {
            if (majors.majorOne && schools.schoolOne && minors.minorOne) {
                setIsNextButtonDisabled(false)
            }
        }
    }

    const handleSelectSchools = (e, index) => {
        if (index === 0) {
            schools.schoolOne = e.target.value
        }
        else {
            schools.schoolTwo = e.target.value
        }
        setSchools({ ...schools })
    }

    const handleSelectMajors = (e, index) => {
        if (index === 0) {
            majors.majorOne = e.target.value
        }
        else if (index === 1) {
            majors.majorTwo = e.target.value
        }
        else {
            majors.majorThree = e.target.value
        }
        setMajors({ ...majors })
    }

    const handleSelectMinors = (e, index) => {
        if (index === 0) {
            minors.minorOne = e.target.value
        }
        else if (index === 1) {
            minors.minorTwo = e.target.value
        }
        else {
            minors.minorThree = e.target.value
        }
        setMinors({ ...minors })
    }

    const checkIfQuestionsAreAnswered = () => {
        if (state && highSchoolSize && dorm && isTransferStudent !== null && isFirstGen !== null && hasStudiedAbroad !== null) {
            setIsNextButtonDisabled(false)
        }
    }

    const handleQuestions = (index) => {
        if (index === 0) {
            if (highSchoolSize && dorm && isTransferStudent !== null && isFirstGen !== null && hasStudiedAbroad !== null && graduationYear !== null) {
                setIsNextButtonDisabled(false)
            }
        }
        else if (index === 1) {
            if (state && dorm && isTransferStudent !== null && isFirstGen !== null && hasStudiedAbroad !== null && graduationYear !== null) {
                setIsNextButtonDisabled(false)
            }
        }
        else if (index === 2) {
            if (state && highSchoolSize && isTransferStudent !== null && isFirstGen !== null && hasStudiedAbroad !== null && graduationYear !== null) {
                setIsNextButtonDisabled(false)
            }
        }
        else if (index === 3) {
            if (state && highSchoolSize && dorm && isFirstGen !== null && hasStudiedAbroad !== null && graduationYear !== null) {
                setIsNextButtonDisabled(false)
            }
        }
        else if (index === 4) {
            if (state && highSchoolSize && dorm && isTransferStudent !== null && hasStudiedAbroad !== null && graduationYear !== null) {
                setIsNextButtonDisabled(false)
            }
        }
        else if (index === 5) {
            if (state && highSchoolSize && dorm && isTransferStudent !== null && isFirstGen !== null && graduationYear !== null) {
                setIsNextButtonDisabled(false)
            }
        }

        else if (index === 6) {
            if (state && highSchoolSize && dorm && isTransferStudent !== null && isFirstGen !== null && hasStudiedAbroad !== null) {
                setIsNextButtonDisabled(false)
            }
        }
    }

    const handleSubmit = () => {

        const profileData = {
            schools, majors, minors, clubs, introductionText, state, referralEmail,
            highSchoolSize, dorm, isTransferStudent, isFirstGen, hasStudiedAbroad, graduationYear, askMeQuestions
        }

        const file = profileImage.split(';base64,').pop()

        const profileImageRef = ref(storage, `studentImages/${user.email}`);

        uploadString(profileImageRef, file, 'base64').then((snapshot) => {
            createProfile(profileData, (result) => {
                if (result.success) {
                    window.location.reload()
                }
            });
        });
    }

    const handleWindowSizeChange = () => {
        setWidth(document.body.clientWidth)
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    useEffect(() => {
        pageRef?.current?.scrollTo(0, 0)
        if (currentPageNumber === 2) {
            canMentorClickNextOnPageTwo("")
        }
    }, [user, currentPageNumber])

    return (
        <>
            {width > 430 ? <Navbar /> : null}
            {currentPageNumber === 7 ?
                <div className="profile-complete">
                    <p className='profile-success-message'>Congratulations! Your profile was successfully created.</p>
                    <p className='profile-loading-message'>We're loading it now.</p>
                </div> :
                <div className="split-screen">
                    <div className='instructions'>
                        {
                            currentPageNumber === 1 ?
                                <div className='instructions-text-container'>
                                    <p className='instructions-text'>You're more than a name and a major. <br></br> Let's build your profile.</p>
                                    <p className='qaulifier'>(Building your profile takes two minutes)</p>
                                </div>
                                : currentPageNumber === 2 ?
                                    <div className='instructions-text-container'>
                                        <p className='instructions-text'>Let's dive deeper into your academics.</p>
                                    </div>
                                    :
                                    currentPageNumber === 3 ?
                                        <div className='instructions-text-container'>
                                            <p className='instructions-text'>Give us some insight into your student bio.</p>
                                        </div>
                                        : currentPageNumber === 4 ?
                                            <div className='instructions-text-container'>
                                                <p className='instructions-text'>What are you involved in at Michigan?</p>
                                                <p className='qaulifier'>(You can come back to this section later)</p>
                                            </div>
                                            : currentPageNumber === 5 ?
                                                <div className='instructions-text-container'>
                                                    <p className='instructions-text'>Students have questions for you. <br></br>Let them know what to ask.</p>
                                                    <p className='qaulifier'>(You can come back to this section later)</p>
                                                </div>
                                                : currentPageNumber === 6 ?
                                                    <div className='instructions-text-container'>
                                                        <p className='instructions-text'>You've got hobbies, passions, and quirks. <br /> Introduce yourself to the people. </p>
                                                        <p className='qaulifier'>(You can come back to this section later)</p>
                                                    </div>
                                                    : null
                        }
                    </div>
                    <div className='actions'>
                        {
                            currentPageNumber === 1 ?
                                <div className='text-area'>
                                    <div className='inner-grid'>
                                        <p className='add-a-profile-image-text'>Add a profile image</p>
                                        <div className='add-a-profile-image'>
                                            <div className='image-div'>
                                                <img className="mentor-img" alt="mentor" src={profileImage}></img>
                                            </div>
                                            <label for="avatar" className='upload-photo-button'>
                                                {isProfileImageUploaded ? "Change uploaded image" : "Upload image"}
                                            </label>
                                            <input type="file" id="avatar" name="avatar" accept="image/png, image/jpeg" onChange={(e) => handleUploadProfileImage(e)} />
                                        </div>
                                    </div>
                                </div>
                                : currentPageNumber === 2 ?
                                    <div className='student-form-data'>
                                        <p className='what-school'>What school do you belong to?</p>
                                        {[...Array(numSchools).keys()].map((elem, index) => {
                                            return (
                                                <>
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>
                                                    <select onChange={(e) => {
                                                        handleSelectSchools(e, index)
                                                        canMentorClickNextOnPageTwo("SCHOOL")
                                                    }}
                                                        value={Object.values(schools)[index] ? Object.values(schools)[index] : null}
                                                        className='select-school center'>
                                                        <option selected disabled >School</option>
                                                        <option >LSA</option>
                                                        <option >Business</option>
                                                        <option >Engineering</option>
                                                        <option >Nursing</option>
                                                        <option >Kinesiology</option>
                                                        <option >Education</option>
                                                        <option >Art and Design</option>
                                                        <option >Information</option>
                                                        <option >Public Policy</option>
                                                        <option >Public Health</option>
                                                        <option >Social Work</option>
                                                        <option >Medicine</option>
                                                        <option >Pharmacy</option>
                                                        <option >Dentistry</option>
                                                        <option >Law</option>
                                                        <option >Architecture and Urban Planning</option>
                                                        <option >Environment and Sustainability</option>
                                                        <option >Music, Theatre and Dance</option>
                                                        <option >Rackham School of Graduate Studies</option>
                                                    </select>
                                                </>
                                            )
                                        })}
                                        <div className='flex-school' onClick={() => {
                                            if (numSchools === 1) { setNumSchools(2) }
                                            else { setNumSchools(1) }
                                        }}>
                                            {numSchools === 1 ? <p className='add-school'>Add school</p> : <p className='add-school'>Delete school</p>}
                                            <FontAwesomeIcon icon={numSchools === 1 ? faPlus : faMinus} className="plus-icon" />
                                        </div>
                                        <br></br>
                                        <br></br>
                                        <p className='what-school'>What's your Major?</p>
                                        <div className={numMajors === 1 ? 'majors-grid' : "majors-grid column-spacing"}>
                                            {[...Array(numMajors).keys()].map((elem, index) => {
                                                return (
                                                    <div>
                                                        <select
                                                            value={Object.values(majors)[index] ? Object.values(majors)[index] : null}
                                                            onChange={(e) => {
                                                                handleSelectMajors(e, index)
                                                                canMentorClickNextOnPageTwo("MAJOR")
                                                            }} className="select-mentor-info center-text select-width">
                                                            <option selected disabled >Major</option>
                                                            {sortedMajors.map(major => {
                                                                return <option value={major}>{major}</option>
                                                            })}
                                                        </select>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        <div className='flex-major' onClick={() => {
                                            if (numMajors < 3) { setNumMajors(numMajors + 1) }
                                            else { setNumMajors(numMajors - 1) }
                                        }}>
                                            {numMajors === 1 ? <p className='add-major'>Add major</p> : numMajors === 3 ? <p className='add-major'>Delete major</p>
                                                : null}
                                            {
                                                numMajors === 1 ? <FontAwesomeIcon icon={faPlus} className="plus-icon" /> :
                                                    numMajors === 3 ? <FontAwesomeIcon icon={faMinus} className="plus-icon" /> : null
                                            }
                                        </div>
                                        {numMajors === 2 ?
                                            <>
                                                <div onClick={() => setNumMajors(numMajors + 1)} className='flex-major-two'>
                                                    <p className='add-major'>Add major</p>
                                                    <FontAwesomeIcon icon={faPlus} className="plus-icon" />
                                                </div>
                                                <div onClick={() => setNumMajors(numMajors - 1)} className='flex-major-two'>
                                                    <p className='add-major'>Delete major</p>
                                                    <FontAwesomeIcon icon={faMinus} className="plus-icon" />
                                                </div>
                                            </>
                                            : null}
                                        <br></br>
                                        <br></br>
                                        <p className='what-minor'>What's your Minor?</p>
                                        <div className={numMinors === 1 ? 'majors-grid' : "majors-grid column-spacing"}>
                                            {[...Array(numMinors).keys()].map((elem, index) => {
                                                return (
                                                    <div>
                                                        <select
                                                            value={Object.values(minors)[index] ? Object.values(minors)[index] : null}
                                                            onChange={(e) => {
                                                                handleSelectMinors(e, index)
                                                                canMentorClickNextOnPageTwo("MINOR")
                                                            }} className="select-mentor-info center-text select-width">
                                                            <option selected disabled>minor</option>
                                                            {minorsArray.map(minor => {
                                                                return <option value={minor}>{minor}</option>
                                                            })}
                                                        </select>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        <div className='flex-major' onClick={() => {
                                            if (numMinors < 3) { setNumMinors(numMinors + 1) }
                                            else { setNumMinors(numMinors - 1) }
                                        }}>
                                            {numMinors === 1 ? <p className='add-major'>Add minor</p> : numMinors === 3 ? <p className='add-major'>Delete minor</p>
                                                : null}
                                            {
                                                numMinors === 1 ? <FontAwesomeIcon icon={faPlus} className="plus-icon" /> :
                                                    numMinors === 3 ? <FontAwesomeIcon icon={faMinus} className="plus-icon" /> : null
                                            }
                                        </div>
                                        {numMinors === 2 ?
                                            <>
                                                <div onClick={() => setNumMinors(numMinors + 1)} className='flex-major-two'>
                                                    <p className='add-major'>Add minor</p>
                                                    <FontAwesomeIcon icon={faPlus} className="plus-icon" />
                                                </div>
                                                <div onClick={() => setNumMinors(numMinors - 1)} className='flex-major-two'>
                                                    <p className='add-major'>Delete minor</p>
                                                    <FontAwesomeIcon icon={faMinus} className="plus-icon" />
                                                </div>
                                            </>
                                            : null}
                                    </div>
                                    : currentPageNumber === 3 ?
                                        <div className='questions-grid'>
                                            <div className='question-box'>
                                                <p className='question'>What state are you from?</p>
                                                <select value={state ? state : null}
                                                    onChange={(e) => { setState(e.target.value); handleQuestions(0) }} className='select-question'>
                                                    <option selected disabled >State</option>
                                                    {states.map(state => {
                                                        return <option value={state}>{state}</option>
                                                    })}
                                                </select>
                                            </div>
                                            <div className='question-box'>
                                                <p className='question'>When is your expected graduation?</p>
                                                <select value={graduationYear ? graduationYear : null}
                                                    onChange={(e) => { setGraduationYear(e.target.value); handleQuestions(6) }} className='select-question'>
                                                    <option selected disabled >Year</option>
                                                    <option value="Fall 2022">Fall 2022</option>
                                                    <option value="Winter 2023">Winter 2023</option>
                                                    <option value="Fall 2023">Fall 2023</option>
                                                    <option value="Winter 2024">Winter 2024</option>
                                                    <option value="Fall 2024">Fall 2024</option>
                                                    <option value="Winter 2025">Winter 2025</option>
                                                    <option value="Fall 2025">Fall 2025</option>
                                                    <option value="Winter 2026">Winter 2026</option>
                                                    <option value="Fall 2026">Fall 2026</option>
                                                </select>
                                            </div>
                                            <div className='question-box'>
                                                <p className='question'>How big was your high school graduating class?</p>
                                                <select value={highSchoolSize ? highSchoolSize : null}
                                                    onChange={(e) => { setHighSchoolSize(e.target.value); handleQuestions(1) }} className='select-question'>
                                                    <option selected disabled >Size</option>
                                                    <option value="50">1-50</option>
                                                    <option value="100">50-100</option>
                                                    <option value="200">100-200</option>
                                                    <option value="300">200-300</option>
                                                    <option value="400">300-400</option>
                                                    <option value="500">400-500</option>
                                                    <option value="1000">500-1000</option>
                                                    <option value="2000">1000-2000</option>
                                                    <option value="3000">2000+</option>
                                                </select>
                                            </div>
                                            <div className='question-box'>
                                                <p className='question'>What dorm did/do you live in Freshman Year?</p>
                                                <select value={dorm ? dorm : null} onChange={(e) => { setDorm(e.target.value); handleQuestions(2) }} className='select-question'>
                                                    <option selected disabled >Dorm</option>
                                                    <option value="Alice Lloyd Hall">Alice Lloyd Hall</option>
                                                    <option value="Baits II">Baits II</option>
                                                    <option value="Betsy Barbour">Betsy Barbour</option>
                                                    <option value="Bursley Hall">Bursley Hall</option>
                                                    <option value="Couzens Hall">Couzens Hall</option>
                                                    <option value="East Quadrangle">East Quadrangle</option>
                                                    <option value="Fletcher Hall">Fletcher Hall</option>
                                                    <option value="Helen Newberry">Helen Newberry</option>
                                                    <option value="Henderson House">Henderson House</option>
                                                    <option value="Martha Cook Building">Martha Cook Building</option>
                                                    <option value="Mary Markley Hall">Mary Markley Hall</option>
                                                    <option value="Mosher-Jordan Hall">Mosher-Jordan Hall</option>
                                                    <option value="North Quadrangle">North Quadrangle</option>
                                                    <option value="Oxford Houses">Oxford Houses</option>
                                                    <option value="South Quadrangle">South Quadrangle</option>
                                                    <option value="West Quadrangle & Cambridge House">West Quadrangle & Cambridge House</option>
                                                    <option value="Other">Other</option>
                                                </select>
                                            </div>
                                            <div className='question-box'>
                                                <p className='question'>Did you transfer to Michigan from another school?</p>
                                                <input onChange={(e) => { setIsTransferStudent(e.target.value); handleQuestions(3) }} className="pointer" type="radio" name="tranfer" id="transfer-true" value={true} checked={isTransferStudent === "true"} />
                                                <label className="multiple-choice" for="transfer-true">Yes</label>
                                                <br />
                                                <input onChange={(e) => { setIsTransferStudent(e.target.value); handleQuestions(3) }} className="pointer" name="tranfer" type="radio" id="transfer-false" value={false} checked={isTransferStudent === "false"} />
                                                <label className="multiple-choice" for="transfer-false">No</label>
                                            </div>
                                            <div className='question-box'>
                                                <p className='question'>Are you a first generation student?</p>
                                                <input onChange={(e) => { setIsFirstGen(e.target.value); handleQuestions(4) }} name="first-gen" className="pointer" type="radio" id="first-gen-true" value={true} checked={isFirstGen === "true"} />
                                                <label className="multiple-choice" for="first-gen-true">Yes</label>
                                                <br />
                                                <input onChange={(e) => { setIsFirstGen(e.target.value); handleQuestions(4) }} name="first-gen" className="pointer" type="radio" id="first-gen-false" value={false} checked={isFirstGen === "false"} />
                                                <label className="multiple-choice" for="first-gen-false">No</label>
                                            </div>
                                            <div className='question-box'>
                                                <p className='question'>Have you ever studied abroad?</p>
                                                <input onChange={(e) => { setHasStudiedAbroad(e.target.value); handleQuestions(5) }} name="studied-abroad" className="pointer" type="radio" id="studied-abroad-true" value={true} checked={hasStudiedAbroad === "true"} />
                                                <label className="multiple-choice" for="studied-abroad-true">Yes</label>
                                                <br />
                                                <input onChange={(e) => { setHasStudiedAbroad(e.target.value); handleQuestions(5) }} name="studied-abroad" className="pointer" type="radio" id="studied-abroad-false" value={false} checked={hasStudiedAbroad === "false"} />
                                                <label className="multiple-choice" for="studied-abroad-false">No</label>
                                            </div>
                                            <div className='question-box'>
                                                <p className='question'>If someone referred you to sign up, please input their email.</p>
                                                <input onChange={(e) => setReferralEmail(e.target.value)} className='referral-email'></input>
                                            </div>
                                        </div>
                                        : currentPageNumber === 4 ?
                                            <div ref={pageRef} className='involvement-grid-wrapper'>
                                                <p className='involvement-text'>Select the type of group, club, organization, or society you're involved in and let us know what it's called.</p>
                                                <div className='involvement-grid'>
                                                    {clubs.map((club, index) => {
                                                        return (
                                                            <div>
                                                                <div onClick={() => { if (!club.isSelected) { handleSelectClub(index) } }} className={club.isSelected || club.isModified ? 'student-involvement-box-selected' : 'student-involvement-box'}>
                                                                    {club.isSelected ? <input maxLength="100" onKeyDown={(e) => handleClubInputCheckKey(index, e)} onChange={(e) => handleInputClubText(index, e)} placeholder={club.placeholder} value={club.userText} className="involvement-input"></input>
                                                                        : <p className='club-text'>{club.name}</p>}
                                                                </div>
                                                                {
                                                                    club.isSelected ?
                                                                        <div className='flex-involvement'>
                                                                            <FontAwesomeIcon icon={faX} onClick={() => handleDeselectClub(index)} className="x-icon" />
                                                                            <FontAwesomeIcon icon={faCheck} onClick={() => handleClubInputCheck(index)} className="check-icon" />
                                                                            <FontAwesomeIcon icon={faPlus} onClick={() => addAnotherClub(index)} className="plus-icon-1" />
                                                                        </div>
                                                                        : null}
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                            : currentPageNumber === 5 ?
                                                <div className='ask-me-grid-wrapper'>
                                                    <p className='intro-text ask-me-header'>Share the questions you have some knowledge about.</p>
                                                    <div className='ask-me-about-flex top'>
                                                        <p className='ask-me-about-text'>Ask me about...</p>
                                                        <input value={askMeQuestions[0].text} onChange={(e) => handleAskMeQuestions(e, 0)} maxLength="75" className='ask-me-about-input' placeholder='Ex) Not knowing what to major in coming into college.'></input>
                                                    </div>
                                                    <div className='ask-me-about-flex'>
                                                        <p className='ask-me-about-text'>Ask me about...</p>
                                                        <input value={askMeQuestions[1].text} onChange={(e) => handleAskMeQuestions(e, 1)} maxLength="75" className='ask-me-about-input' placeholder='Ex) Joining a Fraternity.'></input>
                                                    </div>
                                                    <div className='ask-me-about-flex'>
                                                        <p className='ask-me-about-text'>Ask me about...</p>
                                                        <input value={askMeQuestions[2].text} onChange={(e) => handleAskMeQuestions(e, 2)} maxLength="75" className='ask-me-about-input' placeholder='Ex) Being a woman in STEM.'></input>
                                                    </div>
                                                    <div className='ask-me-about-flex'>
                                                        <p className='ask-me-about-text'>Ask me about...</p>
                                                        <input value={askMeQuestions[3].text} onChange={(e) => handleAskMeQuestions(e, 3)} maxLength="75" className='ask-me-about-input' placeholder='Ex) Living in Bursley Freshman year.'></input>
                                                    </div>
                                                    <div className='ask-me-about-flex'>
                                                        <p className='ask-me-about-text'>Ask me about...</p>
                                                        <input value={askMeQuestions[4].text} onChange={(e) => handleAskMeQuestions(e, 4)} maxLength="75" className='ask-me-about-input' placeholder='Ex) Never living away from home before college.'></input>
                                                    </div>
                                                </div>
                                                : currentPageNumber === 6 ?
                                                    <div className='last-page-container'>
                                                        <p className='intro-text intro'>Write an introduction for your profile</p>
                                                        <p className={introductionText.length < 100 ? 'char-number double' : "char-number triple"}>{introductionText.length}</p>
                                                        <p className='number-of-chars'>/ 1000 characters</p>
                                                        <textarea maxLength="999" value={introductionText} onChange={(e) => { setIntroductionText(e.target.value) }} className="textarea-intro"
                                                            placeholder="Ex) Hi, my name is John Doe! I'm a Senior studying Computer Science in the college of Literature Science and the Arts. I'm a member of Michigan Hackers and tutor Computer Science in my spare time. Catch me at Hockey games, losing to someone in Spikeball at Elbel field, or reading in a hammock near the Diag (when the weather's nice!). Currently rewatching The Office for the sixth time even though I should be studying for my exams. Looking forward to speaking with you!">
                                                        </textarea>
                                                    </div> : null
                        }
                        <div className='control-bar'>
                            <div className='progress-bar'></div>
                            <div className='progress-bar-start'></div>
                            {currentPageNumber === 2 ? <div className='progress-bar-start second'></div> : null}
                            {currentPageNumber === 3 ? <div className='progress-bar-start third'></div> : null}
                            {currentPageNumber === 4 ? <div className='progress-bar-start fourth'></div> : null}
                            {currentPageNumber === 5 ? <div className='progress-bar-start fifth'></div> : null}
                            {currentPageNumber === 6 ? <div className='progress-bar-start sixth'></div> : null}
                            <button onClick={() => {
                                if (!isBackButtonDisabled) {
                                    setCurrentPageNumber(currentPageNumber - 1)
                                    setIsNextButtonDisabled(false)
                                    currentPageNumber - 1 === 1 ? setIsBackButtonDisabled(true) : setIsBackButtonDisabled(false)
                                }
                            }} className={isBackButtonDisabled ? 'back-button disabled' : "back-button"}>Back</button>
                            <button onClick={() => {
                                if (!isNextButtonDisabled) {
                                    setCurrentPageNumber(currentPageNumber + 1)
                                    setIsBackButtonDisabled(false)
                                    setIsNextButtonDisabled(true)
                                    if (currentPageNumber + 1 === 3) {
                                        checkIfQuestionsAreAnswered()
                                    }
                                    if (currentPageNumber + 1 === 4 || currentPageNumber + 1 === 5 || currentPageNumber + 1 === 6) {
                                        setIsNextButtonDisabled(false)
                                    }
                                    if (currentPageNumber + 1 === 7) {
                                        handleSubmit()
                                    }
                                }
                            }} className={isNextButtonDisabled ? 'next-button disabled' : "next-button"}>{currentPageNumber === 6 ? "Finish" : "Next"}</button>
                        </div>
                    </div>
                </div>}
        </>
    )
}

export default CreateMentorProfile