// Make times an object with a unique id which is the time

export const timesArray = [
    {
        time: "12:00AM - 12:30AM", militaryTime: "00:00"
    },
    {
        time: "12:30AM - 1:00AM", militaryTime: "00:30"
    },
    {
        time: "1:00AM - 1:30AM", militaryTime: "01:00"
    },
    {
        time: "1:30AM - 2:00AM", militaryTime: "01:30"
    },
    {
        time: "2:00AM - 2:30AM", militaryTime: "02:00"
    },
    {
        time: "2:30AM - 3:00AM", militaryTime: "02:30"
    },
    {
        time: "3:00AM - 3:30AM", militaryTime: "03:00"
    },
    {
        time: "3:30AM - 4:00AM", militaryTime: "03:30"
    },
    {
        time: "4:00AM - 4:30AM", militaryTime: "04:00"
    },
    {
        time: "4:30AM - 5:00AM", militaryTime: "04:30"
    },
    {
        time: "5:00AM - 5:30AM", militaryTime: "05:00"
    },
    {
        time: "5:30AM - 6:00AM", militaryTime: "05:30"
    },
    {
        time: "6:00AM - 6:30AM", militaryTime: "06:00"
    },
    {
        time: "6:30AM - 7:00AM", militaryTime: "06:30"
    },
    {
        time: "7:00AM - 7:30AM", militaryTime: "07:00"
    },
    {
        time: "7:30AM - 8:00AM", militaryTime: "07:30"
    },
    {
        time: "8:00AM - 8:30AM", militaryTime: "08:00"
    },
    {
        time: "8:30AM - 9:00AM", militaryTime: "08:30"
    },
    {
        time: "9:00AM - 9:30AM", militaryTime: "09:00"
    },
    {
        time: "9:30AM - 10:00AM", militaryTime: "09:30"
    },
    {
        time: "10:00AM - 10:30AM", militaryTime: "10:00"
    },
    {
        time: "10:30AM - 11:00AM", militaryTime: "10:30"
    },
    {
        time: "11:00AM - 11:30AM", militaryTime: "11:00"
    },
    {
        time: "11:30AM - 12:00PM", militaryTime: "11:30"
    },
    {
        time: "12:00PM - 12:30PM", militaryTime: "12:00"
    },
    {
        time: "12:30PM - 1:00PM", militaryTime: "12:30"
    },
    {
        time: "1:00PM - 1:30PM", militaryTime: "13:00"
    },
    {
        time: "1:30PM - 2:00PM", militaryTime: "13:30"
    },
    {
        time: "2:00PM - 2:30PM", militaryTime: "14:00"
    },
    {
        time: "2:30PM - 3:00PM", militaryTime: "14:30"
    },
    {
        time: "3:00PM - 3:30PM", militaryTime: "15:00"
    },
    {
        time: "3:30PM - 4:00PM", militaryTime: "15:30"
    },
    {
        time: "4:00PM - 4:30PM", militaryTime: "16:00"
    },
    {
        time: "4:30PM - 5:00PM", militaryTime: "16:30"
    },
    {
        time: "5:00PM - 5:30PM", militaryTime: "17:00"
    },
    {
        time: "5:30PM - 6:00PM", militaryTime: "17:30"
    },
    {
        time: "6:00PM - 6:30PM", militaryTime: "18:00"
    },
    {
        time: "6:30PM - 7:00PM", militaryTime: "18:30"
    },
    {
        time: "7:00PM - 7:30PM", militaryTime: "19:00"
    },
    {
        time: "7:30PM - 8:00PM", militaryTime: "19:30"
    },
    {
        time: "8:00PM - 8:30PM", militaryTime: "20:00"
    },
    {
        time: "8:30PM - 9:00PM", militaryTime: "20:30"
    },
    {
        time: "9:00PM - 9:30PM", militaryTime: "21:00"
    },
    {
        time: "9:30PM - 10:00PM", militaryTime: "21:30"
    },
    {
        time: "10:00PM - 10:30PM", militaryTime: "22:00"
    },
    {
        time: "10:30PM - 11:00PM", militaryTime: "22:30"
    },
    {
        time: "11:00PM - 11:30PM", militaryTime: "23:00"
    },
    {
        time: "11:30PM - 12:00AM", militaryTime: "23:30"
    },

]