import React from 'react'
import Navbar from '../../Navbar'
import Footer from '../../Footer'
import "./index.css"

const InfoMeeting = () => {

    return (
        <>
            <Navbar />
            <div className='info-meeting-container'>
                <p className='meetings-header'>Info: Meetings</p>
                <p className='meetings-question'>What is a W.I.L.T. meeting?</p>
                <p className='meetings-answer'>
                    We're so glad you asked! A W.I.L.T. meeting is a thirty-minute one-on-one video chat with a mentor (current Michigan student) who will share their experiences and offer their advice
                    about being a University of Michigan student. Mentors will speak about the degree they are pursuing, activities and clubs they are involved in on campus,
                    adjusting to life at Michigan coming out of high school, and more! Each of our mentors has a unique academic and personal background
                    that they are excited to share with you and want you to ask them about!
                </p>
                <p className='meetings-question'>Why do I need to select three meeting times?</p>
                <p className='meetings-answer'>
                    Even though you are selecting three meeting times, only one of them will be chosen by your mentor as the official meeting time. The process of proposing three meeting times
                    allows you to select your preferred availability while giving your mentor multiple options that could fit into their busy schedule.
                </p>
                <p className='meetings-question'>Should I prepare questions for the meeting?</p>
                <p className='meetings-answer'>
                    While mentors expect and encourage you to ask them questions during the meeting, you are by no means required to prepare questions.
                    If you would like to listen to the mentor speak about their experience for thirty minutes, that is completely fine! If you want to ask thirty minutes
                    worth of questions, that is fine too! And if the mentor is talking and a question pops into your head, feel free to jump in and ask it before you forget!
                </p>
                <p className='meetings-question'>Do I need to have my camera on for the meeting?</p>
                <p className='meetings-answer'>
                    No, you are not required to have your camera on during the meeting, but we strongly encourage you to do so! The meeting is intended to be a conversation
                    between you and the mentor which is much easier when you can see each others' faces!
                </p>
                <p className='meetings-question'>What kinds of questions can I ask in the meeting</p>
                <p className='meetings-answer'>
                    Where do we even begin? Ask mentors about their favorite class in their major, how to make friends during Freshman year, which dining hall has the best food,
                    how they got their Summer internship, how they chose their major despite not knowing what to major in Freshman year, how they found their Freshman roommate,
                    how they switched roommates after having a bad roommate experience, why they applied for a minor in Business, why they studied abroad, why they double majored in Data Science,
                    why they dropped out of pre-med, why they decided to stick with pre-med, why living in a house is better than an apartment, if taking the bus to North Campus is easy, if joining a Frat is worth it,
                    if going to basketball games are better than going to football games, if playing club sports are a good idea, if going to office hours is actually helpful, if Michigan is really as cold as people say it is (yes)...
                </p>
                <p className='meetings-question'>Who can book a meeting?</p>
                <p className='meetings-answer'>
                    While meetings are intended for high school students of all grades, anyone can book a meeting! Whether you're a student who wants to transfer to Michigan or a parent of a high school student who wants to find out more about Michigan, feel free to book a meeting!
                    If you are a current Umich student and want to speak to another Umich student, feel free to book a meeting as well!
                </p>
                <p className='meetings-question'>Can I have friends or family members sit/listen in on the meeting with me?</p>
                <p className='meetings-answer'>
                    Yes! If Mom, Dad, and little brother Joey want to listen in on the meeting, join right in! And if they have questions, they can ask too!
                </p>
            </div>
            <Footer />
        </>
    )
}

export default InfoMeeting