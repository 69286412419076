import firebase from 'firebase/compat/app';
const { API_ROOT_URL } = require("../../../configs/activebuildconfig.json")



/**
 * CONTROLLER - mentor
 * FUNCTION - updatePayment
 * VERIFY - Mentor who is authenticated on the frontend (Currently with google)
 * @param {*} paymentData - Update info for mentor payment
 * @param {*} callback callback function that's takes in the result from the endpoint
 * @returns Updated user with updated payment info
 */

export const updatePayment = async (paymentData, callback) => {
    firebase.auth().onAuthStateChanged(async mentor => {
        const token = await mentor.getIdToken(false);
        const response = await fetch(`${API_ROOT_URL}/mentor/update_payment`, {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-type": "application/json",
            },
            body: JSON.stringify({
                token,
                paymentData
            }),
        })
        const result = await response.json();
        callback(result)
    })
}

