const areDatesEqual = (d1, d2) => {
    const date1 = new Date(d1)

    const date2 = new Date(d2)

    if (date1.getYear() === date2.getYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate()) {
        return true
    }

    return false
}

export default areDatesEqual