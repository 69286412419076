


import React, { useContext } from "react"
import "./index.css"
import signUpWithGoogle from "../../utils/signUp"
import { Link, useLocation } from "react-router-dom"
import { ContextProvider } from '../../context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faFacebook, faInstagram, faYoutube, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faCopyright } from "@fortawesome/pro-light-svg-icons";

const Footer = () => {

    const { user, setIsLoading } = useContext(ContextProvider)

    const location = useLocation()

    const isOnMentorRoute = location.pathname.split("/")[1] === "mentor"

    return (
        <>
            <div className="footer-container">
                <div className="footer-shape">

                </div>
                <p className="footer-slogan">Welcome to the new way to choose a college</p>
                <div className="flex footer-buttons">
                    {user.isUserAuthenticated ?
                        <button onClick={() => isOnMentorRoute ? window.location.href = "/mentor/profile" : window.location.href = "/user/profile"}
                            className="footer-button-1">My profile</button> : <button onClick={() => { signUpWithGoogle(user, "user"); localStorage.setItem('signUpType', 'user'); setIsLoading(true) }} className="footer-button-1">
                            Sign Up
                        </button>}
                    {user.isUserAuthenticated ?
                        <button onClick={() => isOnMentorRoute ? window.location.href = "/mentor/meetings" : window.location.href = "/user/meetings"}
                            className="footer-button-2">My meetings</button> : <button className="footer-button-2">
                            Become a Mentor
                        </button>}
                </div>
            </div>
            <p className="sponsor-text">W.I.L.T. is not sponsored, endorsed, or affiliated with the University of Michigan</p>
            <div className="footer-details">
                <div>
                    <p className="footer-details-header">Product</p>
                    <Link style={{ textDecoration: "none" }} to="/about-us"><p className="footer-details-list">Features</p></Link>
                    <Link style={{ textDecoration: "none" }} to="/about-us"><p className="footer-details-list">Enterprise</p></Link>
                    <Link style={{ textDecoration: "none" }} to="/about-us"><p className="footer-details-list">Solutions</p></Link>
                    <Link style={{ textDecoration: "none" }} to="/about-us"><p className="footer-details-list">Releases</p></Link>
                </div>
                <div>
                    <p className="footer-details-header">Pricing</p>
                    <Link style={{ textDecoration: "none" }} to="/about-us"><p className="footer-details-list">Plans</p></Link>
                    <Link style={{ textDecoration: "none" }} to="/about-us"><p className="footer-details-list">Free Version</p></Link>
                    <Link style={{ textDecoration: "none" }} to="/about-us"><p className="footer-details-list">Enterprise Pricing</p></Link>
                </div>
                <div>
                    <p className="footer-details-header">Resources</p>
                    <Link style={{ textDecoration: "none" }} to="/about-us"><p className="footer-details-list">Partners</p></Link>
                    <Link style={{ textDecoration: "none" }} to="/about-us"><p className="footer-details-list">Developers</p></Link>
                    <Link style={{ textDecoration: "none" }} to="/about-us"><p className="footer-details-list">Community</p></Link>
                    <Link style={{ textDecoration: "none" }} to="/about-us"><p className="footer-details-list">Help Center</p></Link>
                    <Link style={{ textDecoration: "none" }} to="/about-us"><p className="footer-details-list">Events</p></Link>
                    <Link style={{ textDecoration: "none" }} to="/about-us"><p className="footer-details-list">Blog</p></Link>
                </div>
                <div>
                    <p className="footer-details-header">Company</p>
                    <Link style={{ textDecoration: "none" }} to="/about-us"><p className="footer-details-list">About us</p></Link>
                    <Link style={{ textDecoration: "none" }} to="/about-us"><p className="footer-details-list">News</p></Link>
                    <Link style={{ textDecoration: "none" }} to="/about-us"><p className="footer-details-list">Careers</p></Link>
                    <Link style={{ textDecoration: "none" }} to="/about-us"><p className="footer-details-list">Investors</p></Link>
                    <Link style={{ textDecoration: "none" }} to="/terms-and-conditions"><p className="footer-details-list">Terms and conditions</p></Link>


                </div>
            </div>
            <hr className="footer-horizontal-line" />
            <div className="flex-2 footer-bottom-bar-container">
                <div className="flex-2">
                    <p className="footer-bottom-bar">Status</p>
                    <p className="footer-bottom-bar">Privacy</p>
                    <p className="footer-bottom-bar">Terms</p>
                    <p className="footer-bottom-bar">Cookie Preferences</p>
                    <p className="footer-bottom-bar">Contact Us</p>
                    <p className="footer-bottom-bar">Change Region</p>
                </div>
                <div className="flex-3">
                    <p className="footer-bottom-bar">Connect with W.I.L.T.</p>
                    <FontAwesomeIcon className="social-media-icon" icon={faTwitter} />
                    <FontAwesomeIcon className="social-media-icon" icon={faFacebook} />
                    <FontAwesomeIcon className="social-media-icon" icon={faInstagram} />
                    <FontAwesomeIcon className="social-media-icon" icon={faYoutube} />
                    <FontAwesomeIcon className="social-media-icon" icon={faLinkedin} />
                </div>
            </div>
            <div className="flex-4 footer-copyright-notice">
                {/* <FontAwesomeIcon className="copy-right-icon" icon={faCopyright} /> */}
                <p className="copyright-notice">2022 W.I.L.T. Technologies. All rights reserved.</p>

            </div>
        </>
    )
}

export default Footer;