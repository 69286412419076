import { signUpUser } from "../API/api";
import { signUpMentor } from "../API/api";
import { getAdditionalUserInfo } from "firebase/auth"
import { createMeetingRequest } from "../API/api";
import { userInterestedInMeetingWithMentor } from "../API/api";
import { addForumMessageUser } from "../API/api";

const handleUserSignUp = async (result, userType = "user") => {
    const name = result.user.displayName;
    const email = result.user.email

    // If this is a new user send them to the sign in page
    const { isNewUser } = getAdditionalUserInfo(result)

    if (isNewUser) {
        // If we have a new user, then sign them up as a new user
        const result = await signUpUser({ name, email })
        // If the user was successfully signed up and the user only wants to be a user
        // Then we are done and return that user signup was successful
        if (result.success) {
            if (userType === "user") {

                // If the user has a umich email, send them directly to the mentor sign up page
                // Cause that's probably where they meant to go anyway
                if (email.endsWith("@umich.edu")) {

                    signUpMentor({ name, email }, (result) => {

                        // If the mentor sign up is successful, then send the mentor to the mentor sign up page
                        if (result.success) {
                            localStorage.setItem('hasUserAuthenticated', 'true');
                            window.location.href = "/mentor/signup"
                        }
                    })
                }
                else {
                    // Send the user to the homepage and scroll them to the mentors grid
                    localStorage.setItem('hasUserAuthenticated', 'true');
                    window.location.href = "/?view=mentors"
                }
            }
            // If the user is trying to sign up as they are booking a meeting
            else if (userType === "userMeetingRequest") {
                const mentorId = localStorage.getItem('mentorId');
                const selectedDays = localStorage.getItem('selectedDays');
                createMeetingRequest(JSON.parse(selectedDays), mentorId, (result) => {
                    if (result.success) {
                        localStorage.setItem('signUpType', '');
                        localStorage.setItem('hasUserAuthenticated', 'true');
                        window.location.href = `/meeting/booked/${result.data.meetingId}`
                    }
                })
            }
            // If the user is trying to sign up as they 
            // are saying their interested in booking a meeting
            else if (userType === "userInterestedInMeeting") {
                const mentorId = localStorage.getItem('mentorId');
                const data = { userName: name, userEmail: email, mentorId }
                userInterestedInMeetingWithMentor(data, (result) => {
                    if (result.success) {
                        localStorage.setItem('signUpType', '');
                        localStorage.setItem('hasUserAuthenticated', 'true');
                        window.location.href = `/mentors/${mentorId}`
                    }
                })
            }
            // If the user is trying to make a post in the forum
            else if (userType === "userForumPost") {
                const messageObject = localStorage.getItem('messageObject');
                addForumMessageUser(JSON.parse(messageObject), (result) => {
                    if (result.success) {
                        localStorage.setItem('signUpType', '');
                        localStorage.setItem('hasUserAuthenticated', 'true');
                    }
                })
            }
            // If the user is trying to make a post in the forum on their phone
            else if (userType === "userForumPostOnMobile") {
                localStorage.setItem('hasUserAuthenticated', 'true');
                window.location.href = "/?view=forum"
            }
            // Otherwise, if the user is also trying to sign up as a mentor then sign them up as a mentor
            else {

                // Only allow emails that are @umich.edu to sign up
                if (!email.endsWith("@umich.edu")) {
                    localStorage.setItem('hasUserAuthenticated', 'true');
                    window.location.href = "/email/error"
                    return
                }

                signUpMentor({ name, email }, (result) => {

                    // If the mentor sign up is successful, then send the mentor to the mentor sign up page
                    if (result.success) {
                        localStorage.setItem('hasUserAuthenticated', 'true');
                        window.location.href = "/mentor/signup"
                    }
                })
            }
        }
    }
    // If we don't have a new user, then no sign up call to the backend is required
    else {
        if (userType === "user") {
            localStorage.setItem('hasUserAuthenticated', 'true');
            return
        }
        // If the user is trying to sign up as they are booking a meeting
        else if (userType === "userMeetingRequest") {
            const mentorId = localStorage.getItem('mentorId');
            const selectedDays = localStorage.getItem('selectedDays');
            createMeetingRequest(JSON.parse(selectedDays), mentorId, (result) => {
                if (result.success) {
                    localStorage.setItem('signUpType', '');
                    localStorage.setItem('hasUserAuthenticated', 'true');
                    window.location.href = `/meeting/booked/${result.data.meetingId}`
                }
            })
        }
        else if (userType === "userInterestedInMeeting") {
            const mentorId = localStorage.getItem('mentorId');
            const data = { userName: name, userEmail: email, mentorId }
            userInterestedInMeetingWithMentor(data, (result) => {
                if (result.success) {
                    localStorage.setItem('signUpType', '');
                    localStorage.setItem('hasUserAuthenticated', 'true');
                    window.location.href = `/mentors/${mentorId}`
                }
            })
        }
        else if (userType === "userForumPost") {
            const messageObject = localStorage.getItem('messageObject');
            addForumMessageUser(JSON.parse(messageObject), (result) => {
                if (result.success) {
                    localStorage.setItem('signUpType', '');
                    localStorage.setItem('hasUserAuthenticated', 'true');
                }
            })
        }
        // If the user is trying to make a post in the forum on their phone
        else if (userType === "userForumPostOnMobile") {
            localStorage.setItem('hasUserAuthenticated', 'true');
            window.location.href = "/?view=forum"
        }
        else {

            // Don't allow users that are non umich to get to the mentor page
            if (!email.endsWith("@umich.edu")) {
                localStorage.setItem('hasUserAuthenticated', 'true');
                window.location.href = "/email/error"
                return
            }
            localStorage.setItem('hasUserAuthenticated', 'true');
            window.location.href = '/mentor'
        }
    }
}

export default handleUserSignUp