import React, { useState, useEffect, useContext, useRef } from "react"
import { useNavigate, createSearchParams, useLocation, Link } from "react-router-dom"
import { ContextProvider } from '../../context';
import "./index.css"
import Navbar from "../Navbar"
import demo from "../../utils/demo.mov"
import Mentor from "../Mentor"
import Footer from "../Footer"
import Loading from "../Loading"
import signUpWithGoogle from "../../utils/signUp"
import LoadingCards from "../LoadingCard"
import filterMentorsByMajor from "../../utils/filterMentorsByMajor";
import filterMentorsByMinor from "../../utils/filterMentorsByMinor";
import filterMentorsBySchool from "../../utils/filterMentorsBySchool";
import { getMentors } from "../../API/controllers/mentor/getMentors"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons";
import Forum from "../Forum";
import MajorFilter from "../MajorFilter";
import MinorFilter from "../MinorFilter";
import SchoolFilter from "../SchoolFilter";

const Homepage = () => {

    const { user, isLoading, setIsLoading } = useContext(ContextProvider)

    const [filterSelected, setFilterSelected] = useState()

    const [mentorData, setMentorData] = useState([])

    const [width, setWidth] = useState(document.body.clientWidth);

    const [tabSelected, setTabSelected] = useState("meeting")

    const [filterTab, setFilterTab] = useState("major")

    const navigate = useNavigate()

    const search = useLocation().search;

    const view = new URLSearchParams(search).get('view');

    const filter = new URLSearchParams(search).get('filter');

    const mentorGridRef = useRef()

    const forumRef = useRef()


    useEffect(() => {
        // Call the function that will get the Mentor data from the backend     
        getMentorData();
    }, []);

    useEffect(() => {
        if (view === "mentors") {
            mentorGridRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
        }
        else if (view === "forum") {
            forumRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
        }
    }, [mentorData, view, tabSelected])

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    useEffect(() => {
        const tab = localStorage.getItem('tab', 'forum');
        if (tab === "forum") {
            setTabSelected("forum")
        }
        localStorage.setItem('tab', "");
    }, [])

    const handleWindowSizeChange = () => {
        setWidth(document.body.clientWidth)
    }

    const getMentorData = async () => {
        // This will get the data from the backend	
        const response = await getMentors();
        // If the response was not successful, show an error
        if (!response.success) {
            console.log("Error in fetching mentors")
        }
        else {
            // Once we have the data, put it in state	
            setMentorData(response.data);
        }
    };

    const handleSelectFilter = (id) => {
        if (id === filterSelected) {
            setFilterSelected("")
            navigate({
                pathname: window.location.pathaname,
            })
        }
        else {
            setFilterSelected(id)
            navigate({
                pathname: window.location.pathaname,
                search: createSearchParams({
                    filter: id
                }).toString()
            })
        }
    }

    const filterMentorsBy = (mentor) => {
        if (filterTab === "major") {
            return filterMentorsByMajor(filter, mentor.mentorBio.majors)
        }
        else if (filterTab === "minor") {
            return filterMentorsByMinor(filter, mentor.mentorBio.minors)
        }
        else {
            return filterMentorsBySchool(filter, mentor.mentorBio.schools)
        }
    }

    return isLoading ? <Loading />
        : (
            <div>
                <Navbar />
                <div className="homepage-container">
                    <div className="homepage-top-container">
                        <div>
                            {width <= 430 ? <p className="header-text">Speak to a <br /> <span className="mentor">Student</span> from the <br></br>University of <span className="mentor">Michigan</span></p>
                                : <p className="header-text">Speak to a <span className="mentor">Student</span> from <br></br> University of <span className="mentor">Michigan</span></p>}
                            {width <= 430 ? <p className="slogan-text">Discover what Michigan is really like from students <br /> who go there right now. Choose a student. <br /> Meet on Zoom. Get information that's tailored <br /> to you.</p>
                                : <p className="slogan-text">Discover what Michigan is really like from students who go there right now. <br></br>
                                    Choose a student. Meet on Zoom. Get information that's tailored to you.</p>}
                            {user.isUserAuthenticated ? <button onClick={() => window.location.href = "/user/meetings"} className="get-started-button">My Meetings</button>
                                : <button onClick={() => mentorGridRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })} className="get-started-button">Book a Meeting</button>}
                            {user.isUserAuthenticated ? <button onClick={() => mentorGridRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })} className="sign-up-button">Book a Meeting</button> : <button onClick={() => { signUpWithGoogle(user, "user"); localStorage.setItem('signUpType', 'user'); setIsLoading(true) }} className="sign-up-button">Login or Sign up</button>}
                            <div className="become-a-mentor">
                                <p className="mentor-text">Are you a Umich student?</p>
                                <button onClick={() => { signUpWithGoogle(user, "mentor"); localStorage.setItem('signUpType', 'mentor'); setIsLoading(true) }} className="become-a-mentor-button">{user?.isAlsoMentor || user?.mentorBio ? "Switch to Mentor" : "Become a Mentor"}</button>
                            </div>
                        </div>
                        {width > 430 ? <div>
                            <video defaultMuted playsinline autoPlay="autoplay" loop="loop" muted className="demo-video" src={demo}></video>
                        </div>
                            : null
                        }
                    </div>
                </div>
                <div ref={mentorGridRef} className="mentors-container">
                    {tabSelected === "meeting" ?
                        <>
                            <div className="filter-header">
                                <br></br>
                                <div className="mentor-header-container">
                                    <p className="mentor-header-text">Find students by: </p>
                                    <div className="filter flex">
                                        <select onChange={(e) => {
                                            setFilterTab(e.target.value); setFilterSelected(""); navigate({
                                                pathname: window.location.pathaname,
                                            })
                                        }} className="select" id="select">
                                            <option value="major">Major</option>
                                            <option value="minor">Minor</option>
                                            <option value="school">School</option>
                                        </select>
                                        <FontAwesomeIcon icon={faCaretDown} className="select-icon" />
                                    </div>
                                </div>
                                <div style={{ position: "absolute", width: "100vw" }}>
                                    <div className="outer-filter-container">
                                        <div className="filter-container">
                                            {filterTab === "major" ? <MajorFilter filterSelected={filterSelected} handleSelectFilter={handleSelectFilter} /> :
                                                filterTab === "minor" ? <MinorFilter filterSelected={filterSelected} handleSelectFilter={handleSelectFilter} /> :
                                                    filterTab === "school" ? <SchoolFilter filterSelected={filterSelected} handleSelectFilter={handleSelectFilter} /> : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        : null
                    }
                    <div className="meeting-forum-flex">
                        <Link style={{ textDecoration: "none", color: "black" }} to="/?view=mentors">
                            <p
                                className={tabSelected === "meeting" ? "homepage-tab-selected" : "homepage-tab"}
                                onClick={() => setTabSelected("meeting")}>
                                Book a Meeting
                            </p>
                        </Link>
                        <Link style={{ textDecoration: "none", color: "black" }} to="/?view=forum">
                            <p
                                className={tabSelected === "question" ? "homepage-tab-selected" : "homepage-tab"}
                                onClick={() => setTabSelected("question")}>
                                Ask a Question
                            </p>
                        </Link>
                    </div>
                    {
                        tabSelected === "meeting" ?
                            <>
                                <p className="select-mentor-instruction">Select a mentor to book a meeting</p>
                                <div className="mentors-grid">
                                    {/* mentors go here using loop */}
                                    {mentorData.length !== 0 ?
                                        mentorData.map((mentor) => {
                                            return mentor.isProfileCreationCompleted && filterMentorsBy(mentor) ?
                                                <div key={mentor.email} >
                                                    <Mentor
                                                        email={mentor.email}
                                                        image={mentor.image}
                                                        name={mentor.name}
                                                        id={mentor.id}
                                                        mentorBio={mentor.mentorBio}
                                                    ></Mentor>
                                                </div> : null
                                        }) :
                                        <LoadingCards />
                                    }
                                </div>
                            </> :
                            <>
                                <p ref={forumRef} className="select-mentor-instruction">Have your questions answered by Michigan students!</p>
                                <Forum />
                            </>
                    }
                    <Footer />
                </div>
            </div>
        )
}

export default Homepage
