import React, { useContext, useEffect } from 'react'
import firebase from 'firebase/compat/app';
import { ContextProvider } from '../context/index';
import { useNavigate } from "react-router-dom"
import signUpWithGoogle from "./signUp"
import Loading from "../Components/Loading/index"


const PrivateRouteMentor = ({ children }) => {

    const { user, setIsLoading } = useContext(ContextProvider)


    const currentUser = firebase.auth().currentUser;

    if (currentUser) {
        user.isUserAuthenticated = true
    }


    const navigate = useNavigate()

    const handleAuthenticateUser = () => {
        signUpWithGoogle(user, "mentor");
        setIsLoading(true)
        localStorage.setItem('signUpType', 'mentor')
        return null;
    }

    useEffect(() => {
        if (user?.email && !user?.email?.endsWith("@umich.edu")) {
            window.location.href = "/email/error"
        }

        if (user?.isUserAuthenticated && !user.isSignUpCompleted) {
            navigate('/mentor/signup')
        }
        else if (user?.isUserAuthenticated === false) {
            handleAuthenticateUser()
        }
    }, [user])


    // If the user is not authenticated, send them to the sign up with google page
    // If the user is authenticated, but their sign up is not complete, send them to the sign up page
    // If the user is authenticated and their sign up is completed, send them to wherever they were going
    // Otherwise, display loading
    return user?.isUserAuthenticated && user?.isSignUpCompleted ? children
        : <Loading />
}


export default PrivateRouteMentor
