import React, { useContext, useRef, useState, useEffect } from 'react'
import Navbar from '../Navbar'
import Footer from '../Footer'
import { ContextProvider } from '../../context';
import formatDate from '../../utils/formatDate';
import formatTime from '../../utils/formatTime'
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faXmark, faCheckCircle
} from "@fortawesome/pro-solid-svg-icons";
import { getEST } from '../../API/api';
import { acceptMeeting } from '../../API/api';
import { cancelMeetingMentor } from '../../API/api';
import { declineMeeting } from '../../API/api';
import "./index.css"

const MentorMeetings = () => {

    const { user, setUser } = useContext(ContextProvider)

    const [isCannotJoinMeetingModalShown, setIsCannotJoinMeetingModalShown] = useState(false)

    const [meetings, setMeetings] = useState(user.meetings)

    const [isMeetingCancelled, setIsMeetingCancelled] = useState(false)

    const [isMeetingDeclined, setIsMeetingDeclined] = useState(false)

    const [isCannotBeCanceledShown, setIsCannotBeCanceledShown] = useState(false)

    const cannotJoinModalRef = useRef()

    const cannotCancelRef = useRef()


    useEffect(() => {
        let closeCannotJoinModalRef = (event) => {
            if (!cannotJoinModalRef?.current.contains(event.target)) {
                setIsCannotJoinMeetingModalShown(false)
            }
        }
        let cannotCancel = (event) => {
            if (!cannotCancelRef?.current.contains(event.target)) {
                setIsCannotBeCanceledShown(false)
            }
        }

        document.addEventListener("mousedown", closeCannotJoinModalRef);
        document.addEventListener("mousedown", cannotCancel);
        return () => {
            document.removeEventListener("mousedown", closeCannotJoinModalRef);
            document.removeEventListener("mousedown", cannotCancel);
        }

    }, [])

    const canMeetingBeCanceled = async (meeting) => {
        const result = await getEST()
        if (new Date(result.data.datetime) > new Date(meeting.startTime)) {
            return false
        }
        return true
    }

    const isMeetingWithinFiveMinutesOfStarting = async (meetingStartTime) => {
        const currentEstTime = await getEST()
        const isLessThanFive = new Date(meetingStartTime) - new Date(currentEstTime.data.datetime)
        if (isLessThanFive <= 300000) {
            return true
        }
        return false
    }

    const handleAcceptMeeting = async (meetingId) => {
        const result = await acceptMeeting(meetingId)

        // If the result is successful, change the meeting's
        // hasMentorAcceptedMeetingRequest field to true and update state
        if (result.success) {
            meetings.forEach(meeting => {
                if (meeting.id === meetingId) {
                    meeting.hasMentorAcceptedMeetingRequest = true
                }
            })
            setMeetings([...meetings])
            user.meetings = meetings
            setUser({ ...user })
        }
    }

    const handleCancelMeeting = (meeting) => {
        cancelMeetingMentor(meeting, (result) => {
            setMeetings(result.data.meetings)
            setIsMeetingCancelled(true)
            user.meetings = result.data.meetings
            user.calendarData = result.data.calendarData
            setUser({ ...user })
        })
    }

    const handleDeclineMeeting = async (meeting) => {
        const result = await declineMeeting(meeting.id)
        if (result.success) {
            setIsMeetingDeclined(true)
            setMeetings(result.data.meetings)
            user.meetings = result.data.meetings
            user.calendarData = result.data.calendarData
            setUser({ ...user })
        }
    }

    return (
        <>
            <Navbar />
            {isCannotJoinMeetingModalShown ?
                <div ref={cannotJoinModalRef} className='cannot-join-meeting-yet'>
                    <FontAwesomeIcon icon={faXmark} className="icon-x-mark" onClick={() => setIsCannotJoinMeetingModalShown(false)} />
                    <p className="cannot-join-meeting-header-text">Your meeting has not started yet</p>
                    <p className="cannot-join-meeting-sub-text">The join button will become active 5 minutes before your meeting begins!</p>
                </div>
                : null}
            {isCannotBeCanceledShown ?
                <div ref={cannotCancelRef} className='cannot-join-meeting-yet'>
                    <FontAwesomeIcon icon={faXmark} className="icon-x-mark" onClick={() => setIsCannotBeCanceledShown(false)} />
                    <p className="cannot-join-meeting-header-text">
                        This meeting has already started
                    </p>
                    <p className="cannot-join-meeting-sub-text">
                        If the meeting has already started it cannot be canceled.
                    </p>
                </div>
                : null}
            <div className={isCannotJoinMeetingModalShown || isCannotBeCanceledShown ? 'user-meetings-container blur-background' : 'user-meetings-container'}>
                <p className='my-meetings-header-text'>My Meetings</p>
                {isMeetingCancelled || isMeetingDeclined ? <div onClick={setTimeout(() => { setIsMeetingCancelled(false); setIsMeetingDeclined(false) }, 3000)} className="meeting-cancelled-success">
                    <p className="availability-saved">{isMeetingCancelled ? "Meeting Successfully Cancelled" : "Meeting Successfully Declined"}.</p>
                    <FontAwesomeIcon icon={faCheckCircle} className="circle-check-icon" />
                </div> : null}
                {user.meetings.length === 0 ?
                    <>
                        <p className='no-meetings-yet'>You aren't booked for any meetings yet.</p>
                        <Link to="/mentor/calendar">
                            <button className='no-meetings-book-meeting-button'>Manage availability</button>
                        </Link>
                    </>
                    : meetings.sort((a, b) => new Date(a.startTime) - new Date(b.startTime)).map(meeting => {
                        return (
                            <div className='meeting-box'>
                                <div className='personal-information-flex'>
                                    {meeting?.isCancelClicked ? <p className='meeting-with-name'>Are you sure you want to cancel?</p>
                                        : meeting?.isDeclineClicked ? <p className='meeting-with-name'>Are you sure you want to decline?</p>
                                            : <p className='meeting-with-name'>Meeting with {meeting?.userName}</p>}
                                    {meeting?.hasMentorAcceptedMeetingRequest ?
                                        meeting?.isCancelClicked ? null
                                            :
                                            <div className='meeting-accepted-flex'>
                                                <p className='meeting-with-name'>
                                                    Meeting Accepted
                                                </p>
                                                <FontAwesomeIcon icon={faCheckCircle} className="meeting-accepted-icon" />
                                            </div>
                                        :
                                        <p className='meeting-with-name accept-or-decline-text'>
                                            {meeting?.isDeclineClicked ? null : "Accept or decline your meeting"}
                                        </p>
                                    }
                                    {/* <p className='personal-information-edit'>Manage</p> */}
                                </div>
                                <div className='my-meetings-buttons-flex'>
                                    {meeting?.isCancelClicked ?
                                        <>
                                            <button onClick={() => {
                                                meeting.isCancelClicked = false;
                                                setMeetings([...meetings])
                                            }} className='my-meetings-join-button'>Back
                                            </button>

                                            <button onClick={() => {
                                                handleCancelMeeting(meeting)
                                                meeting.isMeetingBeingCancelled = true
                                                setMeetings([...meetings])
                                            }} className={meeting?.isMeetingBeingCancelled ? 'my-meetings-cancel-button-long-being-cancelled' : 'my-meetings-cancel-button-long'}>
                                                {meeting?.isMeetingBeingCancelled ? "Cancelling Meeting" : "Cancel Meeting"}
                                            </button>
                                        </>
                                        :
                                        meeting?.isDeclineClicked ?
                                            <>
                                                <button onClick={() => {
                                                    meeting.isDeclineClicked = false;
                                                    setMeetings([...meetings])
                                                }} className='my-meetings-join-button'>Back
                                                </button>
                                                <button onClick={() => {
                                                    handleDeclineMeeting(meeting)
                                                    meeting.isMeetingBeingDeclined = true
                                                    setMeetings([...meetings])
                                                }} className={meeting?.isMeetingBeingDeclined ? 'my-meetings-cancel-button-long-being-cancelled' : 'my-meetings-cancel-button-long'}>
                                                    {meeting?.isMeetingBeingDeclined ? "Declining Meeting" : "Decline Meeting"}
                                                </button>
                                            </>
                                            :
                                            <>
                                                {
                                                    meeting.hasMentorAcceptedMeetingRequest ?
                                                        <a target="_blank" href={`/meeting/join/mentor/${meeting.id}`} rel="noreferrer">
                                                            <button
                                                                className={meeting.hasMentorAcceptedMeetingRequest ? 'my-meetings-date-button' : "my-meetings-date-button three-meetings"}>
                                                                {formatDate(meeting.startTime)} @ {formatTime(meeting.startTime)} EST
                                                            </button>
                                                        </a> :
                                                        <a target="_blank" href={`/meeting/accept/${meeting.id}`} rel="noreferrer">
                                                            <button
                                                                className={meeting.hasMentorAcceptedMeetingRequest ? 'my-meetings-date-button' : 'my-meetings-date-button large-date'}>
                                                                <div style={{ marginTop: 10 }}>
                                                                    <p className='proposed-meeting-date'>{formatDate(meeting["0"].startTime)} @ {formatTime(meeting["0"].startTime)}</p>
                                                                    <p className='proposed-meeting-date'>{formatDate(meeting["1"].startTime)} @ {formatTime(meeting["1"].startTime)}</p>
                                                                    <p className='proposed-meeting-date'>{formatDate(meeting["2"].startTime)} @ {formatTime(meeting["2"].startTime)}</p>
                                                                </div>
                                                            </button>
                                                        </a>
                                                }

                                                {meeting?.hasMentorAcceptedMeetingRequest ?
                                                    <div className="accept-deny-button-flex">
                                                        <button onClick={async () => meeting.zoomLink && await isMeetingWithinFiveMinutesOfStarting(meeting?.startTime)
                                                            ? window.location.href = meeting.zoomLink : setIsCannotJoinMeetingModalShown(true)}
                                                            className='my-meetings-join-button'>
                                                            Join
                                                        </button>
                                                        <button className='my-meetings-cancel-button' onClick={async () => {
                                                            const result = await canMeetingBeCanceled(meeting)
                                                            if (result) {
                                                                meeting.isCancelClicked = true;
                                                                setMeetings([...meetings])
                                                            }
                                                            else {
                                                                setIsCannotBeCanceledShown(true)
                                                            }
                                                        }}>
                                                            Cancel
                                                        </button>
                                                    </div>
                                                    :
                                                    <div className={meeting.isDeclineClicked || meeting.hasMentorAcceptedMeetingRequest ? "accept-deny-button-flex" : "accept-deny-button-grid"}>
                                                        <a href={`/meeting/accept/${meeting.id}`} target="_blank" rel="noreferrer">
                                                            <button className={meeting?.isAcceptClicked ? 'my-meetings-accept-button disabled' : "my-meetings-accept-button"}>
                                                                Accept
                                                            </button>
                                                        </a>
                                                        <button className={meeting?.isAcceptClicked || meeting?.isConfirmDeclineClicked ? "my-meetings-deny-button disabled" : "my-meetings-deny-button"}
                                                            disabled={meeting?.isAcceptClicked || meeting?.isConfirmDeclineClicked} onClick={() => {
                                                                meeting.isDeclineClicked = true
                                                                setMeetings([...meetings])
                                                            }}>
                                                            {meeting?.isConfirmDeclineClicked ? "Declining..." : "Decline"}
                                                        </button>
                                                    </div>
                                                }
                                            </>
                                    }
                                </div>

                            </div>
                        )
                    })}
            </div>
            <Footer />
        </>
    )
}

export default MentorMeetings