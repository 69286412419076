
import firebase from 'firebase/compat/app';
const { API_ROOT_URL } = require("../../../configs/activebuildconfig.json");

/**
 * CONTROLLER - user
 * FUNCTION - getAuthenticatedUser
 * VERIFY - an authenticated user
 * @param token the token of the authenticated user
 * @returns The account data for the authenticated user
 */

export const getAuthenticatedUser = async (callback) => {
    firebase.auth().onAuthStateChanged(async user => {
        if (!user) {
            callback({ isUserAuthenticated: false })
            return;
        }
        const token = await user.getIdToken(false);
        const response = await fetch(`${API_ROOT_URL}/user/get_authenticated_user`, {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-type": "application/json",
            },
            body: JSON.stringify({
                token
            }),
        })
        const result = await response.json();
        if (result.success) {
            result.isUserAuthenticated = true
        }
        else {
            result.isUserAuthenticated = false
        }
        callback(result)
    })
}