import React from 'react'
import { faLaptopCode, faGear, faPaintBrushFine, faLandmarkDome, faUserTieHairLong, faHeadSideBrain, faUserDoctorHair, faFlask, faPlane, faUserNurseHairLong, faPersonChalkboard, faPen, faEarthAmericas } from "@fortawesome/pro-thin-svg-icons";
import {
    faLaptopCode as faLaptopCodeSolid, faGear as faGearSolid, faPaintBrushFine as faPaintBrushFineSolid, faLandmarkDome as faLandmarkDomeSolid,
    faUserTieHairLong as faUserTieHairLongSolid, faHeadSide as faHeadSideBrainSolid, faUserDoctorHair as faUserDoctorHairSolid, faFlask as faFlaskSolid, faPlane as faPlaneSolid, faUserNurseHairLong as faUserNurseHairLongSolid,
    faPersonChalkboard as faPersonChalkboardSolid, faPen as faPenSolid, faEarthAmericas as faEarthAmericasSolid
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
const MinorFilter = ({ filterSelected, handleSelectFilter }) => {
    return (
        <>
            <div className={filterSelected === "cs" ? "filter-icon-selected" : "filter-icon"} onClick={() => handleSelectFilter("cs")}>
                <FontAwesomeIcon icon={filterSelected === "cs" ? faLaptopCodeSolid : faLaptopCode} className="icon" />
                <p className={filterSelected === "cs" ? "filter-icon-text-selected" : "filter-icon-text"}>CS</p>
            </div>
            <div className={filterSelected === "writing" ? "filter-icon-selected" : "filter-icon"} onClick={() => handleSelectFilter("writing")}>
                <FontAwesomeIcon icon={filterSelected === "writing" ? faPenSolid : faPen} className="engineering-icon icon" />
                <p className={filterSelected === "writing" ? "filter-icon-text-selected" : "filter-icon-text"}>Writing</p>
            </div>
            <div className={filterSelected === "hist" ? "filter-icon-selected" : "filter-icon"} onClick={() => handleSelectFilter("hist")} >
                <FontAwesomeIcon icon={filterSelected === "hist" ? faLandmarkDomeSolid : faLandmarkDome} className="poli-sci-icon icon" />
                <p className={filterSelected === "hist" ? "filter-icon-text-selected" : "filter-icon-text"}>History</p>
            </div>
            <div className={filterSelected === "entre" ? "filter-icon-selected" : "filter-icon"} onClick={() => handleSelectFilter("entre")}>
                <FontAwesomeIcon icon={filterSelected === "entre" ? faUserTieHairLongSolid : faUserTieHairLong} className="business-icon icon entre-icon" />
                <p className={filterSelected === "entre" ? "filter-icon-text-selected" : "filter-icon-text"}>entrepreneurship</p>
            </div>
            <div className={filterSelected === "lang" ? "filter-icon-selected" : "filter-icon"} onClick={() => handleSelectFilter("lang")}>
                <FontAwesomeIcon icon={filterSelected === "lang" ? faEarthAmericasSolid : faEarthAmericas} className="icon psych-icon lang-minor" />
                <p className={filterSelected === "lang" ? "filter-icon-text-selected" : "filter-icon-text"}>Language</p>
            </div>
            <div className={filterSelected === "med" ? "filter-icon-selected" : "filter-icon"} onClick={() => handleSelectFilter("med")}>
                <FontAwesomeIcon icon={filterSelected === "med" ? faUserDoctorHairSolid : faUserDoctorHair} className="pre-med-icon icon" />
                <p className={filterSelected === "med" ? "filter-icon-text-selected" : "filter-icon-text"}>Pre-Med</p>
            </div>
            <div className={filterSelected === "aero" ? "filter-icon-selected" : "filter-icon"} onClick={() => handleSelectFilter("aero")} >
                <FontAwesomeIcon icon={filterSelected === "aero" ? faPlaneSolid : faPlane} className="aerospace-icon icon" />
                <p className={filterSelected === "aero" ? "filter-icon-text-selected" : "filter-icon-text"}>Aerospace</p>
            </div>
            <div className={filterSelected === "nurse" ? "filter-icon-selected" : "filter-icon"} onClick={() => handleSelectFilter("nurse")}>
                <FontAwesomeIcon icon={filterSelected === "nurse" ? faUserNurseHairLongSolid : faUserNurseHairLong} className="nursing-icon icon health-minor" />
                <p className={filterSelected === "nurse" ? "filter-icon-text-selected" : "filter-icon-text"}>Health</p>
            </div>
        </>
    )
}

export default MinorFilter