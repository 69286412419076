
const { API_ROOT_URL } = require("../../../configs/activebuildconfig.json")


/**
 * CONTROLLER - mentor
 * FUNCTION - getMentor
 * VERIFY - any user
 * @param {*} id The id of the mentor to be fetched
 * @returns Returns all the data associated with the mentor
 */

export const getMentor = async (id) => {
    const response = await fetch(`${API_ROOT_URL}/mentor/get_mentor`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-type": "application/json",
        },
        body: JSON.stringify({
            id
        })
    })
    const result = await response.json();
    return result;
}