import React, { createContext, useState, useEffect } from 'react'
import { getAuthenticatedUser, getAuthenticatedMentor } from '../API/api'
export const ContextProvider = createContext();

export const Context = ({ children }) => {

    const [searchQuery, setSearchQuery] = useState();

    const [user, setUser] = useState({ isUserAuthenticated: undefined });

    const [isLoading, setIsLoading] = useState(false)

    const fetchUserData = async () => {
        getAuthenticatedUser((result) => {
            if (result.isUserAuthenticated) {
                result.data.isUserAuthenticated = true
                setUser(result.data)

            }
            else {
                setUser({ isUserAuthenticated: false })

            }
        });
    }

    const fetchMentorData = async () => {
        getAuthenticatedMentor((result) => {
            if (result.isUserAuthenticated) {
                result.data.isUserAuthenticated = true
                setUser(result.data)

            }
            else {
                setUser({ isUserAuthenticated: false })

            }
        });
    }

    const escapeRegExpMatch = function (s) {
        return s.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
    };

    const isExactMatch = (str, match) => {
        return new RegExp(`\\b${escapeRegExpMatch(match)}\\b`).test(str)
    }

    useEffect(() => {
        const hasUserAuthenticated = localStorage.getItem('hasUserAuthenticated');
        if (hasUserAuthenticated === "true") {
            setIsLoading(false)
        }
        localStorage.setItem('hasUserAuthenticated', 'false');
    })

    useEffect(() => {
        if (isExactMatch(window.location.href, "mentor")) {
            fetchMentorData()
        }
        else {
            fetchUserData()
        }
    }, [])

    //Pass the user object to the context. 
    //Auth context will wrap around every component in app.js
    return (
        <ContextProvider.Provider value={{
            searchQuery, user, isLoading, setSearchQuery, setUser, fetchUserData, fetchMentorData, setIsLoading
        }}>
            {children}
        </ContextProvider.Provider>)
}