import signUpWithGoogle from "./signUp";
import { createMeetingRequest } from "../API/api";


const confirmMeetingRequest = (user, mentorId, selectedDays) => {
    // Add the currentDate to the selectedDay object

    // Get the day that the user clicked on
    selectedDays.forEach(day => {
        let selectedTime = {}
        Object.entries(day.chosenDay.times).forEach(([, time]) => {
            if (time?.isSelected) {
                selectedTime = time
            }
        })
        day.selectedTime = selectedTime
    })

    // If the user is authenticated, handle confirming their selected meeting
    if (user?.isUserAuthenticated) {
        createMeetingRequest(selectedDays, mentorId, (result) => {
            if (result.success) {
                window.location.href = `/meeting/booked/${result.data.meetingId}`
            }
        })
    }

    // Otherwise, if the user is not yet authenticated, authenticate them
    else {
        localStorage.setItem('signUpType', 'userMeetingRequest');
        localStorage.setItem('mentorId', mentorId);
        localStorage.setItem('selectedDays', JSON.stringify(selectedDays));
        signUpWithGoogle(user, "user")
    }
}

export default confirmMeetingRequest