import firebase from 'firebase/compat/app';
const { API_ROOT_URL } = require("../../../configs/activebuildconfig.json")


/**
 * CONTROLLER - mentor
 * FUNCTION - updateMentor
 * VERIFY - User who is authenticated on the frontend (Currently with google)
 * @param {*} mentorData data about the mentor including their profile picture, school, major, minor, clubs and intro message
 * @returns Returns nothing (besides success flag or error message)
 */

export const createProfile = async (profileData, callback) => {
    firebase.auth().onAuthStateChanged(async mentor => {
        const token = await mentor.getIdToken(false);
        const response = await fetch(`${API_ROOT_URL}/mentor/create_profile`, {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-type": "application/json",
            },
            body: JSON.stringify({
                token,
                profileData
            }),
        })
        const result = await response.json();
        callback(result)
    })
}