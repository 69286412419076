import React, { useEffect, useState, useRef } from "react"
import { getStorage, ref, getDownloadURL } from "firebase/storage"
import Navbar from "../Navbar"
import Calendar from "../Calendar"
import Footer from "../Footer"
import { getMentor } from "../../API/api"
import { useLocation } from "react-router-dom"
import Loading from "../Loading"
import {
    faMessages, faBookmark, faStarSharp, faBlockQuestion, faHouseBuilding, faSchool,
    faBuildingColumns, faDiploma, faGraduationCap, faUserGraduate, faPenToSquare, faXmark, faCheckCircle
} from "@fortawesome/pro-solid-svg-icons";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import states from "../../utils/states"
import majors from "../../utils/majors"
import minors from "../../utils/minors"
import months from "../../utils/months"
import years from "../../utils/years"
import "./index.css"
import { updateMentor } from "../../API/controllers/mentor"


const MentorProfile = () => {

    const location = useLocation();

    const [mentorProfile, setMentorProfile] = useState(null);

    const [image, setImage] = useState(null);

    const [isLoading, setIsLoading] = useState(true)

    const storage = getStorage();

    const colors = ["blue", "pink", "purple", "red", "orange"]

    const [width, setWidth] = useState(document.body.clientWidth);

    const [isEditNameModalShowing, setIsEditNameModalShowing] = useState(false)

    const [isEditNameSubmitDisabled, setIsEditNameSubmitDisabled] = useState(true)

    const [isEditNameError, setIsEditNameError] = useState(false)

    const [isBackgroundBlurred, setIsBackgroundBlurred] = useState(false)

    const [firstName, setFirstName] = useState("")

    const [lastName, setLastName] = useState("")

    const [headline, setHeadline] = useState("")

    const [state, setState] = useState("")

    const [year, setYear] = useState("")

    const [city, setCity] = useState("")

    const [isEditAboutShowing, setIsEditAboutShowing] = useState(false)

    const [about, setAbout] = useState("")

    const [aboutError, setAboutError] = useState(false)

    const [isAboutSubmitButtonDisabled, setIsAboutSubmitButtonDisabled] = useState(true)

    const [isEditProfileShowing, setIsEditProfileShowing] = useState(false)

    const [schools, setSchools] = useState([])

    const [mentorMajors, setMentorMajors] = useState([])

    const [mentorMinors, setMentorMinors] = useState([])

    const [freshmanDorm, setFreshmanDorm] = useState("")

    const [highSchoolSize, setHighSchoolSize] = useState("")

    const [expectedGraduation, setExpectedGraduation] = useState("")

    const [isEditProfileSubmitDisabled, setIsEditProfileSubmitDisabled] = useState(true)

    const [isEditQuestionsShowing, setIsEditQuestionsShowing] = useState(false)

    const [questions, setQuestions] = useState([])

    const [isEditQuestionsSubmitDisabled, setIsEditQuestionsSubmitDisabled] = useState(true)

    const [isEditExperienceShowing, setIsEditExperienceShowing] = useState(false)

    const [experiences, setExperiences] = useState([])

    const [isEditExperienceSubmitDisabled, setIsEditExperiencesSubmitDisabled] = useState(true)

    const [editExperienceError, setEditExperienceError] = useState(false)

    const [isUpdateSuccessfulModalShowing, setIsUpdateSuccessfulModalShowing] = useState(false)

    const [isSaveChangesClicked, setIsSaveChangesClicked] = useState(false)

    const editNameRef = useRef()

    const aboutRef = useRef()

    const editProfileRef = useRef()

    const editQuestionsRef = useRef()

    const editExperienceRef = useRef()

    const updateSuccessfulRef = useRef()


    useEffect(() => {
        // Get the mentor's id from the query string
        const id = location.pathname.split("/")[3];

        // Call getMentor endpoint to get the mentor information
        fetchMentor(id);

        // Make sure the window starts at the top of the screen
        window.scrollTo(0, 0)

    }, [])

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    useEffect(() => {
        let highlightFirstNameInput = (event) => {
            if (!editNameRef?.current.contains(event.target)) {
                setIsEditNameModalShowing(false)
                setIsBackgroundBlurred(false)
                resetEditName()
            }
        }
        let aboutEdit = (event) => {
            if (!aboutRef?.current.contains(event.target)) {
                setIsEditAboutShowing(false)
                setIsBackgroundBlurred(false)
                resetEditAbout()
            }
        }

        let editProfile = (event) => {
            if (!editProfileRef?.current.contains(event.target)) {
                setIsEditProfileShowing(false)
                setIsBackgroundBlurred(false)
                resetEditProfile()
            }
        }

        let editQuestions = (event) => {
            if (!editQuestionsRef?.current.contains(event.target)) {
                setIsEditQuestionsShowing(false)
                setIsBackgroundBlurred(false)
                resetEditQuestions()
            }
        }

        let editExperience = (event) => {
            if (!editExperienceRef.current.contains(event.target)) {
                setIsEditExperienceShowing(false)
                setIsBackgroundBlurred(false)
                resetEditExperience()
            }
        }

        let updateSuccess = (event) => {
            if (!updateSuccessfulRef.current.contains(event.target)) {
                setIsUpdateSuccessfulModalShowing(false)
                setIsBackgroundBlurred(false)
            }
        }

        document.addEventListener("mousedown", highlightFirstNameInput);
        document.addEventListener("mousedown", aboutEdit);
        document.addEventListener("mousedown", editProfile);
        document.addEventListener("mousedown", editQuestions);
        document.addEventListener("mousedown", editExperience);
        document.addEventListener("mousedown", updateSuccess);
        return () => {
            document.removeEventListener("mousedown", highlightFirstNameInput);
            document.removeEventListener("mousedown", aboutEdit);
            document.removeEventListener("mousedown", editProfile);
            document.removeEventListener("mousedown", editQuestions);
            document.removeEventListener("mousedown", editExperience);
            document.removeEventListener("mousedown", updateSuccess);

        }

    }, [mentorProfile])

    const resetEditName = () => {
        setFirstName(mentorProfile?.name?.split(" ")[0])
        setLastName(mentorProfile?.name?.split(" ")[1])
        setHeadline(mentorProfile?.mentorBio?.headline ? mentorProfile?.mentorBio?.headline : `${mentorProfile?.mentorBio?.majors[0]} in the School of ${mentorProfile?.mentorBio?.schools[0]}`)
        setState(mentorProfile?.mentorBio?.state)
        setYear(mentorProfile?.mentorBio?.year)
        setCity(mentorProfile?.mentorBio?.city || "")
    }

    const resetEditAbout = () => {
        if (mentorProfile?.mentorBio?.introduction) {
            setAbout(mentorProfile?.mentorBio?.introduction)
        }
        else {
            setAbout(`Hi, I'm ${mentorProfile?.name.split(" ")[0]}! I'm a ${mentorProfile?.mentorBio?.year} majoring in ${mentorProfile?.mentorBio?.majors[0]} ${mentorProfile?.mentorBio?.minors[0] === "None" ? "in" : `and minoring in ${mentorProfile?.mentorBio?.minors[0]} in`} the School of ${mentorProfile?.mentorBio?.schools[0]}. Looking forward to speaking with you!`)
        }
    }

    const resetEditProfile = () => {
        setSchools(structuredClone(mentorProfile?.mentorBio?.schools))
        setMentorMajors(structuredClone(mentorProfile?.mentorBio?.majors))
        setMentorMinors(structuredClone(mentorProfile?.mentorBio?.minors))
        setFreshmanDorm(mentorProfile?.mentorBio?.dorm)
        setHighSchoolSize(mentorProfile?.mentorBio?.highSchoolSize)
        setExpectedGraduation(mentorProfile?.mentorBio?.graduationYear)
    }

    const resetEditExperience = () => {
        const clubs = structuredClone(mentorProfile?.mentorBio?.clubs)
        setExperiences(clubs)
    }

    const resetEditQuestions = () => {
        setQuestions(structuredClone(mentorProfile?.mentorBio?.askMeQuestions))
    }

    useEffect(() => {
        resetEditName()
        resetEditAbout()
        resetEditProfile()
        resetEditExperience()
        resetEditQuestions()
    }, [mentorProfile])

    const handleWindowSizeChange = () => {
        setWidth(document.body.clientWidth)
    }

    const fetchMentor = async (id) => {

        // This will get the data from the backend	
        const response = await getMentor(id);

        // If the response was not successfuly, show an error
        if (!response.success) {
            console.log("Error in fetching mentor")
        }
        else {
            // Once we have the data, put it in state
            setMentorProfile(response.data)
            setIsLoading(false)
        }

        const mentorImageRef = ref(storage, response.data.image)
        getDownloadURL(mentorImageRef).then((img) => {
            setImage(img)
        })
    }

    const returnHighSchoolSize = (size) => {
        if (size === "50") { return "1-50" }
        else if (size === "100") { return "50-100 students" }
        else if (size === "200") { return "100-200 students" }
        else if (size === "300") { return "200-300 students" }
        else if (size === "400") { return "300-400 students" }
        else if (size === "500") { return "400-500 students" }
        else if (size === "1000") { return "500-1000 students" }
        else if (size === "2000") { return "1000-2000 students" }
        else if (size === "3000") { return "2000+ students" }
    }

    const handleEditNameSubmit = () => {
        setIsSaveChangesClicked(true)
        setIsEditProfileSubmitDisabled(true)
        setIsEditNameSubmitDisabled(true)
        setIsEditQuestionsSubmitDisabled(true)
        setIsEditQuestionsSubmitDisabled(true)
        setIsAboutSubmitButtonDisabled(true)
        const mentorData = {
            firstName, lastName, headline, state, year, city,
            about,
            schools, mentorMajors, mentorMinors, freshmanDorm, highSchoolSize, expectedGraduation,
            experiences,
            questions
        }

        updateMentor(mentorData, (result) => {
            setMentorProfile(result.data)
            setIsEditNameModalShowing(false)
            setIsEditAboutShowing(false)
            setIsEditProfileShowing(false)
            setIsEditExperienceShowing(false)
            setIsEditQuestionsShowing(false)
            setIsUpdateSuccessfulModalShowing(true)
            setIsSaveChangesClicked(false)
            setIsEditProfileSubmitDisabled(false)
            setIsEditNameSubmitDisabled(false)
            setIsEditQuestionsSubmitDisabled(false)
            setIsEditQuestionsSubmitDisabled(false)
            setIsAboutSubmitButtonDisabled(false)
        })
    }

    const areSchoolsMinorAndMajorUnchanged = () => {
        let unchanged = true

        mentorMajors?.forEach((major, index) => {
            if (major !== mentorProfile?.mentorBio.majors[index]) {
                unchanged = false
            }
        })

        mentorMinors?.forEach((minor, index) => {
            if (minor !== mentorProfile?.mentorBio.minors[index]) {
                unchanged = false
            }
        })

        schools?.forEach((school, index) => {
            if (school !== mentorProfile?.mentorBio.schools[index]) {
                unchanged = false
            }
        })

        return unchanged
    }

    const areQuestionsUnchanged = () => {
        let unchanged = true
        questions?.forEach((question, index) => {
            if (question.text !== mentorProfile?.mentorBio.askMeQuestions[index].text) {
                unchanged = false
            }
        })

        return unchanged
    }

    const areExperiencesUnchanged = () => {
        let unchanged = true
        let error = false
        experiences?.forEach((experience, index) => {
            if (experience.club !== mentorProfile?.mentorBio.clubs[index].club) {
                unchanged = false
            }
            if (experience.title !== mentorProfile?.mentorBio.clubs[index].title) {
                unchanged = false
            }
            if (experience.startMonth !== mentorProfile?.mentorBio.clubs[index].startMonth) {
                unchanged = false
            }
            if (experience.startYear !== mentorProfile?.mentorBio.clubs[index].startYear) {
                unchanged = false
            }
            if (experience.endMonth !== mentorProfile?.mentorBio.clubs[index].endMonth) {
                unchanged = false
            }
            if (experience.endYear !== mentorProfile?.mentorBio.clubs[index].endYear) {
                unchanged = false
            }
            if (experience.description !== mentorProfile?.mentorBio.clubs[index].description) {
                unchanged = false
            }
        })
        if (error) {
            setEditExperienceError(true)
        }
        else {
            setEditExperienceError(false)
        }
        return unchanged
    }

    const isAboutUnchaged = () => {
        let unchanged = true

        if (mentorProfile?.mentorBio.introduction) {
            unchanged = about === mentorProfile?.mentorBio.introduction
        }

        else {
            unchanged = about === `Hi, I'm ${mentorProfile?.name.split(" ")[0]}! I'm a ${mentorProfile?.mentorBio?.year} majoring in ${mentorProfile?.mentorBio?.majors[0]} ${mentorProfile?.mentorBio?.minors[0] === "None" ? "in" : `and minoring in ${mentorProfile?.mentorBio?.minors[0]} in`} the School of ${mentorProfile?.mentorBio?.schools[0]}. Looking forward to speaking with you!`
        }

        return unchanged
    }

    useEffect(() => {
        if (firstName !== mentorProfile?.name?.split(" ")[0] || lastName !== mentorProfile?.name?.split(" ")[1] ||
            headline !== (mentorProfile?.mentorBio?.headline ? mentorProfile?.mentorBio?.headline : `${mentorProfile?.mentorBio?.majors[0]} in the School of ${mentorProfile?.mentorBio?.schools[0]}`) ||
            state !== mentorProfile?.mentorBio?.state || year !== mentorProfile?.mentorBio?.year || city !== mentorProfile?.mentorBio?.city) {
            setIsEditNameSubmitDisabled(false)
        }
        else if (firstName === mentorProfile?.name?.split(" ")[0] && lastName === mentorProfile?.name?.split(" ")[1] &&
            headline === `${mentorProfile?.mentorBio?.majors[0]} in the School of ${mentorProfile?.mentorBio?.schools[0]}` &&
            state === mentorProfile?.mentorBio?.state && year === mentorProfile?.mentorBio?.year && city === mentorProfile?.mentorBio?.city) {
            setIsEditNameSubmitDisabled(true)
        }

        if (!firstName || !lastName || !headline) {
            setIsEditNameSubmitDisabled(true)
            setIsEditNameError(true)
        }
        else {
            setIsEditNameError(false)
        }

        if (!isAboutUnchaged()) {
            setIsAboutSubmitButtonDisabled(false)
        }
        else if (isAboutUnchaged()) {
            setIsAboutSubmitButtonDisabled(true)
        }

        if (!about) {
            setIsAboutSubmitButtonDisabled(true)
            setAboutError(true)
        }
        else {
            setAboutError(false)
        }

        mentorMajors?.forEach((major, index) => {
            if (major !== mentorProfile?.mentorBio.majors[index]) {
                setIsEditProfileSubmitDisabled(false)
            }
        })

        mentorMinors?.forEach((minor, index) => {
            if (minor !== mentorProfile?.mentorBio.minors[index]) {
                setIsEditProfileSubmitDisabled(false)
            }
        })

        schools?.forEach((school, index) => {
            if (school !== mentorProfile?.mentorBio.schools[index]) {
                setIsEditProfileSubmitDisabled(false)
            }
        })

        if (freshmanDorm !== mentorProfile?.mentorBio?.dorm || highSchoolSize !== mentorProfile?.mentorBio?.highSchoolSize
            || expectedGraduation !== mentorProfile?.mentorBio?.graduationYear) {
            setIsEditProfileSubmitDisabled(false)
        }

        if (freshmanDorm === mentorProfile?.mentorBio?.dorm && highSchoolSize === mentorProfile?.mentorBio?.highSchoolSize
            && expectedGraduation === mentorProfile?.mentorBio?.graduationYear && areSchoolsMinorAndMajorUnchanged()) {
            setIsEditProfileSubmitDisabled(true)
        }

        if (areQuestionsUnchanged()) {
            setIsEditQuestionsSubmitDisabled(true)
        }
        else {
            setIsEditQuestionsSubmitDisabled(false)
        }

        if (areExperiencesUnchanged()) {
            setIsEditExperiencesSubmitDisabled(true)
        }
        else {
            setIsEditExperiencesSubmitDisabled(false)
        }



    }, [firstName, lastName, headline,
        state, year, about, schools, mentorMajors,
        mentorMinors, freshmanDorm, highSchoolSize, expectedGraduation, questions, experiences, city])

    return (
        isLoading ? <Loading />
            :
            <>
                <Navbar />
                {isUpdateSuccessfulModalShowing ?
                    <div ref={updateSuccessfulRef} className="edit-name-modal update-success-modal">
                        <FontAwesomeIcon icon={faXmark} className="icon-x-mark" onClick={() => { setIsUpdateSuccessfulModalShowing(false); setIsBackgroundBlurred(false); }} />
                        <p className="edit-header green">Edit Profile Success</p>
                        <p className="edit-profile-success-message">Your profile was successfully updated!</p>
                        <FontAwesomeIcon icon={faCheckCircle} className="check-circle-icon" />
                        <div>
                            <button onClick={() => {
                                setIsUpdateSuccessfulModalShowing(false); setIsBackgroundBlurred(false);
                            }}
                                className="close-update-success-button">
                                Close</button>
                        </div>
                    </div>
                    : null}
                {isEditNameModalShowing ?
                    <div ref={editNameRef} className="edit-name-modal">
                        <FontAwesomeIcon icon={faXmark} className="icon-x-mark" onClick={() => { setIsEditNameModalShowing(false); setIsBackgroundBlurred(false); resetEditName() }} />
                        <p className="edit-header">Edit Profile</p>
                        <div className="modal-content">
                            <p className="edit-field">Name</p>
                            <div className='info-boxes-flex'>
                                <div className={'name-container-personal-info active-border-info edit-name'}>
                                    <p className={'name-header'}>First name</p>
                                    <input placeholder="First Name" onChange={(e) => { setFirstName(e.target.value) }}
                                        value={firstName} className='name-input-info edit-name-text'></input>
                                </div>
                                <div className={'name-container-personal-info active-border-info edit-name'}>
                                    <p className={'name-header'}>Last name</p>
                                    <input placeholder="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)}
                                        className='name-input-info edit-name-text'></input>
                                </div>
                            </div>
                            <p className="edit-field">Headline</p>
                            <input maxLength="100" className="headline-input" onChange={(e) => setHeadline(e.target.value)} value={headline}></input>
                            <div className="grade-state-flex">
                                <div>
                                    <p className="edit-field">Year</p>
                                    <select onChange={(e) => setYear(e.target.value)} className="select-grade">
                                        <option selected disabled >{year}</option>
                                        <option >Freshman</option>
                                        <option >Sophomore</option>
                                        <option >Junior</option>
                                        <option >Senior</option>
                                        <option >Fifth Year</option>
                                        <option >Grad Student</option>
                                        <option >PhD Student</option>
                                    </select>
                                </div>
                                <div>
                                    <p className="edit-field">State</p>
                                    <select onChange={(e) => setState(e.target.value)} className="select-grade">
                                        <option selected disabled >{state}</option>
                                        {states.map(state => {
                                            return <option>{state}</option>
                                        })}
                                    </select>
                                </div>
                            </div>
                            <p className="edit-field">City</p>
                            <input maxLength="100" placeholder="Ex) Grand Rapids" className="city-input" onChange={(e) => setCity(e.target.value)} value={city}></input>
                        </div>
                        <div className="save-cancel-flex">
                            <button onClick={() => isEditNameSubmitDisabled ? null : handleEditNameSubmit()}
                                className={isEditNameSubmitDisabled ? "save-changes disabled" : isSaveChangesClicked ? "save-changes-clicked" : "save-changes"}>
                                {isSaveChangesClicked ? "Saving..." : "Save Changes"}</button>
                            <button onClick={() => { setIsEditNameModalShowing(false); setIsBackgroundBlurred(false); resetEditName() }} className="cancel-button">Cancel</button>
                            {isEditNameError ? <p className="edit-name-error">Field cannot be blank</p> : null}
                        </div>
                    </div>
                    : null}
                {isEditAboutShowing ?
                    <div ref={aboutRef} className="edit-name-modal">
                        <FontAwesomeIcon icon={faXmark} className="icon-x-mark" onClick={() => { setIsEditAboutShowing(false); setIsBackgroundBlurred(false); resetEditAbout() }} />
                        <p className="edit-header">Edit Profile</p>
                        <div className="modal-content">
                            <p className="edit-field">About</p>
                            <textarea maxLength="999" onChange={(e) => setAbout(e.target.value)} className="edit-about-text">
                                {about}
                            </textarea>
                        </div>
                        <div className="save-cancel-flex">
                            <button onClick={() => isAboutSubmitButtonDisabled ? null : handleEditNameSubmit()}
                                className={isAboutSubmitButtonDisabled ? "save-changes disabled" : isSaveChangesClicked ? "save-changes-clicked" : "save-changes"}>
                                {isSaveChangesClicked ? "Saving..." : "Save Changes"}</button>
                            <button onClick={() => { setIsEditAboutShowing(false); setIsBackgroundBlurred(false); resetEditAbout() }} className="cancel-button">Cancel</button>
                            {aboutError ? <p className="edit-name-error">Field cannot be blank</p> : null}
                        </div>
                    </div> : null}
                {isEditProfileShowing ?
                    <div ref={editProfileRef} className="edit-profile-modal">
                        <FontAwesomeIcon icon={faXmark} className="icon-x-mark" onClick={() => { setIsEditProfileShowing(false); setIsBackgroundBlurred(false); resetEditProfile() }} />
                        <p className="edit-header">Edit Profile</p>
                        <div className="modal-content">
                            <div className="edit-profile-grid">
                                <div>
                                    <p className="edit-field">{schools.length === 1 ? "School" : "Schools"}</p>
                                    {schools.map((_, index) => {
                                        return <select value={schools[index]} onChange={(e) => {
                                            schools[index] = e.target.value
                                            setSchools([...schools])
                                        }} className="select-grade">
                                            <option selected disabled >School</option>
                                            <option >LSA</option>
                                            <option >Business</option>
                                            <option >Engineering</option>
                                            <option >Nursing</option>
                                            <option >Kinesiology</option>
                                            <option >Education</option>
                                            <option >Art and Design</option>
                                            <option >Information</option>
                                            <option >Public Policy</option>
                                            <option >Public Health</option>
                                            <option >Social Work</option>
                                            <option >Medicine</option>
                                            <option >Pharmacy</option>
                                            <option >Dentistry</option>
                                            <option >Law</option>
                                            <option >Architecture and Urban Planning</option>
                                            <option >Environment and Sustainability</option>
                                            <option >Music, Theatre and Dance</option>
                                            <option >Rackham School of Graduate Studies</option>
                                        </select>
                                    })}
                                </div>
                                <div>
                                    <p className="edit-field">{mentorMajors.length === 1 ? "Major" : "Majors"}</p>
                                    {mentorMajors.map((_, index) => {
                                        return <select className="select-grade" value={mentorMajors[index]} onChange={(e) => {
                                            mentorMajors[index] = e.target.value
                                            setMentorMajors([...mentorMajors])
                                        }} >
                                            <option selected disabled >{mentorMajors[index]}</option>
                                            {majors.map(major => {
                                                return <option>{major}</option>
                                            })}
                                        </select>
                                    })}
                                </div>


                                <div>
                                    <p className="edit-field">{mentorMinors.length === 1 ? "Minor" : "Minors"}</p>
                                    {mentorMinors.map((_, index) => {
                                        return <select className="select-grade" value={mentorMinors[index]} onChange={(e) => {
                                            mentorMinors[index] = e.target.value
                                            setMentorMinors([...mentorMinors])
                                        }} >
                                            <option selected disabled >{mentorMajors[index]}</option>
                                            {minors.map(minor => {
                                                return <option>{minor}</option>
                                            })}
                                        </select>
                                    })}
                                </div>
                                <div>
                                    <p className="edit-field">Freshman Dorm</p>
                                    <select value={freshmanDorm} onChange={(e) => setFreshmanDorm(e.target.value)} className="select-grade">
                                        <option selected disabled >Dorm</option>
                                        <option value="Alice Lloyd Hall">Alice Lloyd Hall</option>
                                        <option value="Baits II">Baits II</option>
                                        <option value="Betsy Barbour">Betsy Barbour</option>
                                        <option value="Bursley Hall">Bursley Hall</option>
                                        <option value="Couzens Hall">Couzens Hall</option>
                                        <option value="East Quadrangle">East Quadrangle</option>
                                        <option value="Fletcher Hall">Fletcher Hall</option>
                                        <option value="Helen Newberry">Helen Newberry</option>
                                        <option value="Henderson House">Henderson House</option>
                                        <option value="Martha Cook Building">Martha Cook Building</option>
                                        <option value="Mary Markley Hall">Mary Markley Hall</option>
                                        <option value="Mosher-Jordan Hall">Mosher-Jordan Hall</option>
                                        <option value="North Quadrangle">North Quadrangle</option>
                                        <option value="Oxford Houses">Oxford Houses</option>
                                        <option value="South Quadrangle">South Quadrangle</option>
                                        <option value="West Quadrangle & Cambridge House">West Quadrangle & Cambridge House</option>
                                        <option value="Other">Other</option>
                                    </select>
                                </div>


                                <div>
                                    <p className="edit-field">High School Class Size</p>
                                    <select value={highSchoolSize} onChange={(e) => setHighSchoolSize(e.target.value)} className="select-grade">
                                        <option selected disabled >Size</option>
                                        <option value="50">1-50</option>
                                        <option value="100">50-100</option>
                                        <option value="200">100-200</option>
                                        <option value="300">200-300</option>
                                        <option value="400">300-400</option>
                                        <option value="500">400-500</option>
                                        <option value="1000">500-1000</option>
                                        <option value="2000">1000-2000</option>
                                        <option value="3000">2000+</option>
                                    </select>
                                </div>
                                <div>
                                    <p className="edit-field">Expected Graduation</p>
                                    <select value={expectedGraduation} onChange={(e) => setExpectedGraduation(e.target.value)} className="select-grade">
                                        <option selected disabled >Year</option>
                                        <option value="Fall 2022">Fall 2022</option>
                                        <option value="Winter 2023">Winter 2023</option>
                                        <option value="Fall 2023">Fall 2023</option>
                                        <option value="Winter 2024">Winter 2024</option>
                                        <option value="Fall 2024">Fall 2024</option>
                                        <option value="Winter 2025">Winter 2025</option>
                                        <option value="Fall 2025">Fall 2025</option>
                                        <option value="Winter 2026">Winter 2026</option>
                                        <option value="Fall 2026">Fall 2026</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="save-cancel-flex">
                            <button onClick={() => isEditProfileSubmitDisabled ? null : handleEditNameSubmit()}
                                className={isEditProfileSubmitDisabled ? "save-changes disabled" : isSaveChangesClicked ? "save-changes-clicked" : "save-changes"}>
                                {isSaveChangesClicked ? "Saving..." : "Save Changes"}</button>
                            <button onClick={() => { setIsEditProfileShowing(false); setIsBackgroundBlurred(false); resetEditProfile() }} className="cancel-button">Cancel</button>
                            {isEditNameError ? <p className="edit-name-error">Field cannot be blank</p> : null}
                        </div>
                    </div>
                    : null}
                {isEditQuestionsShowing ?
                    <div ref={editQuestionsRef} className="edit-name-modal">
                        <FontAwesomeIcon icon={faXmark} className="icon-x-mark" onClick={() => { setIsEditQuestionsShowing(false); setIsBackgroundBlurred(false); resetEditQuestions() }} />
                        <p className="edit-header">Edit Profile</p>
                        <div className="modal-content">
                            <p className="edit-field">Questions</p>
                            <div className="edit-questions-grid">
                                {questions.map((question, index) => {
                                    return (
                                        <div className="edit-question-flex">
                                            <p className="edit-question-ask-me-text">Ask me about...</p>
                                            <input maxLength="100" className="edit-question-input" onChange={(e) => {
                                                questions[index].text = e.target.value
                                                setQuestions([...questions])
                                            }}
                                                value={question.text}>
                                            </input>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className="save-cancel-flex">
                            <button onClick={() => isEditQuestionsSubmitDisabled ? null : handleEditNameSubmit()}
                                className={isEditQuestionsSubmitDisabled ? "save-changes disabled" : isSaveChangesClicked ? "save-changes-clicked" : "save-changes"}>
                                {isSaveChangesClicked ? "Saving..." : "Save Changes"}</button>
                            <button onClick={() => { setIsEditQuestionsShowing(false); setIsBackgroundBlurred(false); resetEditQuestions() }} className="cancel-button">Cancel</button>
                        </div>
                    </div>
                    : null}

                {isEditExperienceShowing ?
                    <div ref={editExperienceRef} className="edit-profile-modal experience-modal">
                        <FontAwesomeIcon icon={faXmark} className="icon-x-mark" onClick={() => { setIsEditExperienceShowing(false); setIsBackgroundBlurred(false); resetEditExperience() }} />
                        <p className="edit-header">Edit Profile</p>
                        <div className="modal-content">
                            {experiences?.map((experience, index) => {
                                return (
                                    <>
                                        <p className="edit-field">{experience.club}</p>
                                        <p className="experience-input-header">Club / Organization Name*</p>
                                        <input maxLength="100" className="experience-input" onChange={(e) => {
                                            experiences[index].club = e.target.value
                                            setExperiences([...experiences])
                                        }} value={experience.club}></input>
                                        <p className="experience-input-header">Title</p>
                                        <input maxLength="100" placeholder="Ex) Teamlead & Recruitment Chair" onChange={(e) => {
                                            experiences[index].title = e.target.value
                                            setExperiences([...experiences])
                                        }} value={experience.title}
                                            className="experience-input"></input>
                                        <p className="experience-input-header">Start Date</p>
                                        <div className="start-date-flex">
                                            <select onChange={(e) => {
                                                experiences[index].startMonth = e.target.value
                                                setExperiences([...experiences])
                                            }} value={experience.startMonth ? experience.startMonth : null}
                                                className="experience-select-date">
                                                <option selected disabled >Month</option>
                                                {months.map((month, index) => {
                                                    return <option>{month}</option>
                                                })}
                                            </select>
                                            <select onChange={(e) => {
                                                experiences[index].startYear = e.target.value
                                                setExperiences([...experiences])
                                            }} value={experience.startYear ? experience.startYear : null}
                                                className="experience-select-date">
                                                <option selected disabled >Year</option>
                                                {years.map((year, index) => {
                                                    return <option>{year}</option>
                                                })}
                                            </select>
                                        </div>
                                        <div>
                                            <div>
                                                <input onChange={(e) => {
                                                    if (e.target.value === "true") {
                                                        experiences[index].isRoleOngoing = "false"

                                                    }
                                                    else {
                                                        experiences[index].isRoleOngoing = "true"
                                                        experiences[index].endMonth = ""
                                                        experiences[index].endYear = ""
                                                        setExperiences([...experiences])
                                                    }
                                                    setExperiences([...experiences])
                                                }} className="experience-currently-in-role" type="checkbox" name={index} value={experiences[index].isRoleOngoing} id={index} defaultChecked={experiences[index].isRoleOngoing === "true"} />
                                                <label className="experience-currently-in-role-text" for="transfer">I am currently in this role</label>
                                            </div>
                                        </div>
                                        <p className="experience-input-header">End Date</p>
                                        <div className="start-date-flex">
                                            <select disabled={experiences[index].isRoleOngoing === "true"} onChange={(e) => {
                                                experiences[index].endMonth = e.target.value
                                                setExperiences([...experiences])
                                            }} value={experience.endMonth ? experience.endMonth : null} className={experiences[index].isRoleOngoing === "true" ? "experience-select-date experience-date-disabled" : "experience-select-date"}>
                                                <option selected disabled >Month</option>
                                                {months.map((month, index) => {
                                                    return <option>{month}</option>
                                                })}
                                            </select>
                                            <select value={experience.endYear ? experience.endYear : null} disabled={experiences[index].isRoleOngoing === "true"} onChange={(e) => {
                                                experiences[index].endYear = e.target.value
                                                setExperiences([...experiences])
                                            }} className={experiences[index].isRoleOngoing === "true" ? "experience-select-date experience-date-disabled" : "experience-select-date"}>
                                                <option selected disabled >Year</option>
                                                {years.map((year, index) => {
                                                    return <option>{year}</option>
                                                })}
                                            </select>
                                        </div>
                                        <p className="experience-input-header">Description</p>
                                        <textarea value={experience.description} maxLength="999" onChange={(e) => {
                                            experiences[index].description = e.target.value
                                            setExperiences([...experiences])
                                        }} className="experience-textarea"></textarea>
                                    </>
                                )
                            })}
                        </div>
                        <div className="save-cancel-flex">
                            <button onClick={() => isEditExperienceSubmitDisabled ? null : handleEditNameSubmit()}
                                className={isEditExperienceSubmitDisabled ? "save-changes disabled" : isSaveChangesClicked ? "save-changes-clicked" : "save-changes"}>
                                {isSaveChangesClicked ? "Saving..." : "Save Changes"}</button>
                            <button onClick={() => { setIsEditExperienceShowing(false); setIsBackgroundBlurred(false); resetEditExperience() }} className="cancel-button">Cancel</button>
                            {editExperienceError ? <p className="edit-name-error">Field cannot be blank</p> : null}
                        </div>
                    </div>
                    : null}

                <div className={isBackgroundBlurred ? "mentor-bio-page modal-background" : "mentor-bio-page"}>
                    <p className="mentor-profile-header">This is a preview of your mentor profile.</p>
                    <p className="mentor-profile-header-two">Select the edit icons to make changes to your profile.</p>
                    <div>
                        {mentorProfile ?
                            <div className="mentor-profile-grid">
                                <div className="mentor-profile-image-container">
                                    {image ? <img
                                        alt="mentor"
                                        src={image}
                                        className="mentor-profile-image"
                                    /> : null}
                                    {/* <FontAwesomeIcon icon={faPenToSquare} className="edit-icon" /> */}
                                    <div className="edit-flex">
                                        <p className="mentor-profile-name">{mentorProfile.name}</p>
                                        <FontAwesomeIcon onClick={() => { setIsEditNameModalShowing(true); setIsBackgroundBlurred(true) }} icon={faPenToSquare} className="edit-icon-name" />
                                    </div>
                                    <p className="mentor-profile-details-major">{mentorProfile.mentorBio.headline ? mentorProfile.mentorBio.headline : `${mentorProfile.mentorBio.majors[0]} in the School of ${mentorProfile.mentorBio.schools[0]}`}</p>
                                    <p className="mentor-profile-details-year-state">{mentorProfile.mentorBio.year} from
                                        {mentorProfile.mentorBio.city ? ` ${mentorProfile.mentorBio.city},` : null} {mentorProfile.mentorBio.state}
                                    </p>
                                    <div className="mentor-profile-buttons-flex">
                                        <div className="mentor-profile-button-message-icon-flex">
                                            <FontAwesomeIcon icon={faMessages} className="messages-icon icon" />
                                            <button className="mentor-profile-message-button">Message</button>
                                        </div>
                                        <div className="mentor-profile-button-bookmark-icon-flex">
                                            <FontAwesomeIcon icon={faBookmark} className="bookmark-icon icon" />
                                            <button className="mentor-profile-bookmark-button">Bookmark</button>
                                        </div>
                                    </div>
                                    <div className="coming-soon-flex">
                                        <i>Coming Soon!</i>
                                        <i>Coming Soon!</i>
                                    </div>
                                    <div className="mentor-rating-flex">
                                        <p className="mentor-rating">_ /5</p>
                                        <FontAwesomeIcon icon={faStarSharp} className="star-icon icon" />
                                        <FontAwesomeIcon icon={faStarSharp} className="star-icon icon" />
                                        <FontAwesomeIcon icon={faStarSharp} className="star-icon icon" />
                                        <FontAwesomeIcon icon={faStarSharp} className="star-icon icon" />
                                        <FontAwesomeIcon icon={faStarSharp} className="star-icon icon" />
                                        <p className="number-of-ratings">(0)</p>
                                    </div>
                                    <p className="testimonial">- "Ratings and testimonials coming soon!" </p>
                                </div>
                                <Calendar calendarData={mentorProfile.calendarData} mentorEmail={mentorProfile.email} />
                            </div>
                            : null}
                    </div>
                    <div className="cards">
                        <div className="about-card">
                            <div className="edit-flex">
                                <p className="about-card-header">About</p>
                                <FontAwesomeIcon onClick={() => { setIsEditAboutShowing(true); setIsBackgroundBlurred(true) }} icon={faPenToSquare} className="edit-icon-about" />
                            </div>
                            {
                                mentorProfile?.mentorBio?.introduction ?
                                    <p className="about-card-text">{mentorProfile.mentorBio.introduction}</p>
                                    :
                                    <p className="about-card-text">Hi, I'm {mentorProfile.name.split(" ")[0]}! I'm a {mentorProfile.mentorBio.year} majoring in {mentorProfile.mentorBio.majors[0]}
                                        {" "}    {mentorProfile.mentorBio.minors[0] === "None" ? null : `and minoring in ${mentorProfile.mentorBio.minors[0]}`} in the School of {mentorProfile.mentorBio.schools[0]}. Looking forward to speaking with you!</p>
                            }
                        </div>
                    </div>
                    <div className="cards">
                        <div className="college-profile-card">
                            <div className="edit-flex">
                                <p className="about-card-header">Student Profile</p>
                                <FontAwesomeIcon onClick={() => { setIsEditProfileShowing(true); setIsBackgroundBlurred(true) }} icon={faPenToSquare} className="edit-icon-profile" />
                            </div>
                            <div className="student-profile-grid-container">
                                <div>
                                    <div className="profile-flex-container">
                                        <FontAwesomeIcon icon={faBuildingColumns} className="school-icon icon" />
                                        <p className="profile-header">{mentorProfile.mentorBio.schools.length > 1 ? "Schools" : "School"}</p>
                                    </div>
                                    {mentorProfile.mentorBio.schools.map(school => {
                                        return <p className="profile-text-school">{school} </p>
                                    })}
                                </div>
                                <div>
                                    <div className="profile-flex-container">
                                        <FontAwesomeIcon icon={faGraduationCap} className="major-icon icon" />
                                        <p className="profile-header">{mentorProfile.mentorBio.majors.length > 1 ? "Majors" : "Major"}</p>
                                    </div>
                                    {mentorProfile.mentorBio.majors.map(major => {
                                        return <p className="profile-text-major">{major} </p>
                                    })}
                                </div>
                                <div>
                                    <div className="profile-flex-container">
                                        <FontAwesomeIcon icon={faDiploma} className="minor-icon icon" />
                                        <p className="profile-header pf-minor">{mentorProfile.mentorBio.minors.length > 1 ? "Minors" : "Minor"}</p>
                                    </div>
                                    {mentorProfile.mentorBio.minors.map(minor => {
                                        return <p className="profile-text-minor">{minor} </p>
                                    })}
                                </div>
                                <div>
                                    <div className="profile-flex-container">
                                        <FontAwesomeIcon icon={faHouseBuilding} className="school-icon icon profile-2-a" />
                                        <p className="profile-header profile-2-a">Freshman Dorm</p>
                                    </div>
                                    <p className="profile-text-school">{mentorProfile.mentorBio.dorm} </p>
                                </div>
                                <div>
                                    <div className="profile-flex-container">
                                        <FontAwesomeIcon icon={faSchool} className="high-school-icon icon  profile-2-b" />
                                        <p className="profile-header profile-2-b">High School Class Size</p>
                                    </div>
                                    <p className="profile-text-major">{returnHighSchoolSize(mentorProfile.mentorBio.highSchoolSize)} </p>
                                </div>
                                <div>
                                    <div className="profile-flex-container">
                                        <FontAwesomeIcon icon={faUserGraduate} className="graduate-icon icon  profile-2-b" />
                                        <p className="profile-header profile-2-b">Expected Graduation</p>
                                    </div>
                                    <p className="profile-text-minor">{mentorProfile.mentorBio.graduationYear}</p>
                                </div>
                            </div>
                            <hr className="profile-line-separator" />
                            {width < 1300 ? <hr className="profile-line-separator-2" /> : null}
                            {width < 800 ? <hr className="profile-line-separator-3" /> : null}
                            {width < 800 ? <hr className="profile-line-separator-4" /> : null}
                            {width < 800 ? <hr className="profile-line-separator-5" /> : null}


                        </div>
                        <div className="about-card college-experience">
                            <div className="edit-flex">
                                <p className="about-card-header">College Experience</p>
                                <FontAwesomeIcon onClick={() => { setIsEditExperienceShowing(true); setIsBackgroundBlurred(true) }} icon={faPenToSquare} className="edit-icon-experience" />
                            </div>
                            {mentorProfile.mentorBio.clubs.map((club, index) => {
                                return (
                                    <>
                                        <p className="club-headers">{club.club}</p>
                                        <p className="club-title">{club.title}</p>
                                        {club.startMonth && club.startYear ? <p className="club-date">{club.startMonth} {club.startYear} - {club.endMonth && club.endYear ? `${club.endMonth} ${club.endYear}` : "Present"}</p> : null}
                                        <p className="club-description">{club.description}</p>
                                        {index !== mentorProfile.mentorBio.clubs.length - 1 ? <hr className="clubs-line-separator"></hr> : null}
                                    </>
                                )
                            })}
                        </div>
                    </div>
                    <div className="statistics-container">
                        {
                            mentorProfile.mentorBio.askMeQuestions.map((question, index) => {
                                if (question.text) {
                                    return <div className={"one-statistic border-" + colors[index]}>
                                        <p className={"stat-label-workload " + colors[index]}>Ask Me About...</p>
                                        <FontAwesomeIcon className={"backpack-icon " + colors[index]} icon={faBlockQuestion} />
                                        <p className={"stat-explanation " + colors[index]}>
                                            {question.text}
                                        </p>
                                    </div>
                                }
                                else {
                                    return null
                                }
                            })
                        }
                        {!mentorProfile?.mentorBio?.askMeQuestions[4].text ?
                            <FontAwesomeIcon icon={faPenToSquare} onClick={() => { setIsEditQuestionsShowing(true); setIsBackgroundBlurred(true) }} className="edit-icon-question" /> : null}
                    </div>
                    {mentorProfile?.mentorBio?.askMeQuestions[4].text ?
                        <FontAwesomeIcon icon={faPenToSquare} onClick={() => { setIsEditQuestionsShowing(true); setIsBackgroundBlurred(true) }} className="edit-icon-question-center" /> : null}
                    <Footer />
                </div>
            </>
    )
}

export default MentorProfile;
