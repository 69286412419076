import React, { useContext, useEffect } from 'react'
import { ContextProvider } from '../context/index';
import { useNavigate } from "react-router-dom"
import Loading from "../Components/Loading/index"

const BaseRoute = ({ children }) => {

    const { user } = useContext(ContextProvider)

    const navigate = useNavigate()

    useEffect(() => {
        // if (user?.isUserAuthenticated && !user.isSignUpCompleted) {
        //     navigate(`/mentor/signup`)
        // }
        // else if (user?.isUserAuthenticated && user.isSignUpCompleted) {
        //     navigate("/mentor")
        // }

    }, [user])

    // If the user is a mentor, send them to the mentor page by default
    return user?.isAlsoMentor ? window.location.href = "/mentor"
        : user?.isUserAuthenticated === false ? children
            : user?.isUserAuthenticated ? children
                : < Loading />
}


export default BaseRoute