
const filterMentorsByMinor = (filter, minors) => {
    if (!filter) { return true }
    let filterMatch = false
    minors.forEach(minor => {
        if (filter === "writing") {
            if (minor.includes("Writing")) {
                filterMatch = true
            }
        }
        else if (filter === "cs") {
            if (minor.includes("Computer") || minor.includes("Data")) {
                filterMatch = true
            }
        }
        else if (filter === "hist") {
            if (minor.includes("History")) {
                filterMatch = true
            }
        }
        else if (filter === "poli") {
            if (minor.includes("Political Science") || minor.includes("Politics")) {
                filterMatch = true
            }
        }
        else if (filter === "entre") {
            if (minor.includes("Business") || minor.includes("Economics") || minor.includes("Management") || minor.includes("Entrepreneurship")) {
                filterMatch = true
            }
        }
        else if (filter === "lang") {
            if (minor.includes("Language") || minor.includes("Culture") || minor.includes("German") || minor.includes("Spanish") || minor.includes("Chinese")) {
                filterMatch = true
            }
        }
        else if (filter === "med") {
            if (minor.includes("Biology") || minor.includes("Neuroscience")) {
                filterMatch = true
            }
        }
        else if (filter === "chem") {
            if (minor.includes("Chemistry") || minor.includes("Chemical")) {
                filterMatch = true
            }
        }
        else if (filter === "aero") {
            if (minor.includes("Aerospace") || minor.includes("Astronomy")) {
                filterMatch = true
            }
        }
        else if (filter === "nurse") {
            if (minor.includes("Health")) {
                filterMatch = true
            }
        }
        else if (filter === "edu") {
            if (minor.includes("Education")) {
                filterMatch = true
            }
        }
    })
    return filterMatch
}

export default filterMentorsByMinor