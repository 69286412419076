import React, { useState, useEffect } from "react";
import { getStorage, ref, getDownloadURL } from "firebase/storage"
import { Link } from "react-router-dom";
import "./index.css"

const Mentor = ({ email, image, name, id, mentorBio }) => {

    const [img, setImg] = useState(null);

    const storage = getStorage();

    const mentorImageRef = ref(storage, image)


    useEffect(() => {
        getDownloadURL(mentorImageRef).then((img1) => {
            setImg(img1)
        })
    }, [mentorImageRef])

    return (
        <Link
            // onClick={() => {
            //     const numProfilesClicked = parseInt(localStorage.getItem("profilesClicked"))
            //     if (numProfilesClicked === 2) {

            //     }
            //     else {
            //         const numProfilesClickedIncremented = numProfilesClicked + 1
            //         localStorage.setItem("profilesClicked", numProfilesClickedIncremented)
            //     }
            // }}
            style={{ textDecoration: "none", color: "black" }}
            to={`/mentors/${id}`}>
            <div className="mentor-div">
                {img ? <img alt="mentor" className="mentor-grid-image" src={img}></img> : <div className="mentor-grid-image"></div>}
                <div className="mentor-text-container">
                    <p className="mentor-name name-text"> {name} </p>
                    <p className={mentorBio.minors[0] === "None" ? "mentor-bio mentor-text-center no-minor" : "mentor-bio mentor-text-center minor"}>
                        School: {mentorBio.schools[0]}</p>
                    <p className="mentor-bio mentor-text-center">
                        {mentorBio.majors.length === 1 ? "Major" : "Majors"}: {mentorBio.majors.map((major, index) => {
                            if (index + 2 === mentorBio.majors.length) {
                                return <>{major} and </>
                            }
                            else if (index === mentorBio.majors.length - 1) {
                                return <>{major}</>
                            }
                            else {
                                return <>{major}, </>
                            }
                        })}
                    </p>
                    {mentorBio.majors.length > 2 || mentorBio.minors[0] === "None" ? null : <p className="mentor-bio mentor-text-center">Minor: {mentorBio.minors[0]}</p>}
                </div>
            </div>
        </Link>
    ) //return
}; //mentor

export default Mentor;