import React, { useContext, useState, useEffect, useRef } from 'react'
import Navbar from '../Navbar'
import Footer from '../Footer'
import { ContextProvider } from '../../context';
import formatDate from '../../utils/formatDate';
import formatTime from '../../utils/formatTime'
import { cancelMeeting } from '../../API/api';
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCheckCircle, faXmark
} from "@fortawesome/pro-solid-svg-icons";
import { getEST } from '../../API/api';
import "./index.css"

const UserMeetings = () => {

    const { user, setUser, fetchUserData } = useContext(ContextProvider)

    const [meetings, setMeetings] = useState(user.meetings)

    const [isMeetingCancelled, setIsMeetingCancelled] = useState(false)

    const [isCannotJoinMeetingModalShown, setisCannotJoinMeetingModalShown] = useState(false)

    const [isCannotBeCanceledShown, setIsCannotBeCanceledShown] = useState(false)

    const cannotJoinModalRef = useRef()

    const cannotCancelRef = useRef()

    useEffect(() => {
        fetchUserData()
    }, [])

    useEffect(() => {
        setMeetings(user.meetings)
    }, [user])

    useEffect(() => {
        let closeCannotJoinModalRef = (event) => {
            if (!cannotJoinModalRef?.current.contains(event.target)) {
                setisCannotJoinMeetingModalShown(false)
            }
        }
        let cannotCancel = (event) => {
            if (!cannotCancelRef?.current.contains(event.target)) {
                setIsCannotBeCanceledShown(false)
            }
        }
        document.addEventListener("mousedown", closeCannotJoinModalRef);
        document.addEventListener("mousedown", cannotCancel);
        return () => {
            document.removeEventListener("mousedown", closeCannotJoinModalRef);
            document.removeEventListener("mousedown", cannotCancel);
        }

    }, [])

    const canMeetingBeCanceled = async (meeting) => {
        const result = await getEST()
        if (new Date(result.data.datetime) > new Date(meeting.startTime)) {
            return false
        }
        return true
    }

    const handleCancelMeeting = (meeting) => {
        cancelMeeting(meeting, (result) => {
            setMeetings(result.data)
            setIsMeetingCancelled(true)
            user.meetings = result.data
            setUser({ ...user })
        })
    }

    const isMeetingWithinFiveMinutesOfStarting = async (meetingStartTime) => {
        const currentEstTime = await getEST()
        const isLessThanFive = new Date(meetingStartTime) - new Date(currentEstTime.data.datetime)
        if (isLessThanFive <= 300000) {
            return true
        }
        return false
    }

    return (
        <>
            <Navbar />
            {isCannotJoinMeetingModalShown !== false ?
                <div ref={cannotJoinModalRef} className='cannot-join-meeting-yet'>
                    <FontAwesomeIcon icon={faXmark} className="icon-x-mark" onClick={() => setisCannotJoinMeetingModalShown(false)} />
                    <p className="cannot-join-meeting-header-text">
                        {!user?.meetings[isCannotJoinMeetingModalShown]?.hasMentorAcceptedMeetingRequest ? "Your meeting request has not been accepted yet" : "Your meeting has not started yet"}
                    </p>
                    <p className='refresh-warning'>Make sure that you have refreshed the page recently.</p>
                    <p className="cannot-join-meeting-sub-text">
                        {!user?.meetings[isCannotJoinMeetingModalShown]?.hasMentorAcceptedMeetingRequest ? "Your mentor must accept your meeting request before the meeting can begin." : "The join button will become active 5 minutes before your meeting begins."}
                    </p>
                </div>
                : null}
            {isCannotBeCanceledShown ?
                <div ref={cannotCancelRef} className='cannot-join-meeting-yet'>
                    <FontAwesomeIcon icon={faXmark} className="icon-x-mark" onClick={() => setIsCannotBeCanceledShown(false)} />
                    <p className="cannot-join-meeting-header-text">
                        This meeting has already started
                    </p>
                    <p className="cannot-join-meeting-sub-text">
                        If the meeting has already started it cannot be canceled.
                    </p>
                </div>
                : null}
            <div className={isCannotJoinMeetingModalShown !== false || isCannotBeCanceledShown ? 'user-meetings-container blur-background' : 'user-meetings-container'}>
                <p className='my-meetings-header-text'>My Meetings</p>
                {isMeetingCancelled ? <div onClick={setTimeout(() => { setIsMeetingCancelled(false) }, 3000)} className="meeting-cancelled-success">
                    <p className="availability-saved">Meeting Successfully Cancelled.</p>
                    <FontAwesomeIcon icon={faCheckCircle} className="circle-check-icon" />
                </div> : null}
                {user?.meetings?.length === 0 ?
                    <>
                        <p className='no-meetings-yet'>You haven't booked any meetings yet.</p>
                        <Link to="/?view=mentors">
                            <button className='no-meetings-book-meeting-button'>Book a meeting</button>
                        </Link>
                    </>
                    :
                    meetings?.sort((a, b) => new Date(a.startTime) - new Date(b.startTime)).map((meeting, index) => {
                        return (
                            <div className='meeting-box'>
                                <div className='personal-information-flex'>
                                    {meeting.isCancelMeetingPressed ? <p className='meeting-with-name'>Are you sure you want to cancel?</p>
                                        :
                                        <p className='meeting-with-name'>Meeting {meeting.hasMentorAcceptedMeetingRequest ? "booked with" : "requested for"} {meeting?.mentorName}</p>
                                    }
                                    {/* <p className='personal-information-edit'>Manage</p> */}
                                </div>
                                <div className='my-meetings-buttons-flex'>
                                    {meeting.isCancelMeetingPressed ? null :
                                        <button className={meeting.hasMentorAcceptedMeetingRequest ? 'my-meetings-date-button' : "my-meetings-date-button large-date three-meetings"}
                                            onClick={() => {
                                                // if the meeting has been accepted, send the user to the join meeting page
                                                if (meeting.hasMentorAcceptedMeetingRequest)
                                                    window.location.href = `/meeting/join/user/${meeting.id}`
                                                // Otherwise send them to the meeting request page
                                                else
                                                    window.location.href = `/meeting/booked/${meeting.id}`
                                            }}>
                                            {meeting.hasMentorAcceptedMeetingRequest ?
                                                <>
                                                    {formatDate(meeting.startTime)} @ {formatTime(meeting.startTime)}
                                                </> :
                                                <div style={{ marginTop: 10 }}>
                                                    <p className='proposed-meeting-date'>{formatDate(meeting["0"].startTime)} @ {formatTime(meeting["0"].startTime)}</p>
                                                    <p className='proposed-meeting-date'>{formatDate(meeting["1"].startTime)} @ {formatTime(meeting["1"].startTime)}</p>
                                                    <p className='proposed-meeting-date'>{formatDate(meeting["2"].startTime)} @ {formatTime(meeting["2"].startTime)}</p>
                                                </div>
                                            }

                                        </button>}

                                    <div className={meeting.hasMentorAcceptedMeetingRequest ? "join-cancel-flex" : "join-cancel-grid"}>
                                        {meeting.isCancelMeetingPressed ? <button onClick={() => {
                                            meeting.isCancelMeetingPressed = false;
                                            setMeetings([...meetings])
                                        }} className='my-meetings-join-button'>Back</button>
                                            :
                                            <button onClick={async () => await isMeetingWithinFiveMinutesOfStarting(meeting?.startTime) && meeting?.hasMentorAcceptedMeetingRequest ? window.location.href = meeting.zoomLink : setisCannotJoinMeetingModalShown(index)} className='my-meetings-join-button'>Join</button>
                                        }
                                        {meeting.isCancelMeetingPressed ?
                                            <button onClick={() => {
                                                handleCancelMeeting(meeting)
                                                meeting.isMeetingBeingCancelled = true
                                                setMeetings([...meetings])
                                            }} className={meeting.isMeetingBeingCancelled ? 'my-meetings-cancel-button-long-being-cancelled' : 'my-meetings-cancel-button-long'}>
                                                {meeting.isMeetingBeingCancelled ? "Cancelling Meeting" : "Cancel Meeting"}
                                            </button>
                                            :
                                            <button onClick={async () => {
                                                const result = await canMeetingBeCanceled(meeting)
                                                if (result) {
                                                    meeting.isCancelMeetingPressed = true;
                                                    setMeetings([...meetings])
                                                }
                                                else {
                                                    setIsCannotBeCanceledShown(true)
                                                }
                                            }} className='my-meetings-cancel-button'>Cancel</button>}
                                    </div>
                                </div>

                            </div>
                        )
                    })}
            </div>
            <Footer />
        </>
    )
}

export default UserMeetings