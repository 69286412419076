
import firebase from 'firebase/compat/app';
const { API_ROOT_URL } = require("../../../configs/activebuildconfig.json");

/**
 * CONTROLLER - mentor
 * FUNCTION - getAuthenticatedMentor
 * VERIFY - an authenticated Mentor
 * @param token the token of the authenticated mentor
 * @returns The account data for the authenticated mentor
 */

export const getAuthenticatedMentor = async (callback) => {
    firebase.auth().onAuthStateChanged(async mentor => {
        if (!mentor) {
            callback({ isMentorAuthenticated: false })
            return;
        }
        const token = await mentor.getIdToken(false);
        const response = await fetch(`${API_ROOT_URL}/mentor/get_authenticated_mentor`, {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-type": "application/json",
            },
            body: JSON.stringify({
                token
            }),
        })
        const result = await response.json();
        if (result.success) {
            result.isUserAuthenticated = true
        }
        else {
            result.isUserAuthenticated = false
        }
        callback(result)
    })
}